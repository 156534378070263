.result {
	&__container {
		max-width: 890px;
		padding: 0 20px 60px 20px;
		margin: auto;

		.author-book__title {
			max-width: 100%;
		}
	}

	&__header {
		padding-top: 34px;
		margin-bottom: 22px;
		&__content {
			font-weight: 700;
			font-size: 36px;
			line-height: 48px;
			color: #2d2c42;
			overflow: hidden;
		}
	}

	&__main {
		margin-top: 24px;
		border-radius: 16px;
		overflow: hidden;
		.nav-tabs {
			display: flex;
			flex-wrap: wrap;
			justify-content: space-evenly;
			padding: 0 20px;
		}
		.nav-item {
			.nav-link {
				font-weight: 600;
				font-size: 15px;
				line-height: 24px;
				color: #a0a3bd;
			}
			.active {
				color: #2d2c42;
			}
		}
		.nav-tabs .nav-link {
			padding: 2.75rem 1.5rem 1.75rem 1.5rem;
			font-size: 18px;
		}
		.tab-content {
			padding-bottom: 0;
		}
	}
}

@media only screen and (max-width: 1024px) {
	.result {
		&__container {
			margin: auto;
		}

		&__header__content {
			font-size: 24px;
		}

		&__main {
			.nav-tabs .nav-link {
				padding: 28px 20px 20px;
			}
		}
	}
}

@media only screen and (max-width: 768px) {
	.result {
		&__main {
			.quote-footer__badge.badge {
				max-width: 153px !important;
			}
			.quote-footer {
				gap: 5px;
			}
		}
	}
}

// responsive điện thoại
@media only screen and (max-width: 500px) {
	.result {
		&__header {
			padding-top: 24px;
			margin-bottom: 10px;

			&__content {
				font-size: medium;
				line-height: 1.5;
			}
		}

		&__container {
			padding: 0 10px 30px 10px;

			.search {
				&__container {
					padding: 10px;
				}
			}
		}

		&__main {
			margin-top: 16px;
			margin-bottom: 16px;

			.nav-item {
				.nav-link {
					font-size: small;
					line-height: normal;
					padding: 20px 10px;
				}
			}
		}
	}
}
