.topbooks {
	&__container {
		padding: 41px 20px;
		font-family: 'Montserrat';
		font-style: normal;

		&__title {
			font-weight: 700;
			font-size: 24px;
			line-height: 32px;
			letter-spacing: 1px;
			color: #2d2c42;
		}

		&__sort {
			display: flex;
			flex-wrap: wrap;
			gap: 10px;
			justify-content: space-between;
			margin-top: 39px;
			.select-box__value {
				font-weight: 600;
				font-size: 18px;
				line-height: 24px;
				color: #2d2c42;
				letter-spacing: 0.75px;
			}
			&__left {
				.select-box {
					&__btn {
						background: #f5e7d8;
						min-width: 220px;
						justify-content: center;
						.select-box__value {
							flex: 1;
							text-align: center;
						}
					}
					&__list {
						background: #fbf7f2;
						max-width: 220px;
					}
				}
				.disable {
					background-color: #cccccc !important;
					cursor: not-allowed;
					.select-box__value {
						color: #666666 !important;
					}
				}
			}

			&__right {
				&__title {
					color: #6e7191;
					letter-spacing: 0.75px;
					font-weight: 500;
					font-size: 15px;
					line-height: 24px;
					min-width: 75px;
				}

				display: flex;
				align-items: center;
				gap: 20px;

				.select-box {
					.select-box__btn {
						background: #f7f7fc;
						width: 140px;
						justify-content: space-around;
					}

					.select-box__list {
						background: #f7f7fc;
						max-width: 140px;
					}
				}
			}
		}
		.top__book {
			gap: 28px;
		}
		&__main {
			margin-top: 40px;
			margin-left: 25px;
			display: flex;
			align-items: center;
			justify-content: space-between;
			gap: 32px;

			.topbooks__container__star {
				position: relative;
			}

			.topbooks__container__number___custom {
				font-size: 18px;
				line-height: 30px;
				width: 42px;
				height: 42px;
				border-radius: 50%;
				background: #fbf7f2;
				display: flex;
				justify-content: center;
				align-items: center;
			}

			.topbooks__container__number___ranks {
				position: absolute;
				margin-left: 18.5px;
				margin-top: -33px;
				font-size: 18px;
				line-height: 30px;
			}

			&__layout {
				background: #fbf7f2;
				border-radius: 16px;
				flex: 1;
				width: 100%;
				.star-icon {
					&.fill {
						path {
							fill: #ff9d2b;
						}
					}
				}
				.author-card {
					padding: 1.25rem 1.3rem 1.25rem 1.8rem;
				}
				.react-rating-container {
					svg {
						margin-right: 12px;
					}
				}

				.author-book__title {
					font-size: 1.3rem;
					margin-bottom: 0;
					flex: 1;
				}
				.author-book {
					margin-bottom: 0;

					.author-book__stats {
						font-size: 18px;
					}
				}

				.author-card__avatar {
					width: 76px;
					height: 76px;
				}
				.author-card__info h5 {
					color: #2d2c42;
					font-weight: 600;
					font-size: 1rem;
					line-height: 24px;
				}
				.author-card__info p {
					font-weight: 500;
					font-size: 0.9rem;
					line-height: 22px;
				}
			}
			.topbooks__container__main__svg {
				.star-1 path {
					fill: #a0a3bd;
				}
				.star-2 path {
					fill: #b38c64;
				}
				.star-1,
				.star-2 {
					color: #fcfcfc;
				}
			}
		}
	}

	&__notthing {
		margin-top: 40px;
		text-align: center;
	}
}

// responsive điện thoại
@media only screen and (max-width: 500px) {
	.topbooks__container {
		padding: 20px 10px;

		&__title {
			font-size: large;
			letter-spacing: normal;
		}

		&__main {
			flex-direction: column;
			gap: 10px;

			&.top__book {
				gap: 10px;
			}

			.text-status {
				display: none;
			}

			.author-book {
				.author-book__share.hide {
					position: absolute;
					right: 10%;
				}
				.author-book__title {
					-webkit-line-clamp: 2;
				}
				.author-book__stats {
					font-size: medium;
				}
			}
		}

		&__sort {
			margin-top: 12px;

			&__right {
				&__title {
					display: none;
				}
			}

			.select-box {
				&__btn {
					min-width: 150px;
				}
				&__value {
					font-size: inherit;
				}
			}
		}
	}
}
