.sb-show-main {
	padding-top: 50px !important;
}

.top__user__ranks {
	display: flex;
	margin: 30px auto 40px auto;
	width: 80%;
	justify-content: center;
	align-items: center;
	gap: 43px;
	&__two {
		display: flex;
		flex-direction: column;
		justify-content: center;
		&__avatar {
			border-radius: 50%;
			display: flex;
			align-items: center;
			position: relative;
			max-width: 92px;
			margin: auto;
			.user-avatar {
				width: 82px;
				border: 5px solid #f4f4f4;
			}
			.author-card__avatar {
				margin: 0;
			}
		}
		.three {
			.user-avatar {
				border: 5px solid #b38c64;
			}
		}

		&__title {
			margin-top: 16px;
			text-align: center;
			p {
				font-weight: 600;
				font-size: 15px;
				line-height: 24px;
				display: -webkit-box;
				-webkit-box-orient: vertical;
				-webkit-line-clamp: 2;
				overflow: hidden;
				text-overflow: ellipsis;
				margin-bottom: 3px;
				word-break: break-word;
			}
			.number__books,
			span {
				font-weight: 600;
				font-size: 13px;
				line-height: 22px;
				color: #e0af7e;
			}
			span {
				color: #a0a3bd;
			}
		}
	}

	&__one {
		display: flex;
		flex-direction: column;
		justify-content: center;
		&__avatar {
			border-radius: 50%;
			display: flex;
			align-items: center;
			position: relative;
			max-width: 138px;
			margin: auto;
			.user-avatar {
				width: 128px;
				border: 5px solid #ffca28;
			}
			.author-card__avatar {
				margin: 0;
			}
			&:hover {
				cursor: pointer;
			}
		}

		&__title {
			margin-top: 20px;
			text-align: center;
			p {
				font-weight: 600;
				font-size: 18px;
				line-height: 20px;
				display: -webkit-box;
				-webkit-box-orient: vertical;
				-webkit-line-clamp: 2;
				overflow: hidden;
				text-overflow: ellipsis;
				margin-bottom: 3px;
				word-break: break-word;
			}
			.number__books,
			span {
				font-weight: 600;
				font-size: 15px;
				line-height: 24px;
				color: #e0af7e;
			}
			span {
				color: #a0a3bd;
			}
		}

		.Crown {
			position: absolute;
			bottom: 50%;
			left: 50%;
			transform: translate(-50%, -40%);
		}
	}

	.number__ranks {
		position: absolute;
		border-radius: 50%;
		width: 30px;
		height: 30px;
		text-align: center;
		top: 95%;
		left: 50%;
		transform: translate(-50%, -50%);
		font-weight: 600;
		font-size: 11px;
		line-height: 24px;
		display: flex;
		justify-content: center;
		align-items: center;

		&.two {
			background-color: #f4f4f4;
		}
		&.one {
			background-color: #ffca28;
		}
		&.three {
			background-color: #b38c64;
			color: white;
		}
	}
}

@media only screen and (max-width: 1024px) {
	.top__user__ranks {
		gap: 90px;
	}
}
