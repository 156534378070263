.sort-quotes-modal {
	&__item {
		padding: auto 0;
		height: 56px;
		display: flex;
		align-items: center;

		& .form-check-wrapper {
			width: 100%;
		}

		& span {
			margin-bottom: 0px;
		}

		& button {
			width: 100%;
		}
	}
	& .modal-dialog {
		width: 376px;
		margin-top: 120px;

		& .modal-content {
			border-radius: 8px;
			gap: 12px;
			display: flex;
			flex-direction: column;
			align-items: flex-start;
			padding: 32px;

			& .modal-body {
				padding: 0px;
				margin: 0 auto;
				width: 312px;
			}
		}
	}
	.form-check-input.is-valid ~ .form-check-label {
		font-size: 18px;
	}
}

@media only screen and (max-width: 1024px) {
	.quotes-tab {
		.sort-quotes-modal {
			.modal-content {
				padding: 0 32px 32px 32px !important;
			}
		}

		.filter-quote-pane__setting__title {
			margin-top: 60px !important;
		}
		.form-check {
			margin-top: 15px !important;
		}
		.form-check-wrapper {
			margin-top: 20px !important;
		}
	}
}

// responsive điện thoại
@media only screen and (max-width: 500px) {
	.sort-quotes-modal {
		.modal-dialog {
			width: auto;
			.modal-content {
				padding: 20px 10px;

				.modal-body {
					width: inherit;
				}
			}
		}

		&__item {
			height: 40px;
		}

		.form-check-input.is-valid ~ .form-check-label {
			font-size: small;
		}
	}
}
