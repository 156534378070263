.share-target {
	display: flex;
	align-items: center;
	justify-content: space-between;
	padding: 32px 24px;
	border-radius: 10px;
	background-color: #fbf7f2;
	background-image: url(../../assets/images/popup.png);
	gap: 12px;
	margin-bottom: 20px;
	.share-target__user {
		width: 90px;
	}
	.share-target__progress {
		flex-grow: 1;
	}
	.linear-progress {
		width: 100%;
		height: 10px !important;
	}
	.share-target__content__top {
		line-height: 1.5;
		font-weight: 600;
		margin-bottom: 8px;

		.share-target__content-user {
			font-weight: bold;
			cursor: pointer;
		}
	}
	.bg-share-target-gradient {
		background-image: linear-gradient(to right, #ffddae, #fb7f04);
	}
	.share-target__progress__percent-number {
		color: #a5a5a5;
		font-size: 18px;
		line-height: 1.5;
		margin-top: 4px;
		font-weight: 500;
	}
	& img {
		width: 100%;
		height: 100%;
	}
}
