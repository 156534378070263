* {
	margin: 0;
	padding: 0;
	border: 0;
	vertical-align: baseline;
	-webkit-box-sizing: border-box;
	-moz-box-sizing: border-box;
	box-sizing: border-box;
}

:root {
	--toggle-row: 3;
	--placeholder-editor: '';
}

html {
	width: 100%;
	height: 100%;
	line-height: 1.2rem;
}

body {
	height: 100%;
	font-family: 'Montserrat', sans-serif;
	font-weight: 500;
	line-height: 1;
	&::-webkit-scrollbar {
		width: 8px;
	}

	/* Track */
	&::-webkit-scrollbar-track {
		background: #f1f1f1;
		border-radius: 8px;
	}

	/* Handle */
	&::-webkit-scrollbar-thumb {
		background: #606060;
		border-radius: 8px;
		&:hover {
			background: rgba(#606060, 0.85);
		}
	}
}

.modal {
	&::-webkit-scrollbar {
		width: 8px;
	}

	/* Track */
	&::-webkit-scrollbar-track {
		background: #f1f1f1;
		border-radius: 8px;
	}

	/* Handle */
	&::-webkit-scrollbar-thumb {
		background: #606060;
		border-radius: 8px;
		&:hover {
			background: rgba(#606060, 0.85);
		}
	}
}

article,
aside,
details,
figcaption,
figure,
footer,
header,
menu,
nav,
section {
	display: block;
}

ul,
ol {
	list-style: none;
	padding: 0;
	margin: 0;
}

blockquote,
q {
	quotes: none;
}

p {
	margin: 0;
}

blockquote:before,
blockquote:after,
q:before,
q:after {
	content: '';
	content: none;
}

table {
	border-collapse: collapse;
	border-spacing: 0;
}

*:before,
*:after {
	-webkit-box-sizing: inherit;
	-moz-box-sizing: inherit;
	box-sizing: inherit;
}

button {
	cursor: pointer;
	background: transparent;
}

a {
	text-decoration: none;
	color: inherit;
	cursor: pointer;
	&:hover {
		color: inherit;
	}
}

h4 {
	font-size: 1.25rem;
	font-weight: 600;
	margin-bottom: 24px;
	color: $ash;
}

.view-all-link {
	display: block;
	margin-top: 2rem;
	margin: 2rem 0 0;
	font-size: 13px;
	font-weight: 600;
	line-height: 1.5;
	text-decoration: underline;
	color: $blue;
	text-align: right;
	&:hover {
		color: $blue;
	}
}

div[contenteditable='true'] {
	-webkit-user-modify: read-write-plaintext-only;
	line-height: 1.5;
	color: $ash-body;
	font-family: inherit;
	outline: 0px solid transparent;
	background-color: inherit;
}

.blank-content {
	font-size: 0.875rem;
	margin-bottom: 0.5rem;
}

.modal-portal-open {
	overflow: hidden;
	padding-right: 0.5rem;
}
