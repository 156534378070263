@import 'src/scss/abstracts/_variables.scss';

.author-card {
	display: flex;
	justify-content: space-between;
	background-color: $secondary;
	padding: 2.25rem 2.25rem 2.25rem 3rem;
	border-radius: 16px;
	align-items: center;
	min-height: 176px;
	&__left {
		align-items: center;
		display: flex;
	}

	&__avatar {
		flex-shrink: 0;
		margin-right: 1.25rem;
	}

	&__info {
		font-size: 0.875rem;
		font-weight: 600;
		line-height: 1.5;
		color: $ash-placeholder;
		h5 {
			font-size: 1.125rem;
			color: $ash;
			cursor: pointer;
		}
		p {
			line-height: 22px;
			&:last-of-type {
				color: $ash;
			}
		}
		span {
			line-height: 22px;
		}
	}
}

@media only screen and (max-width: 768px) {
	.author-card {
		padding: 1.5rem;
	}
}

// responsive điện thoại
@media only screen and (max-width: 500px) {
	.author-card {
		flex-direction: column;
		min-height: inherit;
		gap: 1rem;

		h5 {
			font-size: small;
		}

		&__info {
			font-size: smaller;
		}

		&__right {
			.connect-buttons {
				flex-direction: row;
			}
		}
	}
}
