.login__header {
	height: 120px;
	background-color: #fbf7f2;
	display: flex;
	align-items: center;
	img {
		height: 46px;
		width: 173px;
	}
	a {
		padding-left: 200px;
		width: 100%;
		max-width: 360px;
	}
}

@media screen and (max-width: 1024px) {
	.login__header {
		display: flex;
		justify-content: center;

		a {
			padding-left: 72px;
		}
	}
}

@media screen and (max-width: 820px) {
	.login__header {
		padding-left: 0px;
	}
}

@media screen and (max-width: 768px) {
	.login__header {
		padding-left: 0px;
	}
}

@media screen and (max-width: 366px) {
	.login__header {
		height: 90px;
	}
}
