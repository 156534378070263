.search__all__container {
	background: #ffffff;
	box-shadow: 0px 4px 24px rgba(0, 0, 0, 0.24);
	border-radius: 0px 0px 12px 12px;
	position: absolute;
	top: -16px;
	left: -24px;
	z-index: 1000;
	padding: 16px 24px 20px;

	.search__all__main__title {
		display: flex;
		justify-content: space-between;
		margin-top: 15px;
		align-items: center;
		.search__all__title {
			font-weight: 600;
			font-size: 18px;
			line-height: 32px;
			color: #4e4b66;
			letter-spacing: 0.75px;
		}
		.search__all__title__editing {
			font-weight: 500;
			font-size: 15px;
			line-height: 24px;
			color: #0576f0;
			letter-spacing: 0.75px;
			cursor: pointer;
		}
	}

	.history__search {
		text-align: center;
		margin-top: 20px;
	}

	.search__all__header {
		.search-field {
			height: 64px;
			width: 380px;
			background-color: #eff0f7;
			.search-field__input {
				&::placeholder {
					font-weight: 500;
					color: #a0a3bd;
					letter-spacing: 0.75px;
					font-size: 15px;
				}
			}
		}
	}
}

@media only screen and (max-width: 1024px) {
	.search__all__container {
		.search__all__header {
			.search-field {
				width: 300px;
				&__input {
					font-size: 14px;
				}

				&__icon {
					width: 16px;
					height: 16px;
				}
			}
		}
	}
}
