@import 'src/scss/abstracts/_variables.scss';

.main-quote-detail {
	&__header {
		display: flex;
		align-items: center;
		gap: 1.25rem;
		h4 {
			margin: 0;
		}
	}

	&__link {
		display: block;
		margin-left: auto;
		padding: 1rem 1.5rem;
		border: 1px solid $ash-line;
		border-radius: 16px;
		font-size: 1rem;
		font-weight: 600;
		color: $ash-body;

		&__row {
			display: flex;
			flex-direction: row;
			align-items: center;
			gap: 1.4rem;
		}
	}

	&__pane {
		margin-top: 3rem;
		padding: 2rem 40px;
		border-radius: 16px;
		background-color: $white;

		.quote-comment {
			margin-left: 40px;
		}

		.quote-card {
			margin: 0 auto 40px;
			.quote-action-bar {
				gap: 24px;
			}
		}
	}
}

.comment-reply-container {
	margin: 0 0 20px 44px;
	.reply-see-more {
		width: fit-content;
		cursor: pointer;
		display: 'block';
		font-size: 12px;
		margin: -14px 0 14px 0;
		text-decoration: underline;
	}
}

.reply-comment-editor {
	display: none;
	&.show {
		display: flex;
	}
}

@media only screen and (max-width: 1366px) {
	.main-quote-detail {
		.quote-card {
			.quote-action-bar {
				gap: 12px;
				.quote-action__item {
					gap: 8px;
				}
			}
		}
	}
}

@media only screen and (max-width: 1024px) {
	.main-quote-detail {
		.quote-action__name {
			width: 100%;
		}
	}
	.sidebar-quote {
		.dualColumn-item__number {
			display: block;
		}
	}
}

// responsive điện thoại
@media only screen and (max-width: 500px) {
	.main-quote-detail {
		&__pane {
			padding: 10px;
		}

		.quote-card {
			margin: 0 0 20px;
		}
	}
}
