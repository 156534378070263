.result {
	&__search {
		&__container {
			font-family: 'Montserrat';
			font-style: normal;
		}

		&__value {
			display: flex;
			align-items: center;
			gap: 11px;
			margin-top: 24px;
			cursor: pointer;
			&__input {
				font-weight: 700;
				font-size: 15px;
				line-height: 24px;
				color: #e0af7e;
				word-break: break-all;
			}
		}

		&__icon {
			width: 44px;
			height: 44px;
			border-radius: 50%;
			background: #e0af7e;
			display: flex;
			justify-content: center;
			align-items: center;
			flex-shrink: 0;
			svg {
				path {
					stroke: #f7f7fc;
				}
			}
		}

		&__main {
			display: flex;
			justify-content: space-between;
			align-items: center;
			margin-top: 14px;

			&__left {
				display: flex;
				gap: 16px;
				align-items: center;
				flex: 1;
				cursor: pointer;
			}
		}

		&__icon {
			&__time {
				.result__search__img {
					width: 42px;
					aspect-ratio: 1/1;
					border-radius: 50%;
					object-fit: cover;
				}
			}
		}

		&__name {
			font-weight: 700;
			font-size: 14px;
			line-height: 24px;
			color: #000000;
			display: -webkit-box;
			-webkit-box-orient: vertical;
			-webkit-line-clamp: 2;
			text-overflow: ellipsis;
			overflow: hidden;
		}

		&__close {
			cursor: pointer;
			svg {
				width: 20px;
				height: 20px;
			}
		}
	}
}

@media only screen and (max-width: 820px) {
	.result {
		&__search {
			&__container {
				position: absolute;
				top: 120%;
				left: 0;
				width: 100%;
				padding: 20px 16px;
				background-color: white;
				border-radius: 12px;
				box-shadow: 0 4px 20px rgba(0, 0, 0, 0.15);
			}

			&__main {
				margin: 0 0 12px 0;
			}

			&__icon {
				width: 40px;
				height: 40px;
				svg {
					width: 20px;
					height: 20px;
				}
			}
		}
	}
}

// responsive điện thoại
@media only screen and (max-width: 500px) {
	.result {
		&__search {
			&__container {
				padding: 15px;
			}

			&__main {
				margin-bottom: 10px;
			}

			&__value {
				margin-top: 0;
			}
		}
	}
}
