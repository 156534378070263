@import 'src/scss/abstracts/_global.scss';

.add-and-search-categories {
	width: 100%;

	&__main-content {
		min-height: 57px;
		padding: 7px 24px;
		border-radius: 12px;
		background-color: $ash-background;
		display: flex;
		align-items: center;
		svg {
			width: 18px;
			height: 18px;
		}

		.add-and-search-categories__search-icon {
			margin-right: 16px;
		}

		> input {
			width: 100%;
			background-color: unset;
			outline: none;
			font-size: 14px;
			&::placeholder {
				color: $ash-placeholder;
			}
		}
	}

	&__categories-added {
		width: 100%;
		display: flex;
		align-items: center;
		gap: 8px;
		flex-wrap: wrap;

		&__item {
			padding: 0 18px;
			height: 43px;
			color: $primary-dark;
			background-color: $primary-light;
			border-radius: 12px;
			font-weight: 600;
			font-size: 13px;
			width: fit-content;
			max-width: 95%;
			display: flex;
			align-items: center;
			gap: 12px;
			button {
				display: flex;
				align-items: center;
				z-index: 2;
				svg {
					path {
						stroke: $primary-dark;
						stroke-width: 3px;
					}
				}
			}
		}
	}

	&__input {
		width: 100%;
		outline: none;
		font-size: 13px;
		line-height: 19px;
		background-color: transparent;

		&-wrapper {
			height: 100%;
		}
	}

	&__search-result {
		display: flex;
		padding-top: 8px;
		justify-content: flex-start;
		flex-wrap: wrap;
		gap: 16px;
	}

	&__searched-item {
		display: flex;
		flex-direction: column;
		align-items: center;
		cursor: pointer;
		padding: 16px;
		color: $primary;
		border: $primary 2px solid;
		border-radius: 8px;
		position: relative;
		font-size: 14px;
		svg {
			width: 8px;
			height: 8px;
			position: absolute;
			top: 2px;
			right: 4px;
			path {
				stroke: white;
			}
		}
	}

	&__searched-item-elipsis {
		border: none;
		display: flex;
		flex-direction: column;
		justify-content: center;
		color: $primary;
		font-size: 24px;
		cursor: default;
	}

	&__checked-category {
		position: absolute;
		top: 0px;
		right: 0px;
		border-top: 20px $primary solid;
		border-left: 26px transparent solid;
	}

	&__no-search-result {
		margin-top: 24px;
		color: $ash;
		font-size: 15px;
	}

	&__type-new {
		text-decoration: underline;
		cursor: pointer;
		color: blue;
	}

	&__loading {
		margin-top: 8px;
	}
}

// responsive điện thoại
@media only screen and (max-width: 500px) {
	.add-and-search-categories {
		&__main-content {
			min-height: 40px;
			padding: 4px 10px;

			> input {
				font-size: inherit;
			}
		}

		&__categories-added {
			&__item {
				height: 34px;
				gap: 4px;
				padding: 0 8px;
				font-size: smaller;
			}
		}

		&__search-result {
			gap: 8px;
		}

		&__searched-item {
			padding: 8px;
			font-size: small;
		}
	}
}
