@import 'src/scss/abstracts/_global.scss';
.status-book-wrapper {
	&:nth-of-type(2) {
		margin-top: 1rem;
	}
	font-weight: 600;
	.status-book__title {
		font-size: 18px;
	}
}

.status-book__title {
	font-size: 1rem;
	font-weight: inherit;
	line-height: 1.5;
	text-align: left;
	color: $ash;
}

.status-book__list {
	margin-bottom: 1rem;
}

.status-item {
	display: flex;
	align-items: center;
	color: $ash-body;
	&.status-item--icon {
		&:hover {
			.status-item__title {
				color: $primary;
			}
			background-color: $secondary;
		}
	}

	.custom-checkbox {
		margin-left: auto;
	}
	.custom-checkbox__container {
		display: inline-block;
		width: fit-content;
		position: relative;
		margin-right: 2rem;
		margin-bottom: 15px;
		cursor: pointer;
		font-size: 22px;
		-webkit-user-select: none;
		-moz-user-select: none;
		-ms-user-select: none;
		user-select: none;
	}

	.status-item__input {
		position: absolute;
		opacity: 1;
		cursor: pointer;
		height: 0;
		width: 0;
	}

	/* Create a custom checkbox */
	.status-item__checkmark {
		position: absolute;
		top: 0;
		left: 100%;
		height: 20px;
		width: 20px;
		background-color: $white;
		border: 2px solid $ash-placeholder;
		&::after {
			content: '';
			position: absolute;
			display: none;
		}
	}

	.custom-checkbox__container .status-item__input:checked ~ .status-item__checkmark:after {
		display: block;
	}

	.custom-checkbox__container .status-item__checkmark:after {
		left: 5px;
		top: 2px;
		width: 5px;
		height: 10px;
		border: solid $primary;
		border-width: 0 3px 3px 0;
		-webkit-transform: rotate(45deg);
		-ms-transform: rotate(45deg);
		transform: rotate(45deg);
	}

	&__title {
		margin-left: 1em;
		font-size: 18px;
	}

	.custom-radio {
		margin-left: auto;
		width: 18px;
		height: 18px;
		border: 2px solid $ash-placeholder;
		border-radius: 50%;
		&.active {
			background-color: $primary;
			border: none;
		}
	}

	/* Create a custom radio button */
	.checkmark {
		position: absolute;
		top: 0;
		left: 0;
		height: 20px;
		width: 20px;
		background-color: $white;
		border-radius: 50%;
		border: 2px solid $ash-placeholder;
	}

	/* When the radio button is checked, add a blue background */
	.custom-radio__container input:checked ~ .checkmark {
		background-color: $primary;
		border: none;
	}
}
