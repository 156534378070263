@import 'src/scss/abstracts/_variables.scss';

.author-book {
	display: flex;
	gap: 1rem;
	margin-bottom: 1.75rem;
	padding: 1.125rem 1.75rem;
	max-width: 856px;
	background-color: $secondary;
	border-radius: 16px;

	.book-thumbnail {
		flex-shrink: 0;
	}

	&__info {
		flex-grow: 1;
		display: flex;
		flex-direction: column;
		justify-content: space-between;
		overflow: hidden;

		&__book-name {
			display: flex;
			justify-content: space-between;
			align-items: flex-end;
		}
	}
	a {
		width: fit-content;
	}
	&__title {
		font-size: 1.5rem;
		font-weight: 700;
		line-height: 1.3;
		margin: 0;
		display: -webkit-box;
		-webkit-box-orient: vertical;
		-webkit-line-clamp: 1;
		overflow: hidden;
		cursor: pointer;
	}

	&__authors {
		font-size: 15px;
		line-height: 1.5;
		color: $ash-label;
		margin-top: 8px;
	}

	&__rating {
		display: flex;
		align-items: center;
		gap: 1.125rem;

		&__number {
			font-size: 1.25rem;
			font-weight: 600;
			color: $ash-body;
		}
	}

	&__stats {
		font-size: 18px;
		font-weight: 600;
		line-height: 1.5;
		color: $blue;
	}

	&__bottom {
		display: flex;
		justify-content: space-between;
		align-items: center;
	}

	.btn-status {
		align-self: flex-end;
		width: fit-content;
	}

	&__rating {
		&__number {
			font-size: 18px;
		}
	}

	&-custom {
		margin-bottom: 0;
	}
}

// responsive điện thoại
@media only screen and (max-width: 500px) {
	.author-book {
		flex-direction: column;
		padding: 1.125rem 1rem;

		.book-thumbnail {
			height: auto;
			width: 100px;
			margin: 0 auto;
		}

		&__title {
			margin-bottom: inherit !important;
			font-size: medium;
		}

		&__authors {
			margin-top: 0;
			margin-bottom: 0 !important;
			font-size: small;
		}

		&__info {
			text-align: center;

			&__book-name {
				justify-content: center;
			}

			.react-rating-container {
				> span {
					margin-right: 0 !important;
				}
			}
		}

		&__rating {
			justify-content: center;
			&__number {
				font-size: small;
			}

			svg {
				width: 15px;
				height: 15px;
			}
		}

		&__bottom {
			justify-content: center;
			margin-top: 8px;
		}

		&__stats {
			font-size: small;
		}

		&__bottom {
			gap: 8px;
		}

		.btn-status {
			&.btn {
				height: inherit;
			}
		}
	}
}
