@import 'src/scss/abstracts/_variables.scss';

.main-shelves {
	&__header {
		display: flex;
		justify-content: space-between;
		align-items: center;
		margin-bottom: 1.75rem;
		gap: 1rem;
		h4 {
			margin: 0;
		}
	}
	&__search {
		max-width: 456px;
		border: 1px solid $ash-line;

		&__img {
			display: block;
			margin: 0 auto;
			max-width: 100%;
			height: auto;
			object-fit: cover;
		}
	}

	&__pane {
		padding: 1.75rem 2.5rem;
		border-radius: 16px;
		background-color: $white;
		min-height: 228px;
		.select-box__list {
			max-height: 156px;
		}

		&__public-btn {
			color: $ash-body;
			border-color: #d9dbe9;
			padding-left: 8px;
			padding-right: 7px;
			&:hover {
				color: $ash-body;
				background-color: $primary-light;
				border-color: transparent;
			}
			&:disabled {
				color: $ash-body;
			}
			svg {
				margin-right: 12px;
			}
		}
	}

	&__filters {
		display: flex;
		justify-content: space-between;
		flex-wrap: wrap;
		gap: 0.5rem;
		margin-bottom: 2rem;
		.select-box__btn {
			background-color: $primary-light;
		}
		& .select-box {
			width: 220px;
			&__btn {
				justify-content: space-between;
			}
		}
	}

	.select-shelf {
		padding: 1rem 2.25rem;
		border-radius: 16px;
		background-color: $primary-light;
	}

	&__modal {
		.modal-content {
			padding: 2rem;
			text-align: center;
			border-radius: 24px;
		}

		.main-shelves__modal__subtitle {
			line-height: 32px;
			font-weight: 600;
		}

		.btn-closeX {
			cursor: pointer;
			display: inline-block;
			margin-left: auto;
			svg {
				width: 24px;
				height: 24px;
			}
		}

		&__title {
			margin-bottom: 1.25rem;
			font-size: 2.25rem;
			font-weight: 700;
			color: #14142b;
		}
		&__subtitle {
			margin-bottom: 1.5rem;
			font-size: 1.125rem;
			color: $ash-body;
		}

		&__btn-delete {
			&.btn {
				display: block;
				margin: 0 auto;
				width: 255px;
				max-width: 255px;
				margin-bottom: 1.5rem;
			}
		}
	}

	.btn-private {
		display: flex;
		align-items: center;
		justify-content: center;
		gap: 0.875rem;
		min-width: 200px;
		&.btn {
			border-color: $ash-line;
			color: $ash-body;
			&:hover {
				background-color: unset;
			}
		}
	}

	.pagination-group {
		margin-top: 60px;
	}

	&__search__message {
		margin-top: 1.75rem;
		color: $ash-body;
		text-align: center;
		font-weight: 600;
	}
}

@media only screen and (max-width: 1366px) {
	.mainContainer {
		.charts__reading__container {
			.charts__reading__container__main {
				.charts__reading__container__main__month {
					width: auto;
				}
			}
		}
	}
}

@media only screen and (max-width: 1280px) {
	.mainContainer {
		.mainContainer__main {
			.main-shelves {
				.main-shelves__header {
					h4 {
						font-size: 1.2rem;
					}
					.main-shelves__search {
						max-width: 400px;
					}
				}
			}
		}

		.mainContainer__right {
			width: 40%;
			flex: 1;
			.sidebar-shelves {
				.charts__reading__container {
					.charts__reading__container__main {
						.charts__reading__container__main__month {
							width: auto;
						}
					}
				}
			}
		}
	}
}

@media only screen and (max-width: 1024px) {
	.mainContainer {
		.mainContainer__main {
			.main-shelves {
				.main-shelves__header {
					h4 {
						font-size: 1.2rem;
					}
					.main-shelves__search {
						max-width: 456px;
					}
				}
				.book-item__name {
					font-size: 16px;
				}
			}
		}
	}
}

@media only screen and (max-width: 820px) {
	.mainContainer {
		.mainContainer__main {
			padding-left: 0px;
			padding-right: 0px;
			margin: 0;

			.main-shelves {
				.main-shelves__header {
					.main-shelves__search {
						max-width: 400px;
					}

					h4 {
						font-size: 1.2rem;
						margin-left: 30px;
					}
				}
			}
		}
		.mainContainer__right {
			display: none;
		}
	}
}

@media only screen and (max-width: 768px) {
	.mainContainer {
		.main-shelves {
			.main-shelves__pane {
				margin-right: 0px;
			}
			.main-shelves__search {
				margin: 0;
			}
		}
	}
}

// responsive điện thoại
@media only screen and (max-width: 500px) {
	.mainContainer {
		.mainContainer__main {
			.main-shelves {
				.book-item__name {
					font-size: small;
				}

				&__filters {
					margin-bottom: 1rem;
				}
			}
		}
	}
}
