@import 'src/scss/abstracts/_variables.scss';

.topic-column {
	&__header {
		font-size: 1.25rem;
		font-weight: 600;
		line-height: 1.5;
		color: $ash;
		margin: 0;
	}

	&__container {
		display: grid;
		grid-template-columns: repeat(auto-fit, minmax(100px, 1fr));
		grid-column-gap: 40px;
		overflow: hidden;
	}

	&__item {
		margin-top: 24px;
		font-size: 13px;
		font-weight: 700;
		line-height: 1.5;
		color: $ash;
		cursor: pointer;
		&:hover {
			font-weight: 600;
		}
		span {
			display: -webkit-box;
			-webkit-box-orient: vertical;
			-webkit-line-clamp: 1;
			overflow: hidden;
			&:hover {
				text-decoration: underline;
			}
		}
	}

	&__btn {
		display: flex;
		align-items: center;
		color: $ash;
		margin-top: 24px;
	}

	.view-caret {
		margin-right: 15px;
		transition: all 0.2s linear;
		&.view-more {
			transform: rotate(180deg);
		}
	}
}
