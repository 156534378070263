@import 'src/scss/abstracts/_global.scss';

.not-found {
	padding: 4.375rem 0;
	font-size: 1.125rem;
	text-align: center;
	&__title {
		font-weight: 700;
		margin-bottom: 1rem;
	}

	p {
		color: $ash-label;
	}

	&__img-container {
		width: 100%;
		max-width: 535px;
		height: auto;
		margin: 1.25rem auto;
	}

	img {
		width: 100%;
		object-fit: cover;
	}

	&__btn {
		width: 280px;
	}
}
