@import 'src/scss/abstracts/_variables.scss';

.filter-quote-pane {
	background: $white;
	padding: 1.75rem 2.5rem;
	border-radius: 16px;
	&__heading {
		margin-bottom: 2.25rem;
		display: flex;
		gap: 10px;
		flex-direction: row;
		justify-content: space-between;
		align-items: center;

		.filter-options {
			display: flex;
			flex-grow: 0.7;
			justify-content: space-evenly;
			gap: 0px;
		}
	}

	&__btn {
		display: flex;
		align-items: center;
		width: fit-content;
		&.btn {
			width: 220px;
			padding: 1rem;
			background-color: $primary-light;
			font-size: 1rem;
			line-height: 1.5;
			color: $ash;
			justify-content: center;
			&:hover {
				color: $ash;
			}
		}
	}

	&__text {
		white-space: nowrap;
	}

	&__icon {
		width: 1rem;
		height: 1rem;
		margin-right: 1rem;
	}

	&__options {
		margin-left: auto;
		margin-right: 2rem;
	}

	&__title {
		font-size: 1.25rem;
		font-weight: 600;
		line-height: 1.5;
		text-transform: capitalize;
	}

	&__subtitle {
		font-size: 0.825rem;
		font-weight: 600;
		color: $ash-label;
		display: inline-block;
		margin-left: 0.75rem;
	}

	&__setting {
		&.dropdown-menu {
			width: 352px;
			transform: translate(-50%, 10px);
			box-shadow: 0px 0px 32px rgba(0, 0, 0, 0.07);
			border-radius: 12px;
			padding: 48px 32px 44px;
			z-index: 100 !important;
		}

		&__title {
			margin-bottom: 8px;
			font-size: 18px;
			font-weight: 600;
			line-height: 1.5;
			padding-left: 12px;
		}

		&__btn {
			width: 100%;
			margin-top: 1.5rem;
		}
	}
}

.filter-quote-pane__setting > {
	.filter-quote-pane__setting__group {
		.form-check-label--custom {
			font-size: 18px;
			cursor: pointer;
		}
	}
}

@media only screen and (max-width: 1024px) {
	.filter-quote-pane {
		&__setting {
			&.dropdown-menu {
				transform: translate(-80%, 10px);
			}
		}
	}
}

@media only screen and (min-width: 821px) and (max-width: 1088px) {
	.filter-quote-pane {
		.quote-footer {
			flex-direction: column;
			gap: 1rem;
			&__left {
				width: 100%;
				justify-content: center;
			}
		}
	}
}

@media only screen and (max-width: 820px) {
	.filter-quote-pane {
		padding: 1.75rem 1.25rem;
	}
	.main-quote {
		.search-field.main-quote__search {
			max-width: 26.5rem;
		}
	}
}

@media only screen and (max-width: 778px) {
	.main-quote {
		.radio-input-container.form-check {
			gap: 0.5rem;
		}
	}
}

// responsive điện thoại
@media only screen and (max-width: 500px) {
	.filter-quote-pane {
		&__setting {
			&.dropdown-menu {
				padding: 20px;
				width: 270px;
			}

			&__title {
				font-size: medium;
			}

			.filter-quote-pane__setting__group {
				.form-check-label--custom {
					font-size: medium;
					padding: 0.8em 0;
				}
			}
		}

		&__options {
			margin-right: inherit;
		}

		&__btn.btn {
			width: inherit;
		}

		&__icon {
			margin-right: inherit;
		}

		&__text {
			display: none;
		}
	}
}
