@import 'src/scss/abstracts/_variables.scss';

.forget {
	&__container {
		max-width: 1440px;
		margin: auto;
		height: 100vh;
	}

	&__body {
		display: flex;
		padding-bottom: 48px;
		background-color: #fcfcfc;
		justify-content: space-around;
		align-items: center;
		&--wrapper {
			height: calc(100% - 120px);
			background-color: #fcfcfc;
			padding: 0 20px;
		}
	}

	&__form {
		&__img {
			margin-top: 46px;
			margin-left: 0px;
			max-width: 696px;
			max-height: 685px;
			margin-right: 40px;
			img {
				width: 100%;
			}
		}

		&__email {
			padding: 52px 16px 264px;
			margin-top: 60px;
			width: 400px;
			background: #fcfcfc;
			box-shadow: 0px 0px 32px rgba(0, 0, 0, 0.18);
			border-radius: 12px;
			height: fit-content;
			&.create-new-password {
				padding: 52px 16px 76px;
			}
		}
	}

	&__name-title {
		width: 100%;
		text-align: center;
		font-family: 'Montserrat';
		font-style: normal;
		font-weight: 600;
		font-size: 20px;
		line-height: 32px;
		letter-spacing: 0.75px;
		color: #2d2c42;
	}

	&__subcribe {
		margin-top: 20px;
		font-family: 'Montserrat';
		font-style: normal;
		font-weight: 500;
		font-size: 13px;
		line-height: 22px;
		letter-spacing: 0.25px;
		color: #4e4b66;
		text-align: center;
	}

	&__send-otp__link {
		margin-top: 20px;
		margin-bottom: 28px;
		text-align: center;
		color: #0576f0;
	}
}

.timer {
	display: inline-flex;
	justify-content: center;
	width: 50px;
	margin-left: 20px;
}

.hide-timer {
	display: none;
}

.forget__send-otp__link__get-otp-again {
	cursor: pointer;
}

.disabled-link {
	pointer-events: none;
	color: #8a8c95;
}

.disabled-btn-forgot {
	background-color: #d9dbe9 !important;
	color: #a0a3bd !important ;
	pointer-events: none;
}

.forgot__modal__container {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100vh;
	z-index: 1;
	display: flex;
	align-items: center;
	background-color: rgba(97, 97, 97, 0.5);
	justify-content: center;
}

.forgetPassword {
	width: 100%;

	&__form {
		&__wrapper {
			display: flex;
			flex-direction: column;
			align-items: center;
			width: 480px;
			max-width: 480px;
			margin-left: auto;
			padding: 80px;
			border-top-right-radius: 24px;
			border-top-left-radius: 24px;
			background-color: #fff;
			text-align: right;
			height: 100vh;
		}

		&-title {
			font-family: 'Montserrat';
			font-style: normal;
			font-weight: 600;
			font-size: 20px;
			line-height: 32px;
			display: flex;
			align-items: center;
			text-align: center;
			letter-spacing: 0.75px;
			color: #2d2c42;
			span {
				width: 100%;
			}
		}

		&__logo {
			width: 200px;
			height: auto;
			margin-bottom: 80px;
		}

		&__field {
			margin-top: 20px;
			border-radius: 12px;
			position: relative;
			background-color: #eff0f6;
			display: flex;
			justify-content: space-between;
			align-items: center;
			padding: 16px 12px;
			border: transparent 1px solid;
			&.error {
				border: $red 1px solid;
			}
			.error--text {
				display: none;
				&.show {
					display: block;
					margin-left: 8px;
				}
			}

			.login__form__error {
				position: relative;
				.login__form__error__popover-container {
					position: absolute;
					top: 115%;
					right: -130%;
					opacity: 0;
					z-index: -100;
					&.show {
						z-index: 100;
						opacity: 1;
						transition: opacity 0.3s ease-in;
					}
				}
			}

			.login__form__error__popover {
				padding: 8px;
				background-color: red;
				border-radius: 12px;
				color: #fcfcfc;
				z-index: 500;
				text-align: center;
				width: fit-content;
				white-space: nowrap;
				font-weight: 400;
				font-size: 11px;
				padding: 8px 36px;
				line-height: 22px;
			}

			.error--textbox {
				display: flex;
				justify-content: flex-end;
				margin-right: 30px;
			}

			.error--textbox--logo {
				border-left: 10px transparent solid;
				border-bottom: 10px red solid;
				width: 0;
			}

			.error--textbox--error {
				border-right: 10px transparent solid;
				border-bottom: 10px red solid;
				width: 0;
			}
		}

		&__link {
			display: flex;
			justify-content: center;
			align-items: center;
			height: 45px;
			margin-top: 12px;
			color: #0576f0;
		}

		&__input {
			width: 100%;
			background-color: #eff0f6;
			border: none;
			line-height: 24px;
			outline: none;
			padding-left: 8px;
			&::placeholder {
				color: #a0a3bd;
				font-family: 'Montserrat';
				font-style: normal;
				font-weight: 500;
				font-size: 13px;
				line-height: 22px;
				letter-spacing: 0.25;
			}
		}

		&__btn {
			width: 100%;
			height: 56px;
			padding: 116px, 24px, 16px, 24px;
			margin-top: 67px;
			background-color: #18bb0c;
			border: none;
			border-radius: 12px;
			line-height: 1.5;
			color: #fcfcfc;
			font-size: 18px;

			&-otp {
				width: 100%;
				height: 56px;
				padding: 116px, 24px, 16px, 24px;
				margin-top: 32px;
				background-color: #18bb0c;
				border: none;
				border-radius: 8px;
				line-height: 1.5;
				color: #fcfcfc;
			}
		}
	}
}

.input-1 {
	margin-top: 56px;
}

@media screen and (max-width: 1280px) {
	.forget {
		&__form {
			&__img {
				max-width: 600px;
			}

			&__email {
				margin-right: 20px;
			}
		}
	}
}

@media screen and (max-width: 1024px) {
	.forget__form__img {
		width: 500px;
	}
}

@media screen and (max-width: 768px) {
	.forget__form__img {
		display: none;
	}

	.forget__form__email {
		margin-right: 0px;
	}
}
