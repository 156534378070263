.reading-book {
	margin-bottom: 60px;
}

.reading-book__title {
	font-size: 1.25rem;
	font-weight: 600;
	margin-bottom: 24px;
	color: #2d2c42;
}

.reading-book__box {
	display: flex;
	justify-content: space-between;
	gap: 18px;
}

.reading-book__thumbnail {
	height: 162px;
	img {
		height: 100%;
		max-width: 108px;
		object-fit: cover;
		border-radius: 8px;
	}
}

.reading-book__information {
	flex-grow: 1;
	padding-top: 8px;
	font-size: 13.3px;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	word-break: break-word;
	width: 27%;
}

.reading-book__information__book-name {
	color: #2d2c42;
	font-weight: 600;
	line-height: 22px;
	display: -webkit-box;
	-webkit-box-orient: vertical;
	-webkit-line-clamp: 2;
	overflow: hidden;
	font-size: 18px;
}

.reading-book__information__author {
	line-height: 22px;
	color: #6e7191;
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
	max-width: 146px;
	font-size: 13.3px;
}

.reading-book__information__current-progress {
	font-size: 8.2px;
	.progress {
		height: 5.3px;
		border-radius: 2.7px;
		margin-bottom: 3px;
		.progress-bar {
			border-radius: 2.7px;
		}
	}
}

.reading-book__progress-percent {
	font-weight: 600;
	line-height: 14px;
	color: #6e7191;
}

.reading-book__update-progress {
	border: #e0af7e 2px solid;
	border-radius: 8px;
	line-height: 1.375rem;
	padding: 8px;
	font-weight: 600;
	color: #b38c64;
	font-size: 13px;
}

@media only screen and (max-width: 1366px) {
	.reading-book__update-progress {
		font-size: 12px;
	}
	.reading-book__box {
		gap: 12px;
	}
}

@media only screen and (max-width: 1312px) {
	.reading-book__update-progress {
		font-size: 11px;
	}
}

@media only screen and (max-width: 1246px) {
	.reading-book__update-progress {
		padding: 4px;
		font-size: 10px;
	}
}

@media only screen and (max-width: 1024px) {
	.reading-book__title {
		font-size: 18px;
	}
	.reading-book__information {
		margin-left: 0;
	}
	.reading-book__box {
		gap: 8px;
	}
}
