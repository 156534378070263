.book-reference {
	padding-bottom: 150px;
	&__slider {
		margin-bottom: 48px;
	}

	&__add-to-series {
		display: flex;
		align-items: center;
		margin-bottom: 33px;
		> span {
			font-weight: 600;
			font-size: 20px;
		}
	}

	.edit-name__pencil {
		cursor: pointer;
		scale: 0.7;
		position: absolute;
	}
}
@media only screen and (max-width: 1024px) {
	.book-reference {
		&__slider {
			margin-bottom: 1.5rem;
		}
	}
	.statistic-title {
		margin-top: -27px;
	}
}
@media only screen and (max-width: 820px) {
	.book-reference__slider {
		display: none;
	}
	.book-reference__highlight__post {
		display: none;
	}
	.dualColumn {
		display: none;
	}
}
