.category-search-container {
	padding: 0 28px 32px;
	background-color: white;

	.loading-indicator {
		padding-top: 32px;
	}
}

@media only screen and (max-width: 820px) {
	.category-search-container {
		padding: 0 24px;
	}
}

@media only screen and (max-width: 768px) {
	.category-search-container {
		padding: 0 20px;
	}
}
