.modalFollowers {
	&__container {
		border-radius: 16px;
		background: #ffffff;
		font-family: 'Montserrat';
		font-style: normal;
		z-index: 1000;
		box-shadow: 0px 0px 72px rgba(0, 0, 0, 0.04);
		overflow-x: hidden;
	}

	&__container {
		&__main .modal-dialog {
			max-width: 900px;
			.modal-content {
				border-radius: 16px;
			}
		}
	}

	&__header {
		display: flex;
		justify-content: space-around;
		justify-content: center;
		align-items: center;
		padding: 30px 50px;
		position: relative;
		&::after {
			content: '';
			background: #f1f1f5;
			height: 1px;
			width: 100%;
			position: absolute;
			top: 100%;
		}
	}

	&__title {
		letter-spacing: 0.75px;
		font-weight: 600;
		font-size: 20px;
		line-height: 32px;
		flex: 1;
		text-align: center;
		color: #2d2c42;
	}

	&__close {
		width: 40px;
		height: 40px;
		border-radius: 50%;
		background: #eff0f7;
		display: flex;
		justify-content: center;
		align-items: center;
		cursor: pointer;
	}

	&__search {
		margin: 40px 25px;
	}

	&__info {
		display: flex;
		flex-direction: column;
		gap: 20px;
		padding: 0 25px;
		max-height: 430px;
		overflow-y: scroll;

		&::-webkit-scrollbar {
			width: 8px;
			background-color: #f5f5f5;
		}

		&::-webkit-scrollbar-track {
			background-color: rgb(255, 255, 255);
			-webkit-border-radius: 16px;
			border-radius: 16px;
		}
		&::-webkit-scrollbar-thumb {
			border-radius: 16px;
			background-color: #cfcfcf;
		}

		.author-card {
			padding: 2.25rem;
			margin: 16px 0;

			&__left {
				margin-right: 10px;
				cursor: pointer;
				width: 300px;
				overflow: hidden;
			}
			&__right {
				.row {
					margin-right: inherit;
				}
			}

			&__info h5 {
				font-weight: 600;
				font-size: 18px;
				line-height: 24px;
				letter-spacing: 0.75px;
				color: #2d2c42;
			}

			&__subtitle {
				color: #6e7191;
				font-weight: 500;
				font-size: 13px;
				line-height: 22px;
				letter-spacing: 0.25px;
			}
		}
		.data__blank {
			text-align: center;
			display: block;
			margin: 100px 0px;
		}
	}
}

@media only screen and (max-width: 986px) {
	.modalFollowers__container__main {
		.modal-content {
			width: 85%;
			margin: 0 auto;
		}

		.connect-button {
			&.row {
				width: 600px;
				flex-direction: row;
			}
			&.btn {
				width: 45%;
				span {
					font-size: small;
				}
			}
		}
	}
}

// responsive điện thoại
@media only screen and (max-width: 500px) {
	.modalFollowers {
		&__header {
			padding: 0;

			&::after {
				top: 150%;
			}
		}

		&__title {
			font-size: medium;
			letter-spacing: inherit;
			line-height: 24px;
		}

		&__search {
			margin: 35px 0 15px 0;
		}

		&__container {
			&__main {
				.modal-content {
					width: inherit;
				}
				.connect-button.btn {
					width: 110px;
				}
			}
		}

		&__info {
			padding: 0;

			.author-card {
				padding: 1.5rem;

				&__left {
					margin-right: inherit;
					width: inherit;
				}

				h5 {
					font-size: medium;
				}

				&__subtitle {
					font-size: 10px;
				}
			}
		}
	}
}
