.friends__container {
	.notification__main__container {
		padding-top: 36px;
		gap: 20px;
		display: flex;
		align-items: center;

		.notification__main__title {
			font-size: 24px;
			font-weight: 700;
		}
	}
	.friends__detail__header {
		background: #ffffff;
		border-radius: 12px;
		padding: 22px 23px 23px 23px;
		margin-top: 34px;
		margin-bottom: 32px;
		.friends__search {
			display: flex;
			gap: 16px;
			.connect-button {
				background: #f5e7d8;
				border-radius: 12px;
				max-width: 120px;
				border: none;
				span {
					font-weight: 600;
					font-size: 15px;
					line-height: 24px;
					color: #b38c64;
				}
			}
		}
	}
}

// responsive điện thoại
@media only screen and (max-width: 500px) {
	.friends__container {
		.notification__main__container {
			.notification__main__title {
				font-size: medium;
				font-weight: 600;
				line-height: 1.5;
			}
		}

		.friends__detail__header {
			.friends__search {
				flex-direction: column;

				.connect-button {
					span {
						font-size: inherit;
					}
				}
			}
		}
	}
}
