@import 'src/scss/abstracts/_variables.scss';

.review-rating {
	display: flex;
	align-items: center;
	justify-content: center;
	gap: 2em;

	&__left {
		width: fit-content;
		display: flex;
		flex-direction: column;
		gap: 1.6em;
		align-items: center;
		font-size: 0.825rem;
		font-weight: 600;
		line-height: 1.5;
		color: $ash-label;
	}

	&__right {
		flex-grow: 1;
		max-width: 605px;
	}
}

// responsive điện thoại
@media only screen and (max-width: 500px) {
	.review-rating {
		flex-direction: column;

		&__left {
			gap: 0.8em;
		}

		&__right {
			width: 80%;
		}
	}
}
