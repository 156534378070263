.notification-status {
	cursor: pointer;
	padding: 8px;
	border-radius: 8px;
	margin-bottom: 20px;

	&:not(.disable-background):hover {
		background-color: #eaecec;
	}

	&.readed {
		&:not(.friend-request) {
			opacity: 0.7;
		}
	}

	&__main-content-wrapper {
		display: flex;
		gap: 15px;
		align-items: center;

		.system-notification__image {
			display: flex !important;
			justify-content: center;
			align-items: center;
			background-color: white;
			.user-avatar__img {
				width: 70%;
				height: auto;
				object-fit: cover;
				transform: translateX(1px);
			}
		}
	}

	&__main-content {
		flex-grow: 1;
		width: 50%;

		&__message {
			font-weight: 500;
			font-size: 15px;
			line-height: 24px;
			display: -webkit-box;
			-webkit-box-orient: vertical;
			-webkit-line-clamp: 2;
			overflow: hidden;
			span {
				font-weight: 600;
			}
		}

		&__time {
			color: #0576f0;
			font-weight: 500;
			font-size: 11px;
			line-height: 22px;
		}
	}

	&__reply-status {
		font-weight: 500;
		font-size: 13px;
		line-height: 22px;
		color: #e0af7e;
	}

	&__read-status {
		width: 16px;
		height: 16px;
		border-radius: 50%;
		background: #e0af7e;
		&.readed {
			background-color: transparent;
		}
	}

	&__reply-buttons {
		display: flex;
		gap: 20px;
		margin-top: 16px;
		margin-left: 70px;
	}

	&__reply-button {
		width: 180px;
		height: 46px;
		display: flex;
		align-items: center;
		justify-content: center;
		border-radius: 12px;
		font-weight: 600;
		font-size: 16px;
		line-height: 22px;
		letter-spacing: 0.25px;
		cursor: pointer;
		&.accept {
			background: #e0af7e;
			color: #fcfcfc;
		}
		&.refuse {
			background: #eff0f7;
			color: #6e7191;
		}
	}
}

// responsive điện thoại
@media only screen and (max-width: 500px) {
	.notification-status {
		&__main-content {
			&__message {
				font-size: small;
				line-height: 1.5;
			}
		}

		&__reply-buttons {
			margin-left: 0;
			justify-content: center;
		}

		&__reply-button {
			height: 40px;
			font-size: small;
		}
	}
}
