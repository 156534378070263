@import 'src/scss/abstracts/_variables.scss';

.book-item {
	cursor: pointer;
	display: flex;
	flex-direction: column;
	align-items: center;
	&__container {
		position: relative;
	}

	&__name {
		display: -webkit-box;
		-webkit-box-orient: vertical;
		-webkit-line-clamp: 2;
		max-height: 4rem;
		overflow: hidden;
		text-overflow: ellipsis;
		margin-top: 1.25em;
		font-size: 18px;
		font-weight: 600;
		line-height: 1.5;
		text-align: center;
		color: $ash;
	}

	&__author,
	&__text {
		font-size: 13px;
		font-weight: 500;
		line-height: 1.5;
	}

	&__author {
		color: $ash-label;
		margin: auto 0 0.5em;
	}

	&__text {
		color: $ash-placeholder;
		margin-top: 0.5em;
	}

	&__icon {
		width: 2.4rem;
		height: 2.4rem;
		padding: 0.5em;
		margin-top: 0.5em;
		margin-left: auto;
		border-radius: 50%;
		background-color: rgba($white-off, 1);
		border: 2px solid $ash-line;
		display: flex;
		align-items: center;
		justify-content: space-around;
		color: $ash-placeholder;
	}

	&__overlay {
		position: absolute;
		top: 12px;
		right: 12px;
		display: flex;
		flex-direction: column;
		align-items: center;
		width: 100%;
		&.position {
			left: 50%;
			right: 50%;
			transform: translate(-50%, 0);
			bottom: 60px;
		}

		.btn-status {
			padding: 1em 0.5em;
			width: 100%;
			color: $white;
			margin-top: auto;
			display: none;

			span {
				font-size: 1rem;
			}
		}

		.setting-more {
			margin-left: auto;
		}
	}
}

.book-item:hover .btn-status {
	display: flex;
}

@media (hover: none) {
	.book-item .btn-status {
		display: flex;
	}
}
