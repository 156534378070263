@import 'src/scss/abstracts/_variables.scss';

.category-group {
	margin-top: 2.5rem;
	border-bottom: 1px solid $grey-border;
	h3 {
		text-align: left;
		margin: 20px 0 24px;
		font-weight: 600;
		font-size: 1.25em;
	}
	&__link {
		width: 100%;
		text-align: right;
		display: inline-block;
		margin-top: 2rem;
		margin: 2rem 0 1.5rem;
		font-size: 15px;
		font-weight: 600;
		line-height: 1.5;
		text-decoration: underline;
		color: $blue;
		&:hover {
			color: $blue;
		}
	}
}
.data-blank {
	margin: 20px 0 0 0;
	min-height: 60px;
	h4 {
		margin-bottom: 10px;
		font-size: 1.25rem;
		font-weight: 600;
		color: #2d2c42;
	}
	p {
		color: #6e7191;
		font-size: 1rem;
	}
}

// responsive điện thoại
@media only screen and (max-width: 500px) {
	.category-group {
		margin-top: 1.5rem;

		&__link {
			margin: 1rem 0;
			font-size: small;
		}
	}
}
