.group__search {
	&__container {
		padding: 32px 28px;
	}

	&__main {
		background: #fbf7f2;
		border-radius: 16px;
		display: flex;
		padding: 24px 28px 24px 48px;
		justify-content: space-between;
		align-items: center;
		margin-bottom: 20px;
	}

	&__left {
		display: flex;
		align-items: center;
		gap: 40px;
	}

	&__img {
		width: 150px;
		border-radius: 8px;
		height: 150px;
		object-fit: cover;
	}

	&__title {
		font-size: 18px;
		font-weight: 600;
		line-height: 32px;
		max-width: 300px;
	}

	&__content {
		display: flex;
		flex-direction: column;
		gap: 30px;
	}

	&__info {
		font-size: 13px;
		font-weight: 600;
		line-height: 22px;
	}

	&__button {
		min-width: 168px;
		font-size: 16px;
	}
}

@media only screen and (max-width: 820px) {
	.group__search__container {
		padding: 28px 24px;
	}
}

@media only screen and (max-width: 768px) {
	.group__search {
		&__container {
			padding: 24px 20px;
		}

		&__main {
			padding: 16px 20px 16px 40px;
		}

		&__button {
			min-width: 150px;
			font-size: 13px;
		}
	}
}

// responsive điện thoại
@media only screen and (max-width: 500px) {
	.group__search {
		&__container {
			padding: 10px;
		}

		&__title {
			line-height: 1.5;
		}

		&__main {
			flex-direction: column;
			padding: 10px;
			gap: 15px;
		}

		&__left {
			flex-direction: column;
			gap: 10px;
		}

		&__content {
			gap: 8px;
		}

		&__img {
			max-width: 100%;
			width: auto;
			height: 130px;
		}

		&__button {
			min-width: inherit;
			font-size: smaller;
		}
	}
}
