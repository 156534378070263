@import 'src/scss/abstracts/_variables.scss';

.book-slider__title {
	font-size: 1.25rem;
	font-weight: 600;
	margin-bottom: 24px;
	color: #2d2c42;
}

.book-slider {
	.book-thumbnail {
		border: 1px solid $ash-line;
	}

	.slick-prev {
		left: 7%;
	}

	.slick-next {
		right: 4%;
	}

	.slick-arrow {
		display: flex !important;
		justify-content: center;
		border-radius: 50%;
		background-color: rgba(20, 20, 43, 0.4) !important;
		z-index: 500;
		width: 32px;
		height: 32px;
		padding: 0.3rem !important;
		&:before {
			display: none;
		}
		&:hover {
			background-color: rgba(20, 20, 43, 0.7) !important;
		}
	}

	&__content {
		&__item {
			margin-right: 16px;
		}
	}
}

@media only screen and (max-width: 992px) {
	.book-slider__title {
		font-size: 1.1rem;
	}
}
@media only screen and (max-width: 1024px) {
	.slick-arrow {
		width: 25px !important;
		height: 25px !important;
	}

	.dualColumn-item__title {
		margin-top: 4px;
	}
	.book-slider__title {
		font-size: 18px;
	}
}

// responsive điện thoại
@media only screen and (max-width: 500px) {
	.book-slider {
		&__title {
			margin-bottom: 15px;
			font-size: medium;
		}
	}
}
