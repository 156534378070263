@import 'src/scss/abstracts/_variables.scss';

.main-reading-author {
	&__header {
		display: flex;
		flex-wrap: wrap;
		gap: 10px;
		align-items: center;
		justify-content: space-between;
		h4 {
			margin: 0;
			min-width: fit-content;
		}
	}

	&__books-wrapper {
		overflow-x: auto;
		width: 100%;
		margin-top: 80px;

		&.border-left {
			border-left: 8px solid white;
		}

		&.border-right {
			border-right: 8px solid white;
		}
	}

	&__books {
		min-width: 917px;

		&__title {
			display: grid;
			grid-template-columns: 57px 0.8fr 0.7fr 0.7fr 0.8fr 0.75fr 1fr 25px;
			grid-gap: 12px;
			padding: 16px 36px;
			background-color: white;
			border-radius: 16px;
		}
		&__column {
			line-height: 22px;
			font-size: 13px;
			font-weight: 600;
			text-align: center;
		}
		&__content {
			margin-top: 40px;
			padding: 16px 20px;
			background-color: white;
			border-radius: 12px;

			.infinite-scroll-component {
				display: flex;
				flex-direction: column;
				gap: 16px;
			}
		}

		&__item {
			display: grid;
			grid-template-columns: 57px 0.8fr 0.7fr 0.7fr 0.8fr 0.75fr 1fr 25px;
			grid-gap: 12px;
			padding: 12px 16px;
			background-color: $secondary;
			border-radius: 12px;

			&__column {
				display: flex;
				flex-direction: column;
				gap: 5px;
				justify-content: center;
				align-items: center;
				height: 82px;
				overflow: hidden;
				font-size: 14px;
				cursor: pointer;
				&.book-image {
					img {
						width: 100%;
						height: 100%;
						object-fit: cover;
						border-radius: 4px;
					}
				}
				&.book-name {
					display: -webkit-box;
					-webkit-box-orient: vertical;
					-webkit-line-clamp: 4;
					line-height: 20px;
					font-weight: 600;
				}
			}

			&__top {
				height: 26px;
				font-weight: 600;
				display: flex;
				align-items: center;
				justify-content: center;
				span {
					margin-top: 2px;
					&.underline-and-gold-color {
						text-decoration: underline;
						color: $primary-dark;
					}
				}
				svg {
					width: 22px;
					margin-left: 4px;
				}
			}

			&__under {
				font-size: 13px;
				text-decoration: underline;
				text-align: center;
				color: $blue;
				height: 22px;
				display: flex;
				align-items: center;
			}
		}
	}

	&__share-btn {
		background-color: #f5e7d8;
		color: #2d2c42;
		width: 220px;
		display: block;
		margin: 38px 0 80px auto;
	}
}

// responsive điện thoại
@media screen {
	.main-reading-author__books-wrapper {
		margin-top: 40px;
	}
}
