@import 'src/scss/abstracts/_global.scss';

.book-intro {
	display: flex;
	line-height: 1.5;
	gap: 2.25rem;

	&__image {
		display: flex;
		flex-direction: column;
		align-items: stretch;
		gap: 24px;
		.book-thumbnail {
			cursor: default;
		}
	}

	&__btn {
		width: 200px;
	}

	&__content {
		display: flex;
		flex-direction: column;
		justify-content: space-between;
		gap: 24px;
		width: 100%;

		&__infomations {
			flex-grow: 1;
			display: flex;
			flex-direction: column;
		}
	}

	&__name {
		font-size: 1.4rem;
		line-height: 1.5;
		font-weight: 700;
		color: $ash;
		display: -webkit-box;
		-webkit-box-orient: vertical;
		-webkit-line-clamp: 2;
		text-overflow: ellipsis;
		overflow: hidden;
	}

	&__author-and-translator {
		margin-bottom: 12px;
		font-size: 15px;
		font-weight: 400;
	}

	&__author {
		display: flex;
		align-items: center;
		flex-wrap: wrap;

		.verified {
			cursor: pointer;
			&:hover {
				text-decoration: underline;
			}
		}

		&__name {
			display: flex;
			align-items: center;
			margin-left: 4px;
		}
	}

	&__check {
		margin-left: 4px;
	}

	&__is-in-top {
		display: flex;
		align-items: center;
		padding: 6px 12px;
		gap: 9px;
		width: fit-content;
		background: #d9dbe9;
		border-radius: 30px;
		margin-bottom: 10px;
		font-size: 14px;

		&__crown {
			svg {
				display: block;
				width: 22px;
				height: 22px;
			}
		}
	}

	&__stars {
		display: flex;
		align-items: center;
		gap: 0.56rem;
		margin-bottom: 10px;
		span {
			font-size: 0.825rem;
			font-weight: 600;
			color: $ash-placeholder;
		}
	}

	&__action {
		height: 56px;
		background: $white;
		max-width: 200px;
		border-radius: 12px;
		overflow: hidden;
		.react-share__ShareButton {
			width: 100% !important;
			height: 100%;
			display: flex;
			gap: 1.875rem;
			display: flex;
			justify-content: center;
			align-items: center;
			padding: 0 1.25rem !important;
		}
	}

	&__share {
		display: flex;
		align-items: center;
		img {
			max-height: 24px;
		}
		&__text {
			margin-left: 1rem;
			display: inline-block;
			font-size: 0.825rem;
			font-weight: 600;
			color: $ash-label;
		}
	}

	.react-share__ShareButton {
		width: fit-content;
	}
}

@media only screen and (max-width: 768px) {
	.book-intro {
		flex-direction: column;
		gap: 1.5rem;

		&__image {
			flex-direction: row;
		}

		&__content {
			width: inherit;
		}

		&__btn {
			width: inherit;
		}
	}
}
.button-link:hover {
	box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
}
.button-link:disabled {
	cursor: not-allowed;
	box-shadow: none;

}
.cursor-pointer {
	cursor: pointer;
}
.popup-link {
	bottom: 0;
	left: 100%;
	padding: 10px;
	box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
	border-radius: 12px;
	width: 100%;
	z-index: 1;
	min-width: 240px;
}
.item__link {
	padding: 5px 10px;
	img {
		width: 30px;
		height: 30px;
		border-radius: 50%;
	}
}

// responsive điện thoại
@media only screen and (max-width: 500px) {
	.book-intro {
		&__image {
			gap: 16px;
		}

		&__name {
			font-size: medium;
			-webkit-line-clamp: inherit;
			margin-bottom: 1rem;
		}

		&__author {
			font-size: small;
			&__name {
				svg {
					width: 15px;
					height: 15px;
				}
			}
		}

		&__is-in-top {
			border-radius: 12px;
			font-size: 10px;
			padding: 4px 8px;

			&__crown {
				svg {
					width: 18px;
					height: 18px;
				}
			}
		}

		&__content {
			width: inherit;
		}

		&__btn {
			width: inherit;
		}
		.btn-status {
			height: 40px;

			> span {
				font-size: 10px;
			}

			.text-status {
				display: none;
			}
		}

		&__action {
			height: 40px;

			.react-share__ShareButton {
				gap: 1.2rem;
			}
		}

		&__share {
			img {
				max-height: 18px;
			}
		}
		&__buy{
			height: 40px !important;
			span{
				display: none;
			}
		}
	}
}
@media only screen and (max-width: 724px) {
	.popup-link {
		min-width: 100%;
	}
}
