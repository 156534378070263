.intro__container {
	padding: 32px 24px 29px 24px;
	border-bottom-left-radius: 16px;
	border-bottom-right-radius: 16px;
}
.intro-content,
.intro-activity {
	border: 1px solid #d9dbe9;
	padding: 20px 12px 24px 20px;
	box-sizing: border-box;
	border-radius: 16px;
	.intro-content__title {
		h3 {
			font-weight: 600;
			font-size: 18px;
			line-height: 32px;
			display: flex;
			align-items: center;
			letter-spacing: 0.75px;
			color: #2d2c42;
		}
	}
}
.intro-activity {
	margin-top: 20px;
	h3 {
		font-size: 18px;
	}
	span {
		strong {
			font-weight: 600;
		}
	}
}

// responsive điện thoại
@media only screen and (max-width: 500px) {
	.intro__container {
		padding: 15px 10px;
	}

	.intro-content,
	.intro-activity {
		padding: 15px 10px;
	}
}
