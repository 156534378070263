@import 'src/scss/abstracts/_variables.scss';

.invite-friends-wrapper {
	background-color: white;
	padding: 26px 28px;
	border-radius: 14px;
	margin-bottom: 28px;
	.invite-friends__title {
		font-weight: 700;
		font-size: 1.5em;
		line-height: 1.5;
		letter-spacing: 1px;
	}
	.invite-friends {
		&__by-id {
			margin-top: 24px;
			padding: 14px;
			font-size: 13px;
			line-height: 1.7em;
			display: flex;
			align-items: center;
			justify-content: space-between;
			background-color: #eff0f6;
			border-radius: 12px;

			&__code {
				color: #14142b;
				overflow: hidden;
				text-overflow: ellipsis;
				width: 80%;
				white-space: nowrap;
				background-color: transparent;

				&:focus {
					outline: none;
				}
			}

			&__copy-code-btn {
				font-weight: 600;
				color: #b38c64;
				cursor: pointer;
			}
		}

		&__by-external-link {
			margin-top: 1rem;

			&__text {
				font-size: 0.94em;
				line-height: 1.5;
				color: #d9dbe9;
			}

			&__buttons {
				margin-top: 8px;
				display: flex;
				justify-content: space-between;
				align-items: center;
				gap: 1.1rem;
			}

			&__button {
				flex-grow: 1;
				padding: 13px 0;
				display: flex;
				align-items: center;
				justify-content: center;
				gap: 12px;
				font-weight: 600;
				border-radius: 12px;
				&.facebook-btn {
					background-color: #4666d5;
					color: white;
				}
				&.google-btn {
					color: $ash-body;
					background: #eff0f7;
				}
			}
		}
	}

	.iconCheck__copy__link {
		margin-right: 8px;
		svg path {
			stroke: #0a9a16;
		}
	}
}

.myfriends {
	&__container {
		background: #ffffff;
		border-radius: 16px;
		height: fit-content;
		font-family: 'Montserrat';
		font-style: normal;
		padding: 24px;
		margin-bottom: 32px;

		&__content {
			display: flex;
			align-items: center;
			justify-content: space-between;

			.myfriends__title__addfriend {
				font-weight: 700;
				font-size: 24px;
				line-height: 32px;
				color: #2d2c42;
				letter-spacing: 1px;
			}
		}

		.myFriend__blank {
			text-align: center;
			padding: 30px 0 0 0;

			p {
				margin-bottom: 20px;
			}
		}
	}

	&__title {
		font-weight: 700;
		font-size: 24px;
		line-height: 32px;
		letter-spacing: 1px;

		&__all {
			font-weight: 500;
			font-size: 15px;
			line-height: 24px;
			color: #0576f0;
			letter-spacing: 0.75px;
			cursor: pointer;
			white-space: nowrap;
		}
	}

	&__line {
		border: 2px solid #f1f1f5;
		margin-top: 12px;
		margin-bottom: 40px;
	}

	&__layout__container {
		display: grid;
		grid-template-columns: repeat(3, 1fr);
		gap: 24px;
		margin: 28px 1px;
		.myfriends__layout {
			background: #fbf7f2;
			border-radius: 16px;
			margin: 0 auto;
			.myfriends__star {
				display: flex;
				align-items: center;
				justify-content: center;
				gap: 7px;
				padding: 15px 11px 7px;
				.myfriends__star__name {
					font-weight: 600;
					font-size: 18px;
					line-height: 24px;
					letter-spacing: 0.75px;
					color: #2d2c42;
					text-align: center;
				}
			}
			.myfriends__layout__img {
				border-top-left-radius: 16px;
				border-top-right-radius: 16px;
				max-height: 198px;
				object-fit: cover;
			}
			.myfriends__name {
				font-weight: 600;
				font-size: 15px;
				line-height: 24px;
				letter-spacing: 0.75px;
				color: #2d2c42;
				text-align: center;
				margin: 15px 0 7px 0;
			}
			.myfriends__button__container {
				padding: 0 11px 28px 11px;
				display: flex;
				justify-content: center;
				flex-direction: column;
				margin-top: auto;
				.myfriends__button {
					border-radius: 12px;
					display: flex;
					align-items: center;
					justify-content: center;
					margin-top: 8px;
					.myfriends__button__content {
						font-weight: 600;
						font-size: 13px;
						line-height: 22px;
						letter-spacing: 0.25px;
						display: inline-block;
					}
				}
			}
		}
	}
}

// responsive điện thoại
@media only screen and (max-width: 500px) {
	.invite-friends-wrapper {
		padding: 26px 10px;
		.invite-friends {
			&__title {
				font-weight: 600;
				font-size: 1.2em;
				line-height: 1.2;
			}

			&__by-id {
				margin-top: 16px;
				flex-wrap: wrap;
				padding: 10px;

				&__code {
					width: 100%;
				}
			}

			&__by-external-link {
				&__button {
					padding: 8px 0;
				}
			}
		}
	}

	.myfriends {
		&__container {
			padding: 24px 10px;

			&__content {
				.myfriends__title {
					&__addfriend {
						font-weight: 700;
						font-size: medium;
						line-height: medium;
					}

					&__all {
						font-size: small;
					}
				}
			}
		}

		&__title {
			font-size: medium;
			line-height: normal;
		}

		&__layout {
			&__container {
				grid-template-columns: repeat(2, 1fr) !important;
				gap: 10px;
			}
		}
	}
}

@media only screen and (max-width: 320px) {
	.myfriends {
		&__layout {
			&__container {
				grid-template-columns: repeat(1, 1fr) !important;
			}
		}
	}
}
