.read-challenge {
	margin-bottom: 60px;

	&__title {
		font-size: 1.25rem;
		font-weight: 600;
		margin-bottom: 24px;
		color: #2d2c42;
	}

	&__box {
		background-color: white;
		border-radius: 16px;
		padding: 20px 16px;
	}

	&__description {
		font-size: 15px;
		line-height: 24px;
		color: #6e7191;
		letter-spacing: 0.75px;
		margin-bottom: 20px;
	}

	&__input {
		display: flex;
		justify-content: center;
		align-items: center;
		gap: 14px;
		margin-bottom: 16px;
	}

	&__input__button-element {
		width: 32px;
		height: 32px;
		display: flex;
		justify-content: center;
		align-items: center;
		color: #2d2c42;
		font-size: 16px;
		font-weight: 600;
		background-color: #eff0f6;
		border-radius: 50%;
	}

	&__input__input-element {
		width: 40px;
		border: none;
		outline: none;
		text-align: center;
		color: #2d2c42;
		font-size: 16px;
		font-weight: 600;
	}

	&__start-challenge-btn {
		padding: 9px;
		border: #e0af7e 2px solid;
		border-radius: 8px;
		width: 100%;
		color: #b38c64;
		font-size: 13px;
		line-height: 22px;
		font-weight: 600;
	}
}
