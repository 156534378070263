.rating-level {
	margin-bottom: 1.5em;
	display: flex;
	align-items: center;
	font-size: 0.825rem;
	font-weight: 600;
	line-height: 1.5;
	.linear-progress {
		width: 100%;
		margin-right: 1.4em;
	}

	&__icon {
		display: inline-block;
		margin: 0 0.875em;
	}
	.rating-percent {
		width: 75px;
		display: block;
	}
}

// responsive điện thoại
@media only screen and (max-width: 500px) {
	.rating-level {
		margin-bottom: 0.8em;
	}
}
