.input__hstag {
	display: flex;
	width: 244px;
	height: 56px;
	background-color: #f7f7fc;
	align-items: center;
	border: 1px solid #d9dbe9;
	border-radius: 16px;
	gap: 18px;
	padding-left: 21px;
}
.input__img {
	width: 20px;
	height: 20px;
}
.inputag {
	font-family: 'Montserrat';
	font-style: normal;
	font-weight: 500;
	font-size: 15px;
	line-height: 24px;

	letter-spacing: 0.75px;
	color: #a0a3bd;
	background-color: #f7f7fc;
}
