@import 'src/scss/abstracts/_variables.scss';

.search-field {
	height: 57px;
	flex-grow: 1;
	padding: 0 1.5em;
	border-radius: 16px;
	overflow: hidden;
	display: flex;
	align-items: center;
	background-color: $ash-background;

	&__icon {
		width: 20px;
		height: 20px;
		margin-right: 20px;
	}

	&__input {
		flex-grow: 1;
		max-width: 100%;
		background-color: inherit;
		border: none;
		outline: none;
		font-size: 15px;
		overflow: hidden;
		&:focus {
			border: none;
		}
		&:placeholder-shown {
			text-overflow: ellipsis;
		}
	}
}

// responsive điện thoại
@media only screen and (max-width: 500px) {
	.search-field {
		height: 40px;
		border-radius: 12px;

		&__icon {
			margin-right: 10px;
		}

		&__input {
			font-size: small;
		}
	}
}
