.friends__container {
	max-width: 700px;
	margin: 0 auto;
	font-family: 'Montserrat';
	font-style: normal;
	min-height: 100vh;
	.friends__content {
		font-weight: 700;
		font-size: 36px;
		line-height: 48px;
		padding: 34px 0 26px 0;
	}
	.friends__header {
		background: #ffffff;
		border-radius: 12px;
		padding: 22px;
		.search__container {
			padding: 0;
		}

		.friend__radio {
			display: grid;
			grid-template-columns: auto auto auto auto;
			gap: 28px;
			margin-top: 22px;
			label {
				font-size: 18px;
			}
			[type='radio']:checked,
			[type='radio']:not(:checked) {
				position: absolute;
				left: -9999px;
			}
			[type='radio']:checked + label,
			[type='radio']:not(:checked) + label {
				position: relative;
				padding-left: 28px;
				cursor: pointer;
				line-height: 20px;
				display: inline-block;
				color: #666;
			}
			[type='radio']:checked + label:before,
			[type='radio']:not(:checked) + label:before {
				content: '';
				position: absolute;
				left: 0;
				top: 0;
				width: 20px;
				height: 20px;
				border: 2px solid #a0a3bd;
				border-radius: 100%;
				background: #fff;
			}
			[type='radio']:checked + label:after,
			[type='radio']:not(:checked) + label:after {
				content: '';
				width: 12px;
				height: 12px;
				background: #e0af7e;
				position: absolute;
				top: 4px;
				left: 4px;
				border-radius: 100%;
				-webkit-transition: all 0.2s ease;
				transition: all 0.2s ease;
			}
			[type='radio']:checked + label:before {
				border: 2px solid #b38c64;
			}
			[type='radio']:not(:checked) + label:after {
				opacity: 0;
				-webkit-transform: scale(0);
				transform: scale(0);
			}
			[type='radio']:checked + label:after {
				opacity: 1;
				-webkit-transform: scale(1);
				transform: scale(1);
			}
		}
	}
	.friends__main {
		margin-top: 28px;
	}
	.connect-button.btn {
		padding: 14px 16px;
	}
}

// responsive điện thoại
@media only screen and (max-width: 500px) {
	.friends__container {
		padding: 0 10px;
		.friends__content {
			font-size: 1.2rem;
			margin-left: 30px;
			line-height: 1.2;
			font-weight: 600;
		}

		.friends__header {
			padding: 10px;

			.friend__radio {
				margin-top: 16px;
				grid-template-columns: auto auto;
				gap: 9px;
				p {
					label {
						font-size: small;
					}
				}
			}
		}

		.connect-button.btn {
			padding: inherit;
		}
	}
}
