@import 'src/scss/abstracts/_variables.scss';

.input {
	width: 100%;
	min-height: 57px;
	padding: 16px 24px;
	font-size: 0.94rem;
	line-height: 1.5;
	color: $ash;
	outline: none;
	border: none;
	border-radius: 12px;
	background-color: #f7f7fc;
	&:focus {
		outline: none;
	}

	&::placeholder {
		color: $ash-placeholder;
	}
	&.input--none-border {
		border: none;
	}
	&.input--border {
		border: 1px solid $ash-line;
	}
}

// responsive điện thoại
@media only screen and (max-width: 500px) {
	.input {
		min-height: 40px;
		font-size: small;
		padding: 8px 16px;
		font-size: small;

		&::-webkit-input-placeholder {
			font-size: small;
			font-style: normal;
		}
	}
}
