.search-group__container {
	margin-top: 53px;
}
.searh-group__member {
	display: flex;
	flex-direction: column;
	gap: 1rem;
	background-color: #fff;
	border-radius: 12px;
	padding: 23px 12px;
	margin-bottom: 24px;

	.member-item {
		margin: 0;
	}
}

.not-found-group {
	margin-top: 53px;
	background-color: #fff;
	padding: 24px;
	border-radius: 12px;
}

.search-group__not-found {
	background-color: white;
	margin: 32px 0px;
	border-radius: 16px;

	display: flex;
	align-items: center;
	justify-content: center;

	.result__notfound__main {
		margin-top: 30px;
	}
}
