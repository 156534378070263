@import 'src/scss/abstracts/_global.scss';

.popup-invite-friend-container {
	background-color: #fcfcfc;
	box-shadow: 0px 0px 32px rgba(0, 0, 0, 0.14);
	padding: 24px 0;
	border-radius: 24px;

	hr {
		margin-top: 24px;
	}

	&__body {
		padding: 0 24px;
	}

	.invite-sumit {
		margin-top: 10px;
		padding: 0px 24px;
		display: flex;
		justify-content: center;
		width: 100%;
		button {
			align-items: center;
			background-color: $primary;
			border-radius: 12px;
			cursor: pointer;
			padding: 16px;
			width: 100%;
			color: #fff;
		}
	}
}

.title-popup {
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding: 0 24px;
	button {
		background-color: #eff0f7;
		width: 48px;
		aspect-ratio: 1;
		border-radius: 50%;
	}
	h3 {
		flex: 1;
		text-align: center;
		font-size: 20px;
	}
}

.search-friend {
	margin-top: 24px;
	display: flex;
	button {
		margin-left: 24px;
		color: #0576f0;
	}
}

.modal-popup-container {
	width: 100%;
	max-width: 700px;
	position: fixed;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	z-index: 2000;
}

.main-action {
	margin-top: 40px;
	display: grid;
	grid-template-columns: repeat(2, 1fr);
	height: 50vh;
	overflow-x: hidden;
	overflow-y: scroll;
	gap: 10px;
	&::-webkit-scrollbar {
		width: 8px;
	}

	/* Track */
	&::-webkit-scrollbar-track {
		background: transparent;
		border-radius: 8px;
	}

	/* Handle */
	&::-webkit-scrollbar-thumb {
		background: rgba(#606060, 0.3);
		border-radius: 8px;
		&:hover {
			background: rgba(#606060, 0.2);
		}
	}
	.list-friend {
		h4 {
			font-size: 18px;
		}
	}
	.list-friend-select {
		h4 {
			font-size: 18px;
		}
	}
}

.friend-item {
	display: flex;
	align-items: center;
	margin-bottom: 32px;
	justify-content: space-between;
	&__avatar {
		display: flex;
		align-items: center;
		img {
			width: 60px;
			height: 60px;
			border-radius: 50%;
			object-fit: cover;
		}
		span {
			display: inline-block;
			font-style: normal;
			font-weight: 600;
			font-size: 13px;
			line-height: 22px;
			letter-spacing: 0.25px;
			color: #2d2c42;
			margin-left: 20px;
			max-width: 150px;
			text-overflow: ellipsis;
			overflow: hidden;
			white-space: nowrap;
		}
	}
	input {
		cursor: pointer;
		width: 24px;
		height: 24px;
	}
	button {
		margin-left: 24px;
		width: 24px;
		height: 24px;
		display: flex;
		align-items: center;
	}
}

.popup-question {
	&__container {
		width: 700px;
		background-color: #fcfcfc;
		border-radius: 12px;
		padding: 24px;
	}
	&__title {
		display: flex;
		align-items: center;
		justify-content: space-between;
		button {
			width: 48px;
			height: 48px;
			background-color: #eff0f7;
			border-radius: 50%;
		}
		h3 {
			flex: 1;
			text-align: center;
		}
	}
	&__name-group {
		display: flex;
		img {
			width: 64px;
			height: 64px;
			border-radius: 8px;
		}
	}
	&__name-group-text {
		margin-left: 18px;
		div {
			margin-top: 10px;
			color: #2d2c42;
		}
	}
	&__description {
		margin-top: 24px;
		padding: 24px;
		background-color: #f7f7fc;
		border-radius: 12px;
		span {
			font-family: 'Montserrat';
			font-style: normal;
			font-weight: 500;
			font-size: 15px;
			line-height: 24px;
			display: flex;
			align-items: center;
			letter-spacing: 0.75px;
			color: #4e4b66;
		}
	}
	&__select {
		margin-top: 24px;
		padding: 24px;
		background-color: #f7f7fc;
		border-radius: 12px;

		div {
			width: 50%;
			background-color: #fcfcfc;
			margin-bottom: 12px;
			height: 56px;
			align-items: center;
			display: flex;
			border-radius: 8px;
			input {
				display: flex;
				align-items: center;
				width: 22px;
				height: 22px;
				margin: 17px;
			}
		}
		span {
			font-family: 'Montserrat';
			font-style: normal;
			font-weight: 500;
			font-size: 15px;
			line-height: 24px;
			letter-spacing: 0.75px;
			color: #4e4b66;
		}
	}
	&__answer {
		margin-top: 24px;
		padding: 24px;
		background: #f7f7fc;
		border-radius: 12px;
		.form-field-textarea {
			background: #f7f7fc;
			outline: none;
		}
		h4 {
			font-family: 'Montserrat';
			font-style: normal;
			font-weight: 500;
			font-size: 15px;
			line-height: 24px;
			letter-spacing: 0.75px;
			color: #4e4b66;
		}
	}
	&__btn {
		margin-top: 57px;
		background-color: #d9dbe9;
		border-radius: 12px;
		display: flex;
		justify-content: center;
		align-items: center;
		height: 56px;
		button {
			font-family: 'Montserrat';
			font-style: normal;
			font-weight: 600;
			font-size: 15px;
			line-height: 24px;
			display: flex;
			align-items: center;
			text-align: center;
			letter-spacing: 0.75px;
			color: #a0a3bd;
		}
	}
}

.mini-popup__container {
	background-color: #fcfcfc;
}

// responsive điện thoại
@media only screen and (max-width: 500px) {
	.popup-invite-friend-container {
		padding: 15px 0;

		&__body {
			padding: 0 10px;
		}

		hr {
			margin-top: 10px;
		}
	}

	.title-popup {
		padding: 0 10px;

		button {
			width: 30px;

			svg {
				width: 16px;
				height: 16px;
			}
		}

		h3 {
			font-size: medium;
		}
	}

	.main-action {
		.list-friend,
		.list-friend-select {
			h4 {
				font-size: small;
				text-align: center;
				height: 32px;
				margin-bottom: 5px;
			}
		}
	}

	.friend-item {
		margin-bottom: 10px;
		justify-content: flex-end;

		&__avatar {
			flex-direction: column;
			flex-grow: 1;
			span {
				white-space: inherit;
				margin-left: 0;
				font-size: smaller;
			}
		}

		button {
			margin-left: 0;
		}
	}
}
