@import 'src/scss/abstracts/_variables.scss';

.select-box {
	position: relative;
	font-size: 13px;
	line-height: 1.5;
	height: 57px;

	&__btn {
		display: flex;
		align-items: center;
		gap: 0.625rem;
		padding: 1rem 1.5rem;
		border-radius: 12px;
		background-color: $ash-background;
		height: 100%;
		cursor: pointer;
	}

	&__value {
		font-size: 15px;
	}

	&__list {
		position: absolute;
		top: 100%;
		left: 0;
		width: 100%;
		max-height: 182px;
		padding: 0.5rem 0;
		overflow-x: hidden;
		overflow-y: auto;
		box-shadow: 0px 0px 32px rgba(0, 0, 0, 0.1);
		background-color: $secondary;
		border-radius: 12px;
		z-index: 10;

		&::-webkit-scrollbar {
			width: 8px;
		}

		&::-webkit-scrollbar-track {
			box-shadow: inset 0 0 10px $ash-line;
			border-radius: 10px;
		}

		&::-webkit-scrollbar-thumb {
			background: #bcc0c4;
			border-radius: 10px;
		}
	}

	&__icon {
		flex-shrink: 0;
	}

	&__item {
		padding: 0.75rem 0.5rem;
		margin: 0 0.5rem;
		display: flex;
		justify-content: space-between;
		border-radius: 12px;
		cursor: pointer;
		&:hover {
			background-color: #f2f2f2;
			font-weight: 700;
			transition: all 0.3s ease-in;
		}

		&.active {
			border: 2px solid #4894f5;
			background-color: #f2f2f2;
			font-weight: 600;

			svg {
				path {
					stroke: #4894f5;
					stroke-width: 2;
				}
			}
		}
	}
}

// responsive điện thoại
@media only screen and (max-width: 500px) {
	.select-box {
		height: 40px;

		&__value {
			font-size: small;
		}
	}
}
