.group-main__container {
	display: flex;
	.mainContainer__main {
		max-width: 100% !important;
	}
	.tab-content {
		padding-bottom: 0 !important;
		border-bottom-left-radius: 24px;
		border-bottom-right-radius: 24px;
	}
	.group-main-component__container {
		border-radius: 24px;
		flex: 1;
	}
}
.group-main-component__container {
	border-radius: 24px;
	flex: 1;
}

.group__main-container {
	.layout {
		.layout__container {
			.mainContainer {
				padding: 0 !important;
				padding-left: 40px !important;
				padding-top: 49px !important;
				.mainContainer__main {
					max-width: 100%;
				}
			}
		}
	}
	.tab-content {
		border-bottom-left-radius: 16px;
		border-bottom-right-radius: 16px;
		padding-bottom: 0rem;
	}
}

.main-content__post {
	margin-top: 41px;
	&__review {
		margin-top: 0px;
	}
}

.tab-content > .active {
	border-radius: 24px;
}

.hastag__group {
	display: flex;
	justify-content: space-between;
	margin-top: 24px;
	font-family: 'Montserrat';
	font-style: normal;
	font-weight: 600;
	font-size: 13px;
	align-items: center;
	letter-spacing: 0.25px;
	color: #2d2c42;
	&-name {
		word-break: break-word;
		padding-right: 5px;
		cursor: pointer;
	}
	&-number {
		font-family: 'Montserrat';
		font-style: normal;
		font-weight: 600;
		font-size: 16px;
		line-height: 24px;
		display: flex;
		align-items: center;
		text-align: right;
		letter-spacing: 0.75px;
		min-width: fit-content;
	}
}

.more__btn {
	margin-top: 35px;
	font-family: 'Montserrat';
	font-style: normal;
	font-weight: 600;
	font-size: 13px;
	line-height: 22px;
	display: flex;
	align-items: center;
	letter-spacing: 0.25px;
	color: #2d2c42;
	svg {
		margin-left: 5px;
		margin-right: 15px;
		transition: all 0.25s;
	}
}

.rotate__more {
	svg {
		transform: rotate(180deg);
		transition: all 0.25s;
	}
}

@media only screen and (max-width: 1024px) {
	.group-sidebar-right {
		.search-field__icon {
			width: 15px;
			height: 15px;
			margin-right: 10px;
		}
		.search-field {
			padding: 0 0.7rem;
		}
		.hastag__group-number {
			font-size: 13px;
			letter-spacing: 0px;
		}
	}
}

@media only screen and (max-width: 768px) {
}
