.hashtag-page {
	max-width: 720px;
	margin: auto;
	padding: 40px 10px 0;

	&__header {
		display: flex;
		align-items: center;
		gap: 20px;
		margin-bottom: 24px;
		h4 {
			margin: 0;
		}
	}

	&__loading {
		min-height: 50vh;
		background-color: white;
		border-radius: 16px;
		overflow: hidden;
		padding: 8px 0;
	}
}

@media only screen and (max-width: 500px) {
	.hashtag-page {
		&__header {
			padding-left: 20px;
			margin-bottom: 15px;
		}
	}
}
