@import 'src/scss/abstracts/_variables.scss';

.reading-summary-page-tab {
	&__chart-wrapper {
		margin-top: 2rem;
		position: relative;
	}

	.caretY {
		position: absolute;
		top: 35px;
		left: 70px;
		width: 20px;
	}
	.caretX {
		position: absolute;
		bottom: 46px;
		right: 18px;
		transform: rotate(90deg);
		width: 20px;
	}

	.recharts-wrapper {
		.recharts-default-legend {
			text-align: right !important;
			.recharts-surface {
				display: none !important;
			}
		}

		.recharts-layer .recharts-cartesian-axis-tick {
			line {
				display: none;
			}
			g {
				text {
					font-size: 11.28px;
				}
			}
			text {
				tspan {
					font-size: 11.28px;
				}
			}
		}

		.recharts-legend-item-text {
			color: $ash !important;
			font-weight: 600;
		}

		.recharts-text.recharts-label {
			color: $ash !important;
			font-weight: 600;
		}

		.recharts-cartesian-axis-ticks {
			// color: #333333 !important;
			font-weight: 600;
		}
	}
}

// responsive điện thoại
@media only screen and (max-width: 500px) {
	.reading-summary-page-tab {
		&__chart-wrapper {
			margin-top: 0.5rem;
		}
	}
}
