.user__search__container {
	padding: 32px 28px;
	.myfriends {
		&__button {
			width: 100% !important;
		}

		&__layout__container {
			display: grid;
			grid-template-columns: repeat(4, 1fr);
			gap: 20px;
			.myfriends__layout {
				width: 100%;
				img {
					height: 183px;
				}
			}

			&__img:hover {
				cursor: pointer;
			}
		}
	}
}

@media only screen and (max-width: 820px) {
	.user__search__container {
		padding: 28px 24px;
		.myfriends__layout__container {
			.myfriends__layout {
				img {
					height: 164px;
				}
			}
		}
	}
}

@media only screen and (max-width: 768px) {
	.user__search__container {
		padding: 24px 20px;
		.myfriends__layout__container {
			grid-template-columns: repeat(3, 1fr);

			.myfriends__layout {
				img {
					height: 156px;
				}
			}
		}
	}
}

// responsive điện thoại
@media only screen and (max-width: 500px) {
	.user__search__container {
		padding: 0 20px;
	}
}
