@import 'scss/abstracts/global';
@import 'scss/vendor/global';
@import 'scss/base/global';
@import 'scss/components/global';
@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,500;0,600;0,700;0,800;1,600;1,700;1,800;1,900&display=swap');

.infinite-scroll-component {
	overflow: hidden !important;
}

.hashtag-class {
	color: $blue !important;
	text-decoration: none;
	&:hover {
		text-decoration: underline;
	}
}

/* Change the white to any color */
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
	-webkit-box-shadow: 0 0 0 30px #eff0f6 inset !important;
}

.public-DraftEditorPlaceholder-root {
	left: 1px;
}

// hide arrow input number
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
	-webkit-appearance: none;
	margin: 0;
}

input[type='number'] {
	-moz-appearance: textfield;
}
