@import 'src/scss/abstracts/_variables.scss';

.login {
	display: flex;
	width: 100%;
	height: 100%;
	&__container {
		max-width: 1440px;
		margin: auto;
		position: relative;
		height: 100vh;
		a {
			width: 100%;
			max-width: 360px;
		}
		.login__up-block {
			display: flex;
			flex-direction: column;
			justify-content: space-between;
			height: 100vh;
		}
	}
	&__container-modal {
		position: fixed;
		width: 100%;
		height: 100vh;
		z-index: 1;
		background-color: rgba(78, 75, 102, 0.4);
		top: 0;
		left: 0;
		display: flex;
		align-items: center;
		justify-content: center;
	}
	&__form {
		width: 100%;
		max-width: 360px;
		&__error {
			position: relative;
			&__popover-container {
				position: absolute;
				top: 115%;
				right: -130%;
				z-index: -100;
				opacity: 0;
				&.show {
					z-index: 100;
					opacity: 1;
					transition: opacity 0.3s ease-in;
				}
			}
			&__popover {
				padding: 8px;
				background-color: red;
				border-radius: 12px;
				color: #fcfcfc;
				z-index: 500;
				text-align: center;
				width: fit-content;
				white-space: nowrap;
				font-weight: 400;
				font-size: 11px;
				padding: 8px 36px;
				line-height: 22px;
			}
		}
		&__logo {
			width: 200px;
			height: auto;
			margin-bottom: 80px;
		}
		&__wrapper {
			display: flex;
			flex-direction: column;
			align-items: center;
			width: 480px;
			max-width: 480px;
			margin-left: auto;
			padding: 80px;
			border-top-right-radius: 24px;
			border-top-left-radius: 24px;
			background-color: #fff;
			text-align: right;
			height: 100vh;
		}
		&__field {
			margin-top: 20px;
			height: 56px;
			border-radius: 12px;
			position: relative;
			background-color: #eff0f6;
			display: flex;
			justify-content: space-between;
			align-items: center;
			padding: 0 12px;
			border: transparent 1px solid;
			&.error {
				border: $red 1px solid;
			}
			.error--text {
				display: none;
				&.show {
					display: block;
					margin-left: 8px;
				}
			}
		}
		&__link {
			display: flex;
			justify-content: center;
			align-items: center;
			height: 45px;
			margin-top: 12px;
			color: #0576f0;
			flex-grow: 1;
			font-size: 18px;
		}
		&__input {
			width: 100%;
			background-color: #eff0f6;
			border: none;
			line-height: 24px;
			outline: none;
			padding-left: 8px;
			&::placeholder {
				color: #a0a3bd;
				font-family: 'Montserrat';
				font-style: normal;
				font-weight: 500;
				font-size: 13px;
				line-height: 22px;
				letter-spacing: 0.25;
			}
		}
		&__btn {
			display: block;
			width: 100%;
			height: 56px;
			margin-top: 28px;
			background-color: #e0af7e;
			border: none;
			border-radius: 12px;
			line-height: 1.5;
			color: #fcfcfc;
			outline: none;
			font-size: 18px;
		}
	}
	&__body-text1 {
		font-family: 'Montserrat';
		font-style: normal;
		font-weight: 600;
		font-size: 20px;
		line-height: 32px;
		letter-spacing: 0.75px;
		display: flex;
		align-items: center;
		color: #2d2c42;
	}
	&__body-text2 {
		margin-top: 20px;
		font-family: 'Montserrat';
		font-style: normal;
		font-weight: 500;
		font-size: 15px;
		line-height: 24px;
		display: flex;
		align-items: center;
		letter-spacing: 0.75px;
		color: #4e4b66;
	}
	&__body-text3 {
		margin-top: 20px;
		font-style: normal;
		font-weight: 700;
		font-size: 15px;
		line-height: 24px;
		letter-spacing: 0.75px;
		color: #0576f0;
	}
	&__footer {
		img {
			width: 100%;
		}
	}
	&__login-box {
		display: flex;
		flex-direction: column;
		align-items: center;
		position: absolute;
		right: 10rem;
		top: 50px;
		background-color: #fcfcfc;
		padding: 54px 20px 76px 20px;
		box-shadow: 0px 0px 32px rgba(0, 0, 0, 0.18);
		border-radius: 12px;
		max-width: 400px;
		width: 85%;
		&__link {
			border-radius: 12px;
			height: 56px;
			font-family: 'Montserrat';
			font-style: normal;
			font-weight: 600;
			font-size: 13px;
			line-height: 22px;
			display: flex;
			align-items: center;
			padding-left: 20%;
			padding-right: 10%;
			text-align: center;
			letter-spacing: 0.25px;
			&.login-facebook {
				background: #4666d5;
				margin-top: 32px;
				span {
					color: #f7f7fc;
				}
			}
			&.login-gmail {
				margin-top: 20px;
				background: #eff0f7;
				margin-bottom: 28px;
				span {
					color: #4e4b66;
				}
			}
		}
		&__underline {
			height: 1px;
			width: 100%;
			background-color: #f1f1f5;
		}
	}
	&__body {
		margin-left: 200px;
		margin-top: 82px;
		margin-bottom: 82px;
		max-width: 45%;
	}
	&__login-box__title {
		font-family: 'Montserrat';
		font-style: normal;
		font-weight: 600;
		font-size: 20px;
		line-height: 32px;
		display: flex;
		justify-content: center;
		align-items: center;
		text-align: center;
		letter-spacing: 0.75px;
		color: #2d2c42;
	}
	&__form-title {
		display: flex;
		justify-content: center;
		align-items: center;
		font-family: Montserrat;
		font-style: normal;
		font-weight: 600;
		font-size: 18px;
		line-height: 32px;
		display: flex;
		align-items: center;
		text-align: center;
		letter-spacing: 0.75px;
		color: #2d2c42;
		margin-top: 20px;
	}
	&__fbIcon {
		margin-right: 16px;
	}
}
.error--textbox {
	display: flex;
	justify-content: flex-end;
	margin-right: 30px;
}
.error--textbox--logo {
	border-left: 10px transparent solid;
	border-bottom: 10px red solid;
	width: 0;
}
.error--textbox--error {
	border-right: 10px transparent solid;
	border-bottom: 10px red solid;
	width: 0;
}
.login__register {
	height: 56px;
	width: 100%;
	margin-top: 28px;
	display: flex;
	justify-content: center;
	align-items: center;
	background-color: #18bb0c;
	border-radius: 12px;
	color: #fcfcfc;
	font-size: 18px;
	&:hover {
		color: #fcfcfc;
	}
}
.GmailIcon {
	margin-right: 16px;
}
// responsive
@media screen and (max-width: 1366px) {
	.login {
		&__body {
			margin-left: 100px;
		}
	}
}
@media screen and (max-width: 1280px) {
	.login {
		&__body {
			margin-left: 20px;
			&-text1 {
				font-size: 17px;
			}
			&-text2 {
				font-size: 14px;
			}
		}
	}
}

@media screen and (max-width: 1024px) {
	.login {
		&__body {
			visibility: hidden;
		}
		&__login-box {
			left: 50%;
			top: 50%;
			transform: translate(-50%, -50%);
			padding: 40px 20px;
		}
	}
}
@media screen and (max-width: 820px) {
	.login {
		&__footer {
			img {
				object-fit: cover;
			}
		}
		&__form-title {
			margin-top: 10px;
		}
		.login-facebook {
			margin-top: 20px;
		}
	}
}
// responsive điện thoại
@media screen and (max-width: 500px) {
	.login {
		&__login-box {
			&__link {
				padding-left: 10%;
			}
		}
	}
}
@media screen and (max-width: 366px) {
	.login {
		&__login-box {
			padding: 10px 20px;
			&__title {
				font-size: small;
			}
			&__link {
				font-size: smaller;
				line-height: 15px;
				height: 40px;
				&.login-facebook {
					margin-top: 10px;
				}
			}
		}
		&__fbIcon {
			width: 18px;
			height: 18px;
		}
		&__form {
			&-title {
				font-size: small;
			}
			&__input {
				line-height: 15px;
			}
			&__field {
				height: 40px;
			}
			&__btn {
				height: 40px;
				font-size: small;
			}
			&__link {
				font-size: small;
			}
		}
		&__register {
			height: 40px;
		}
		&__register {
			font-size: small;
		}
	}
	.GmailIcon {
		width: 18px;
		height: 18px;
	}
}
