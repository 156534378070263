@import 'src/scss/abstracts/_variables.scss';

.header-search-small {
	display: none;
	align-items: center;
	position: relative;
	margin-left: 10px;

	.header-search-small__icon {
		background-color: #eff0f7;
	}
	img {
		width: 20px;
	}
}

@media only screen and (max-width: 820px) {
	.header-search-small {
		display: flex;
		flex-grow: 1;

		.header-search-small__icon {
			position: relative;
			width: 20px;
			height: 40px;
			border-top-left-radius: 20px;
			border-bottom-left-radius: 20px;
			background-color: #eff0f7;
			img {
				position: absolute;
				top: 10px;
				left: 10px;
			}
		}

		.header-search-small__input-wrapper {
			background-color: #eff0f7;
			height: 40px;
			display: flex;
			align-items: center;
			padding: 0;
			width: 0;
			transition: all 0.4s ease;
			input {
				width: 0;
				border: none;
				outline: none;
				background-color: #eff0f7;
			}
			&.show {
				padding: 12px 0 12px 20px;
				width: 100%;
				input {
					width: 100%;
				}
			}
			form {
				width: 100%;
			}
		}

		.header-search-small__block-end {
			width: 20px;
			height: 40px;
			background-color: #eff0f7;
			border-top-right-radius: 20px;
			border-bottom-right-radius: 20px;
		}
	}
}

@media only screen and (max-width: 768px) {
	.header-search-small {
		.search__all__main__title {
			margin-bottom: 16px;
		}
	}
}
