.quoteSearch__container {
	padding: 32px 28px;
	.quoteSearch__container__main {
		.quote-card {
			padding: 1.5rem;
			.quote-footer__left {
				flex-wrap: nowrap;
			}
			.quote-footer__right {
				.quote-action-bar {
					gap: 20px;
					.quote-action__item {
						gap: 10px;
					}
					.quote-action__name:nth-last-child(2) {
						color: #0576f0;
					}
				}
			}
		}
	}
}

@media only screen and (max-width: 820px) {
	.quoteSearch__container {
		padding: 28px 24px;
	}
}

@media only screen and (max-width: 768px) {
	.quoteSearch__container {
		padding: 24px 20px;
	}
}
