@import 'src/scss/abstracts/_variables.scss';

.main-profile {
	width: 100%;
	.tab-content {
		padding-bottom: 0;
		border-bottom-right-radius: 16px;
		border-bottom-left-radius: 16px;
		overflow: hidden;
		min-height: 200px;
		.active {
			border-radius: 0;
		}
	}

	.tab-pane {
		&.active {
			padding: 2.5rem;
		}
		&.post-tab-active {
			padding: 0;
		}
		.loading-indicator {
			margin-top: 30px;
		}
	}

	&__tabs {
		&.nav-tabs {
			justify-content: space-between;
			padding: 0px 40px;
			.nav-item {
				padding-top: 44px;
			}
			.nav-link {
				font-weight: 600;
				line-height: 1.5;
				padding: 0 12px 32px;
				font-size: 18px;
			}
		}
		&.none-books {
			justify-content: space-around;
		}
	}

	.author-book {
		&__info {
			justify-content: flex-end;

			.react-rating-container {
				> span {
					margin-right: 12px;
					.star-icon {
						width: 28px;
					}
				}
			}
		}

		&__title {
			margin-bottom: 12px;
			-webkit-line-clamp: 2;
		}

		&__authors {
			margin-bottom: 20px;
		}

		&__rating {
			margin-bottom: 5px;
		}
	}

	.favorite-quotes {
		margin-top: 3.75rem;
	}

	.none-data {
		width: fit-content;
		margin: auto;
	}

	.post-data__blank {
		padding: 3.5rem 0;
		background-color: #fff;
		text-align: center;
	}
	.quote-footer__badge {
		padding: 12px 18px;
		font-weight: 600;
		font-size: 18px;
	}
}

@media only screen and (max-width: 820px) {
	.main-profile {
		.author-book {
			.btn-status {
				transform: scale(0.85);
				position: relative;
				bottom: -4px;
				right: -14px;
			}
		}
	}
}

// responsive điện thoại
@media only screen and (max-width: 500px) {
	.main-profile {
		&__tabs {
			&.nav-tabs {
				padding: 0px 20px;
				.nav-item {
					padding-top: 20px;
				}
				.nav-link {
					font-size: small;
					padding: 0 12px 18px;
				}
			}
		}

		.tab-pane.active {
			padding: 1.5rem;
			&.no-padding {
				padding: 0;
			}
		}

		.author-book {
			.book-thumbnail {
				height: inherit;
			}
		}

		.quote-footer__badge {
			padding: 6px 9px;
			font-size: smaller;
		}
	}
}
