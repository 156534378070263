@import 'src/scss/abstracts/_global.scss';

.reading-target {
	&__header {
		display: flex;
		flex-wrap: wrap;
		gap: 10px;
		align-items: center;
		justify-content: space-between;

		.search-field {
			max-width: 456px;
		}

		h4 {
			margin: 0;
		}
	}

	&__process {
		display: flex;
		align-items: center;
		gap: 2.5rem;
		padding: 1.75rem 1.875rem 1.75rem 2.5rem;
		margin: 2.375rem auto 2rem;
		border-radius: 16px;
		background-color: white;
	}

	&__user {
		&.user-avatar.user-avatar-lg {
			width: 143px;
		}
	}

	&__custom {
		padding: 18px !important;
	}

	&__user {
		flex-shrink: 0;
	}

	&__content {
		flex-grow: 1;

		&_container {
			display: flex;
			align-items: flex-start;
			flex-direction: column;
			.reading-target__title {
				font-size: 15px;
				line-height: 24px;
				color: #6e7191;
				margin-bottom: 8px;
			}
			.read-challenge__start-challenge-btn {
				background: #b38c64;
				width: 118px;
				color: #fcfcfc;
				border: none;
				margin-top: 10px;
			}
		}

		&__top {
			display: flex;
			justify-content: space-between;
			flex-wrap: wrap;
			gap: 10px;
			align-items: center;
			margin-bottom: 1.5rem;
			font-weight: 600;

			button {
				font-weight: 600;
			}

			> span,
			> div {
				display: flex;
				white-space: nowrap;
			}
		}

		&__bottom {
			display: flex;
			justify-content: space-between;
			align-items: center;
			gap: 0.75rem;

			.linear-progress {
				flex-grow: 1;
				flex-shrink: 0;
				border-radius: 12px;
			}

			.linear-progress-bar {
				font-size: 18px;
				font-weight: 600;
			}
		}

		.btn-edit {
			margin-right: 26px;
			white-space: nowrap;
			color: $blue;
		}

		.btn-cancel {
			color: $blue;
			white-space: nowrap;
		}

		.btn.btn-share {
			padding: 8px 20px;
			border-radius: 12px;
			font-size: 1rem;
		}
	}

	&__table-wrapper {
		overflow-x: auto;
		width: 100%;

		&.border-left {
			border-left: 8px solid white;
		}

		&.border-right {
			border-right: 8px solid white;
		}
	}

	&__table {
		min-width: 800px;
		&__header {
			font-size: 15px;
			display: grid;
			grid-template-columns: 55px 1fr 1fr 1fr 1fr 1fr;
			grid-gap: 1.25rem;
			padding: 16px 48px;
			background-color: white;
			border-radius: 16px;

			&-column {
				line-height: 22px;
				font-weight: 600;
				text-align: center;
			}
		}

		&__body {
			background-color: #fff;
			border-radius: 16px;
			margin-top: 25px;
			display: flex;
			flex-direction: column;
			min-height: 60px;
			padding: 20px 28px 0px 28px;

			.book-row {
				display: grid;
				grid-template-columns: 55px 1fr 1fr 1fr 1fr 1fr;
				grid-gap: 1.25rem;
				padding: 14px 20px;
				background-color: #fbf7f2;
				border-radius: 16px;
				margin-bottom: 20px;

				&__container {
					display: flex;
					align-items: center;
					justify-content: center;
					.author-name,
					.book-name {
						max-width: 138px;
						display: -webkit-box;
						-webkit-line-clamp: 4;
						-webkit-box-orient: vertical;
						overflow: hidden;
						text-overflow: ellipsis;
						font-weight: 600;
						font-size: 15px;
						line-height: 1.5;
						word-break: break-word;
					}
				}
			}

			.highlight {
				display: flex;
				align-items: center;
				justify-content: center;
				line-height: 60px;
			}
		}
	}

	.book-thumbnail.book-thumbnail-sm {
		width: 55px;
		aspect-ratio: 2/3;
	}

	.goals-not-set-yet-image-wrapper {
		margin-top: 60px;
		object-fit: cover;

		img {
			width: 100%;
		}
	}
}

@media screen and (max-width: 820px) {
	.reading-target {
		&__header {
			.search-field {
				height: 50px;
			}
		}

		&__user {
			&.user-avatar.user-avatar-lg {
				width: 100px;
			}
		}

		&__table {
			&__header {
				&-column {
					font-size: 0.9rem;
				}
			}

			&__body {
				.book-row {
					gap: 10px;
					padding: 16px 15px;

					&__container {
						font-size: 0.84rem;
					}
				}
			}
		}
	}
}

@media screen and (max-width: 768px) {
	.reading-target {
		&__header {
			.search-field {
				max-width: 400px;
				height: 50px;
			}
		}
		&__process {
			gap: 1.2rem;

			.linear-progress {
				height: 2.3rem !important;
			}
			.btn.btn-share {
				padding: 4px 20px;
			}
		}

		&__user {
			&.user-avatar.user-avatar-lg {
				width: 90px;
			}
		}
	}
}

// responsive điện thoại
@media only screen and (max-width: 500px) {
	.reading-target {
		&__header {
			.search-field {
				height: 40px;
			}
		}

		&__process {
			flex-direction: column;
		}

		&__user {
			&.user-avatar.user-avatar-lg {
				width: 75px;
			}
		}

		&__content {
			&__top {
				flex-wrap: wrap;
			}

			&_container {
				.reading-target__title {
					text-align: center;
				}
				.read-challenge {
					&__input {
						margin: 0 auto 16px;
					}

					&__start-challenge-btn {
						margin: 10px auto 0;
					}
				}
			}
		}

		&__table {
			&__header {
				padding: 16px 48px 16px 16px;
			}
			&__body {
				padding: 20px 28px 0 10px;
			}
		}
	}
}
