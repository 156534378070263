@import 'src/scss/abstracts/_global.scss';

.mainContainer {
	padding: 0 74px 0 92px;
	display: flex;
	justify-content: space-between;
	gap: 25px;

	.mainContainer__main {
		padding: 40px 0;
		max-width: 936px;
		width: 72%;
	}

	.mainContainer__sidebar-wrapper {
		max-width: 294px;
		flex-grow: 1;
		.mainContainer__right {
			width: 100%;
			max-width: inherit;
			position: fixed;
			height: fit-content;
			max-height: calc(100vh - 96px);
			overflow-x: hidden;
			overflow-y: scroll;
			overscroll-behavior: contain;
			padding: 40px 22px 40px 0;

			&::-webkit-scrollbar {
				width: 4px;
			}
			/* track */
			&::-webkit-scrollbar-track {
				background: transparent;
				border-radius: 8px;
			}
			/* thumb */
			&::-webkit-scrollbar-thumb {
				background: transparent;
				border-radius: 8px;
			}

			&.show-scrollbar {
				&::-webkit-scrollbar-thumb {
					background: rgba(#606060, 0.2);
				}
			}
		}
	}
}

@media only screen and (max-width: 1366px) {
	.mainContainer {
		padding: 0 38px 0 56px;
	}
}

@media only screen and (max-width: 1024px) {
	.mainContainer {
		padding: 0 56px;
		.mainContainer__main {
			width: 100%;
			max-width: none;
			.main-shelves {
				.main-shelves__header {
					h4 {
						font-size: 1.2rem;
					}
					.main-shelves__search {
						max-width: 250px;
					}
				}
				.main-shelves__pane {
					.main-shelves__filters {
						.select-box {
							width: 200px;
						}
					}
				}
			}
		}
		.mainContainer__sidebar-wrapper {
			display: none;
		}
	}
}

@media only screen and (max-width: 820px) {
	.mainContainer {
		padding: 0 28px;
	}

	.mainContainer__right {
		display: none;
	}
}

@media only screen and (max-width: 700px) {
	.mainContainer {
		.mainContainer__main {
			margin: 0;
			.main-shelves {
				.main-shelves__header {
					h4 {
						font-size: 1.3rem;
					}
					.main-shelves__search {
						max-width: 230px;
					}
				}
				.main-shelves__pane {
					.main-shelves__filters {
						.select-box {
							width: 180px;
						}
					}
				}
			}
		}
		.main-shelves {
			.main-shelves__pane {
				margin-right: 0px;
			}
			.main-shelves__search {
				margin: 0;
			}
		}
	}
}

// responsive điện thoại
@media only screen and (max-width: 500px) {
	.mainContainer {
		padding: 0 10px;
	}
}
