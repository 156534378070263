@import 'src/scss/abstracts/_variables.scss';

.main-category {
	&__container {
		background: $white;
		border-radius: 16px;
		padding: 1.5rem 2.5rem;
	}

	.blank-content {
		margin-top: 1.5rem;
		font-size: 18px;
	}

	.book-thumbnail {
		border: 1px solid $ash-line;
		img {
			transition: transform 0.2s ease-out;
			&:hover {
				transform: scale(1.1);
			}
		}
	}

	.search-category {
		padding-top: 28px;
		h5 {
			margin: 0;
		}
	}

	.infinite-scroll-component {
		.loading-indicator {
			width: 80px;
			height: 80px;
			margin-top: 32px;
		}

		.loading-indicator:after {
			width: 50px;
			height: 50px;
			border-width: 5px;
		}
	}
}

@media only screen and (min-width: 900px) and (max-width: 1024px) {
	.main-category {
		& h4 {
			font-size: 17px;
		}
	}
}
