.modal-login {
	&__title {
		font-weight: 700;
		font-size: 36px;
		line-height: 48px;
		color: #14142b;
		text-align: center;
		margin: 40px 0 25px 0;
	}
	&__button {
		text-align: center;
		margin-bottom: 80px;
		.btn {
			max-width: 300px;
			width: 100%;
		}
	}
	&__close {
		display: flex;
		justify-content: flex-end;
		cursor: pointer;
	}
	&__description {
		font-weight: 500;
		font-size: 18px;
		line-height: 32px;
		text-align: center;
		margin-bottom: 24px;
	}
	&__icon {
		display: flex;
		justify-content: center;
		margin-bottom: 24px;
	}
}

.modal-check-login-container {
	.modal-content {
		border-radius: 24px;
	}
}

// responsive điện thoại
@media screen and (max-width: 500px) {
	.modal-login {
		&__title {
			margin: 20px 0 18px 0;
			font-size: 22px;
		}

		&__description {
			font-size: medium;
		}

		&__button {
			margin-bottom: 40px;
		}
	}
}
