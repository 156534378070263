@import 'src/scss/abstracts/_variables.scss';

.upload-book__header {
	display: flex;
	align-items: center;
	gap: 12px;
	margin-bottom: 30px;

	span {
		font-weight: 700;
		font-size: 24px;
		line-height: 32px;
		letter-spacing: 1px;
	}
}

.upload-book-form {
	padding: 38px 30px;
	background: #ffffff;
	border-radius: 16px;
	display: flex;
	gap: 37px;

	.upload-image {
		width: 100%;
		height: 100%;
		display: flex;
		justify-content: center;
		align-items: center;
		padding: 0 8px;
		border-radius: 6px;
		font-size: 14px;
		&__wrapper {
			display: flex;
			justify-content: center;
			width: 200px;
			min-width: 200px;
			height: 271px;
			border-radius: 6px;
			padding: 0px;
			overflow: hidden;
			cursor: pointer;
			margin: 0 auto;
			&__icon {
				height: 52px;
				width: 52px;
				border-radius: 50%;
				background-color: #eff0f7;
				display: flex;
				align-items: center;
				justify-content: center;
				svg {
					transform: scale(1.2);
				}
			}
		}
	}

	.upload-info-form {
		width: 100%;
		.upload-text-danger {
			color: red;
			margin-left: 4px;
		}
		.inp-book {
			width: 100%;
			margin-bottom: 20px;
			label {
				margin-bottom: 15px;
				font-weight: 600;
				font-size: 15px;
				line-height: 21px;
				letter-spacing: 0.648188px;
			}

			.input {
				height: 50px;
				border-radius: 12px;
				font-weight: 500;
				line-height: 24px;
				padding: 16px 22.78px;
			}

			.inp-date {
				width: 100%;
				border-radius: 12px;
				height: 57px;
				padding: 0px 18px;
				background-color: #f7f7fc;
				border: none;
				display: flex;
				align-items: center;

				.react-datepicker {
					font-family: inherit;
					&__input-container {
						input {
							width: 100%;
							border-radius: 12px;
							font-weight: 500;
							font-size: 15px;
							line-height: 24px;
							padding: 16px 15px;
							background-color: transparent;
							outline: none;
							border: none;

							&::placeholder {
								font-weight: 500;
								font-size: 15px;
								line-height: 24px;
							}
						}
					}
				}

				.icon-calendar {
					cursor: pointer;
				}
			}

			.txtarea {
				width: 100%;
				padding: 0.75rem 1rem;
				background-color: inherit;
				outline: none;
				border: none;
				border-radius: 12px;
				background-color: #f7f7fc;
				font-weight: 500;
				font-size: 15px;
				line-height: 24px;
				word-break: break-word;

				&::-webkit-scrollbar {
					display: none;
				}
				.public-DraftEditor-content {
					> div {
						min-height: 200px;
					}
				}
			}

			.add-and-search-categories__categories-added {
				width: 100%;
				display: flex;
				gap: 4px;
				flex-wrap: wrap;
				align-items: center;
			}

			&.inp-series {
				.btn-cancel-series {
					position: absolute;
					bottom: 11px;
					right: 5px;
					svg {
						transform: scale(0.7);
					}
				}
			}
		}
	}

	.upload-image__description {
		text-align: center;
		font-weight: 600;
	}

	.select-box {
		line-height: 1;
		&__btn {
			justify-content: space-between;
		}
		&__list {
			width: 100%;
		}
	}

	.btnMainUpload {
		width: 100%;
	}

	.btn-upload {
		cursor: not-allowed;
		background-color: #eff0f7;
		margin-top: 0px;
		&.active {
			cursor: pointer;
			background-color: #e0af7e;
		}
	}

	.react-datepicker-popper {
		z-index: 2;
	}
}

.react-datepicker-popper[data-placement^='top'] .react-datepicker__triangle {
	display: none;
}
.react-datepicker-popper[data-placement^='bottom'] .react-datepicker__triangle {
	display: none;
}

@media only screen and (max-width: 820px) {
	.upload-book-form {
		flex-direction: column;
	}
}

// responsive điện thoại
@media only screen and (max-width: 500px) {
	.upload-book__header {
		margin-bottom: 15px;

		span {
			font-size: large;
		}
	}

	.upload-book-form {
		padding: 20px 10px;
		.upload-info-form {
			.inp-book {
				margin-bottom: 12px;

				label {
					margin-bottom: 8px;
					font-size: small;
				}

				.input {
					height: 40px;
				}

				.inp-date {
					height: 40px;
				}
			}
		}
	}
}
