.see-more-comment {
	display: flex;
	justify-content: flex-end;
	margin-bottom: 20px;
	margin-top: -10px;
	align-items: center;

	&__button:hover {
		cursor: pointer;
	}

	&__button {
		font-size: 15px;
		text-decoration: underline;
		&.disabled {
			text-decoration: none;
			cursor: not-allowed;
		}
	}
}

.loading-more-comments {
	.loading-indicator {
		transform: scale(0.5);
		margin-top: -24px;
	}
}
