@import 'src/scss/abstracts/_variables.scss';

.pagination-group {
	gap: 8px;
	justify-content: center;

	.page-link {
		width: 44px;
		height: 44px;
		display: flex;
		justify-content: center;
		align-items: center;
		cursor: pointer;
		border-radius: 10px;
		background-color: $secondary;
		border: none;
		font-size: 0.875rem;
		font-weight: 600;
		color: $ash;
		&:hover {
			background-color: #007eff;
			color: $white;
		}
	}

	.page-item.active .page-link {
		background-color: #007eff;
		color: $white;
	}

	.page-item {
		&:last-child {
			.page-link {
				border-top-right-radius: 10px;
				border-bottom-right-radius: 10px;
			}
		}

		&:first-child {
			.page-link {
				border-top-left-radius: 10px;
				border-bottom-left-radius: 10px;
			}
		}
	}
}
