@import 'src/scss/abstracts/_global.scss';

.btn-status {
	font-size: 14.61px;
	width: 180px;
	height: 56px;
	display: flex;
	justify-content: center;
	align-items: center;
	> span {
		display: flex;
		align-items: center;
		font-size: 17px;
	}

	&__icon {
		width: 1.25rem;
		height: 1.25rem;
		display: inline-block;
		margin-right: 0.8em;
	}

	&.disable {
		cursor: default;
	}
}

.status-book-modal {
	&.modal {
		overflow-y: scroll;
	}
	.modal-dialog {
		max-width: 400px;
	}
	.modal-body {
		padding: 2.5rem 2rem;
	}

	.modal-content {
		border: none;
	}

	form {
		margin: 0.5em 0;
	}

	&__input {
		width: 100%;
		height: 56px;
		padding-left: 1.5em;
		border-radius: 12px;
		background-color: $ash-background;
		font-size: 1rem;
		&:focus {
			outline: none;
		}
	}
	&__addBtn {
		width: 100%;
		height: 56px;
		margin: 0.5em 0;
		border-radius: 12px;
		display: flex;
		justify-content: center;
		align-items: center;
		border: 1px solid $ash-line;
		color: $ash-body;
		font-weight: 600;
		span:last-child {
			font-size: 18px;
		}
		.add-icon {
			margin-right: 19px;
			color: #000;
			path {
				stroke-width: 2.5;
			}
		}
	}

	&__confirm {
		width: 100%;
		font-size: 18px;
	}
}

.error-message {
	display: inline-block;
	margin-top: 0.5rem;
	margin-right: 0.25rem;
	color: $red;
	font-size: 12px;
}

// responsive điện thoại
@media only screen and (max-width: 500px) {
	.status-book-modal.modal.show {
		.modal-dialog {
			margin: auto;
			transform: scale(0.8);
		}
	}
}
