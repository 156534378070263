.choose-topic {
	&__container {
		background-color: #fcfcfc;
		padding-bottom: 82px;
		.was-validated .form-check-input:valid:checked[type='checkbox'],
		.form-check-input.is-valid:checked[type='checkbox'] {
			background-color: #fcfcfc;
			border: 1px solid #a0a3bd;
		}
		.form-check-input:checked[type='checkbox'] {
			background-image: url('../../assets/icons/check-green.svg');
			background-size: 10px;
			border: none;
		}
		.form-check-input:checked + .form-check-label--custom {
			font-weight: 600;
			font-size: 18px;
		}
		.form-check-wrapper {
			cursor: pointer;
		}
	}
	&__header {
		background-color: #fbf7f2;
		height: 120px;
		display: flex;
		justify-content: center;
		align-items: center;
	}
	&__body {
		background-color: #fcfcfc;
		box-shadow: 0px 0px 32px rgba(0, 0, 0, 0.04);
		border-radius: 12px;
		width: 72%;
		margin: 79px auto 82px auto;
		padding: 54px 92px 40px 92px;
	}
	&__box {
		display: grid;
		grid-template-columns: repeat(3, 1fr);
		margin-top: 40px;
	}
	&__button {
		width: 368px;
		height: 56px;
		background-color: #e0af7e;
		border-radius: 12px;
		margin: auto;
		margin-top: 58px;
		button {
			display: flex;
			justify-content: center;
			align-items: center;
			width: 100%;
			height: 100%;
			color: #fcfcfc;
		}
	}
	&__title {
		font-family: 'Montserrat';
		font-style: normal;
		font-weight: 600;
		font-size: 20px;
		line-height: 32px;
		text-align: center;
		letter-spacing: 0.75px;
		color: #2d2c42;
	}
	&__subcribe {
		font-family: 'Montserrat';
		font-style: normal;
		font-weight: 600;
		font-size: 13px;
		line-height: 22px;
		text-align: center;
		letter-spacing: 0.25px;
		color: #4e4b66;
		margin-top: 8px;
	}
	&__search {
		margin-top: 16px;
	}
}

.disabled-btn {
	pointer-events: none;
	opacity: 0.5;
}

.form-check-label--custom {
	font-weight: 500;
	font-size: 15px;
}

.form-check-input:checked + .form-check-label--custom {
	font-weight: 600;
	font-size: 13px;
}

.form-check-input[type='checkbox'] {
	background-color: #d9dbe9;
}
.form-check-input:checked[type='checkbox'] {
	background-image: url('../../assets/icons/check-green.svg');
	background-size: 10px;
	background-color: #fcfcfc;
	border: #d9dbe9 1px solid;
}

@media screen and (max-width: 1280px) {
	.choose-topic__body {
		width: 86%;
	}
}

@media screen and (max-width: 1024px) {
	.choose-topic__container {
		.choose-topic__box {
			grid-template-columns: repeat(2, 1fr);

			.form-check-wrapper {
				margin-top: 0;
			}
		}
	}
}

@media screen and (max-width: 768px) {
	.choose-topic__body {
		width: 90%;
	}
}

// responsive điện thoại
@media only screen and (max-width: 500px) {
	.choose-topic {
		&__container {
			.form-check-input:checked + .form-check-label--custom {
				font-size: inherit;
			}
		}

		&__header {
			height: 90px;
		}

		&__title {
			font-size: medium;
			line-height: 1.5;
		}

		&__body {
			margin: 0;
			width: auto;
			padding: 20px;
		}

		&__button {
			width: auto;
			margin-top: 15px;
			height: 40px;
		}
	}

	.form-check-label--custom {
		font-size: inherit;
	}
}
