.groups-container {
	.mainContainer {
		padding: 0 92px;
		gap: 1.5rem;
	}

	.search-group-container {
		padding: 40px 92px 0;
	}

	.list-group__container {
		.sidebar__view-more-btn--blue {
			margin: 15px 0px 10px 0px;
		}
	}

	.search {
		display: flex;
		.search-field {
			background-color: #eff0f6;
			margin-right: 16px;
		}
		button {
			height: 57px;
			width: 150px;
			background: #e0af7e;
			border-radius: 9px;
			font-family: 'Montserrat';
			font-style: normal;
			font-weight: 600;
			font-size: 13px;
			line-height: 18px;
			letter-spacing: 0.5625px;
			color: #fcfcfc;
		}
	}

	.mainContainer__right-left {
		max-width: 291px;
		width: 100%;
		margin-top: 40px;
	}

	.mainContainer__main-left {
		flex-grow: 1;
		margin-top: 40px;
	}

	.list-group__container {
		background: #fff;
		padding: 10px 16px;
		border-radius: 15px;
		margin-bottom: 15px;
		h3 {
			font-family: 'Montserrat';
			font-style: normal;
			font-weight: 600;
			font-size: 18px;
			line-height: 29px;
			display: flex;
			align-items: center;
			color: #2d2c42;
		}

		.item-update {
			display: flex;
			gap: 6px;
			span {
				font-family: 'Montserrat';
				font-style: normal;
				font-weight: 500;
				font-size: 12.6px;
				display: flex;
				align-items: center;
				color: #6e7191;
			}
		}

		.item-infor__name {
			height: fit-content !important;
		}
	}

	.item-list-group {
		display: flex;
		align-items: center;
		margin-top: 14px;
		img {
			width: 60px;
			height: 60px;
			border-radius: 10px;
			margin-right: 8px;
			object-fit: cover;
		}
	}

	.item-infor {
		display: flex;
		flex-direction: column;
		justify-content: center;
		gap: 8px;
		.item-infor__name {
			font-family: 'Montserrat';
			font-style: normal;
			font-weight: 600;
			font-size: 16px;
			line-height: 22px;
			overflow: hidden;
			display: -webkit-box;
			-webkit-box-orient: vertical;
			-webkit-line-clamp: 2;
			word-break: break-word;
			color: #2d2c42;
		}
	}

	.list-group-container {
		display: grid;
		grid-template-columns: repeat(3, 1fr);
		gap: 20px;
	}

	.list-group-container--none {
		display: grid;
		grid-template-columns: repeat(4, 1fr);
		gap: 20px;
	}

	.result-search {
		.list-group-container {
			grid-template-columns: repeat(4, 1fr);
		}
	}

	.item-group {
		border-radius: 12px;
		background-color: #fff;
		overflow: hidden;
		overflow: hidden;

		img {
			width: 100%;
			height: 163px;
			object-fit: cover;
		}

		.item-group__name {
			font-family: 'Montserrat';
			font-style: normal;
			font-weight: 600;
			font-size: 17px;
			line-height: 22px;
			overflow: hidden;
			display: -webkit-box;
			-webkit-line-clamp: 2;
			-webkit-box-orient: vertical;
			word-break: break-word;
			color: #090909;
			min-height: 44px;
		}

		.item-group__text {
			padding: 16px 12px;
		}

		.item-group__description {
			margin-top: 5.6px;
			span {
				font-family: 'Montserrat';
				font-style: normal;
				font-weight: 500;
				font-size: 14px;
				line-height: 22px;
				display: flex;
				align-items: center;
				color: #606266;
			}
		}

		.item-group__count-post {
			span {
				font-family: 'Montserrat';
				font-style: normal;
				font-weight: 500;
				font-size: 14px;
				line-height: 22px;
				display: flex;
				align-items: center;
				color: #606266;
			}
		}

		.item-group-btn {
			background: #e0af7e;
			border-radius: 12px;
			display: flex;
			align-items: center;
			justify-content: center;
			padding: 9px 22px;
			margin-top: 15.3px;
			font-family: 'Montserrat';
			font-style: normal;
			font-weight: 600;
			font-size: 13px;
			line-height: 22px;
			letter-spacing: 0.25px;
			color: #fcfcfc;
			cursor: pointer;
			text-align: center;
		}
	}
}

.create-group-modal {
	&::-webkit-scrollbar {
		width: 8px;
	}

	&::-webkit-scrollbar-track {
		background: #f1f1f1;
		border-radius: 8px;
	}

	&::-webkit-scrollbar-thumb {
		background: #606060;
		border-radius: 8px;
		&:hover {
			background: rgba(#606060, 0.85);
		}
	}

	.modal-dialog {
		max-width: 650px;
	}
	.modal-content {
		border-radius: 16px;
		border: none;
	}

	.modal-header {
		padding: 2rem 1.25rem 1.25rem;
		border-bottom: 1px solid #f1f1f5;
	}

	.modal-title {
		text-align: center;
		flex-grow: 1;
	}

	.modal-body {
		padding: 0px;
	}

	.close-btn {
		background-color: #eff0f6;
		padding: 8px;
		border-radius: 50%;

		svg {
			width: 18px;
			height: 18px;
		}
	}

	.form-field-wrapper {
		padding: 40px 24px 24px;
	}

	.upload-image__wrapper {
		padding: 0;
		border: none;
		border-radius: 0;
	}
}

@media only screen and (max-width: 1366px) {
	.groups-container {
		.mainContainer {
			padding: 0 40px;
		}
		.search-group-container {
			padding: 40px 40px 0;
		}
	}
}

@media only screen and (max-width: 1280px) {
	.groups-container {
		.search-group-container {
			padding: 40px 40px 0;
		}

		.mainContainer__main-left {
			width: 31%;
			.item-group {
				.item-group__name {
					margin-top: 0;
					span {
						height: 0px;
					}
				}
			}

			.list-group-container {
				h3 {
					font-size: 17px;
				}
			}
		}

		.item-list-group {
			img {
				width: 55px !important;
				height: 55px !important;
			}
		}
	}
}

@media only screen and (max-width: 1024px) {
	.groups-container {
		.mainContainer {
			padding: 0 16px;
			gap: 1.25rem;
		}

		.search-group-container {
			padding: 40px 16px 0;
		}

		.mainContainer__right-left {
			max-width: 250px;

			.list-group__container {
				padding: 10px;
				h3 {
					font-size: 14px;
				}

				.item-infor__name {
					font-size: 15px;
				}

				.item-update {
					span {
						font-size: 11px;
					}
				}
			}

			.main__title {
				font-size: 15px !important;
			}
		}

		.mainContainer__main-left {
			.list-group-container {
				gap: 16px;
				.item-group {
					img {
						height: 130px;
					}
					.item-group__text {
						.item-group__name {
							span {
								font-size: 15px;
							}
						}
					}
				}
			}
		}
	}
}

@media only screen and (max-width: 820px) {
	.groups-container {
		.mainContainer {
			padding: 0 28px;
		}

		.subcontainer__sub {
			.search-group-container {
				padding: 40px 28px 0;
			}
		}

		.mainContainer__right-left {
			display: none;
		}

		.mainContainer__main-left {
			.list-group-container {
				column-gap: 20px;
			}
		}

		.result-search {
			.list-group-container {
				grid-template-columns: repeat(3, 1fr);
			}
		}
	}
}

.group-btn-back {
	display: flex;
	align-items: center;
	margin-bottom: 32px;
	gap: 20px;
	h4 {
		margin-bottom: 0;
	}
}

@media only screen and (max-width: 820px) {
	.groups-container {
		.result-search {
			.list-group-container {
				grid-template-columns: repeat(3, 1fr);
			}
		}
	}
}

@media only screen and (max-width: 768px) {
	.groups-container {
		.mainContainer__main-left {
			.item-group {
				.item-group-btn {
					font-size: 11px;
				}
			}
		}
	}
}

// responsive điện thoại
@media only screen and (max-width: 500px) {
	.groups-container {
		.mainContainer {
			padding: 0 16px;
		}

		.subcontainer__sub {
			.search-group-container {
				padding: 25px 16px 0;
			}
		}

		.list-group-container {
			grid-template-columns: repeat(2, 1fr);
		}

		.result-search {
			.list-group-container {
				grid-template-columns: repeat(2, 1fr);
			}
		}

		.list-group-container--none {
			grid-template-columns: repeat(2, 1fr);
			gap: 20px;
		}

		.search {
			button {
				height: 40px;
				font-size: 11px;
			}
		}
	}

	.popup-group__header {
		+ .upload-image__wrapper {
			.dropzone.upload-image {
				font-size: small;
			}
		}
	}
}

@media only screen and (max-width: 360px) {
	.groups-container {
		.mainContainer {
			padding: 0 10px;
		}

		.subcontainer__sub {
			.search-group-container {
				padding: 25px 10px 0;
			}
		}

		.list-group-container {
			grid-template-columns: repeat(1, 1fr);
		}

		.result-search {
			.list-group-container {
				grid-template-columns: repeat(1, 1fr);
			}
		}

		.list-group-container--none {
			display: grid;
			grid-template-columns: repeat(1, 1fr);
			gap: 20px;
		}
	}
}
