.popup-group__header {
	display: flex;
	align-items: center;
	justify-content: space-between;
	padding: 24px;
	button {
		width: 48px;
		height: 48px;
		border-radius: 50%;
		background-color: #eff0f7;
	}
	h3 {
		flex: 1;
		text-align: center;
		height: 48px !important;
		margin-bottom: 0 !important;
		display: flex;
		justify-content: center;
		align-items: center;
		font-size: 20px;
	}
}

.book-list {
	flex-wrap: wrap;
	display: flex;
	cursor: pointer;
	align-items: center;
	margin-bottom: 10px;
	margin-top: 10px;
	gap: 10px;
	.close__author {
		position: absolute;
		right: 4px;
		align-items: center;
		margin-left: 5px;
		padding-top: 4px;
		svg {
			width: 16px;
			height: 16px;
		}
	}
	span {
		color: #b38c64;
		background-color: #f5e7d8;
		padding: 0rem 1.25rem;
		border-radius: 10px;
		font-size: 0.75rem;
		width: 150px;
		overflow: hidden;
		text-overflow: ellipsis;
		overflow: hidden;
		text-overflow: ellipsis;
		display: -webkit-box;
		-webkit-box-orient: vertical;
		-webkit-line-clamp: 1;
		line-height: 32px;
		position: relative;
		height: 40px;
		padding-top: 5px;
		button {
			position: absolute;
			top: 0;
			right: 0;
		}
	}
}

.author__item {
	cursor: pointer;
	margin-top: 10px;
	justify-content: space-between;
	align-items: center;
	width: -moz-fit-content;
	width: fit-content;
	margin-right: 10px;
	padding: 0.75rem 1.25rem;
	border-radius: 10px;
	font-size: 0.75rem;
	background-color: #f5e7d8;
	color: #b38c64;
}

.author__list {
	display: flex;
	flex-wrap: wrap;
}

.disable-btn {
	margin-top: 57px;
	display: flex;
	justify-content: center;
	align-items: center;
	background-color: #eff0f7;
	padding: 16px;
	border-radius: 12px;
	pointer-events: none;
	button {
		font-size: 18px;
	}
}

.create-group-modal {
	.form-field-wrapper {
		padding: 25px;
	}
	.add-and-search-categories__categories-added__item {
		max-width: 95%;
	}
	.input-form-group__label {
		margin-bottom: 23px;
		font-size: 16px;
	}
}

.form-field-name {
	label {
		margin-bottom: 23px;
		font-size: 16px;
		font-weight: 600;
	}
	input {
		font-size: 15px;
	}
}

.list__author-tags {
	&:hover {
		cursor: text;
	}
	display: flex;
	flex-wrap: wrap;
	background-color: #f7f7fc;
	border-radius: 12px;
	padding: 0 10px;
	margin-bottom: 24px;
	.input {
		width: fit-content;
	}
}

.input__authors {
	flex-wrap: wrap;
	display: flex;
	align-items: center;
	margin-bottom: 10px;
	margin-top: 10px;
	gap: 10px;
	max-width: 100%;
	> span {
		width: max-content;
		color: #b38c64;
		background-color: #f5e7d8;
		padding: 0.75rem 1.25rem;
		border-radius: 10px;
		font-size: 0.75rem;
		cursor: pointer;
		display: flex;
		overflow: hidden;
		align-items: center;
		span {
			overflow: hidden;
			text-overflow: ellipsis;
			max-width: 95%;
		}
		button {
			z-index: 2;
		}
	}
}

.close__author {
	align-items: center;
	margin-left: 5px;
	svg {
		width: 16px;
		height: 16px;
	}
}

.form-field-select__transparent,
.form-field-hashtag,
.form-field-authors,
.form-field-description,
.form-field-select__kind-of-group {
	label {
		margin-top: 39px;
		margin-bottom: 23px;
		font-weight: 600;
	}
}

.form-field-authors {
	&__asterisk {
		color: red;
		margin-left: 4px;
	}
}

.form-field-select__kind-of-group {
	.select-box {
		.select-box__btn {
			justify-content: space-between;
		}
		.select-box__list {
			max-width: 100% !important;
			width: 100% !important;
		}
		.select-box__item {
			width: 100%;
			margin: 0 !important;
		}
	}
}

.form-field--description {
	textarea {
		background-color: #eff0f7;
	}
}

.popup-create-group {
	height: 56px;
}

.form-button {
	margin-top: 57px;
	display: flex;
	justify-content: center;
	align-items: center;
	background-color: #e0af7e;
	padding: 16px;
	border-radius: 12px;
	cursor: pointer;
	button {
		color: #fcfcfc;
	}
	&.disabled-btn {
		background-color: #d9dbe9;
		button {
			color: #a0a3bd;
			font-weight: 600;
		}
	}
}

.upload-image {
	&__wrapper {
		img {
			cursor: pointer;
			width: 100%;
			object-fit: cover;
			max-height: 265px;
		}
		&__icon {
			height: 60px;
			width: 60px;
			border-radius: 50%;
			background-color: #eff0f7;
			display: flex;
			align-items: center;
			justify-content: center;
			svg {
				transform: scale(1.2);
			}
		}
	}
	&__description {
		margin-bottom: 5px;
	}
}

@media only screen and (max-width: 1024px) {
	.form-field-select__kind-of-group {
		label {
			margin-top: 30px;
		}
	}
}

// responsive điện thoại
@media only screen and (max-width: 500px) {
	.create-group-modal {
		.form-field-wrapper {
			padding: 25px 10px;
		}

		.input-form-group__label {
			margin-bottom: 10px;
			font-size: inherit;
		}
	}

	.popup-group__header {
		padding: 10px;

		button {
			width: 34px;
			height: 34px;

			svg {
				width: 18px;
				height: 18px;
			}
		}
	}

	.form-field-name {
		label {
			margin-bottom: 10px;
			font-size: inherit;
		}
	}

	.form-field-select__transparent,
	.form-field-hashtag,
	.form-field-authors,
	.form-field-description,
	.form-field-select__kind-of-group {
		label {
			margin-top: 30px;
			margin-bottom: 10px;
			font-weight: 600;
		}
	}
}
