@import 'src/scss/abstracts/_global.scss';

.post {
	&__container {
		padding: 20px 16px;
		background-color: white;
		border-radius: 16px;
		margin-bottom: 24px;

		.create-post-modal-content__main__share-container {
			margin-bottom: 20px;
			.author-book {
				max-height: 177px;
				margin-bottom: 0;
				padding: 12px 16px;
				.book-thumbnail {
					width: 98px;
				}

				.author-book__authors {
					margin-top: 0;
				}

				&__title {
					font-size: 1rem;
					line-height: 32px;
					max-width: 460px;
				}

				&__rating {
					.star-icon {
						width: 19.42px;
						margin-right: 9px;
					}
					&__number {
						font-size: 18px;
					}
				}

				&__stats {
					font-size: 14.61px;
				}

				.btn {
					height: 48px;
					padding: 12px;
				}
			}

			.quote-card {
				padding: 20px 16px;
				margin-bottom: 0;
				.quote-card__quote-content p:first-child {
					max-width: 90%;
				}
			}

			.post-book {
				margin-bottom: 0;
			}
		}

		.tagged {
			display: flex;
			flex-wrap: wrap;
			gap: 0.5rem;
			margin-bottom: 0.5rem;

			.badge {
				display: flex;
				justify-content: space-between;
				align-items: center;
				width: -moz-fit-content;
				width: fit-content;
				gap: 5px;
				padding: 12px 20px;
				line-height: 18px;
				border-radius: 10px;
				font-size: 0.75rem;
				cursor: pointer;
				svg {
					width: 16px;
					height: 16px;
				}
			}
		}

		.reply-comment {
			padding-left: 1.25rem;
			margin-bottom: 1.25rem;
		}

		.preview-link {
			padding: 0;
			border: none;
			border-radius: 0;
			margin: 24px 0;
			.preview-link__close-btn {
				display: none;
			}
			.preview-link__image {
				height: 320px;
			}
		}

		.create-post-modal-content {
			.preview-link {
				padding: 12px;
				border: #f1f1f5 1px solid;
				border-radius: 12px;
				margin: inherit;
				.preview-link__close-btn {
					display: inherit;
				}
				.preview-link__image {
					height: 280px;
				}
			}
		}

		.post__content-wrapper {
			margin-bottom: 20px;
			.post__content {
				line-height: 1.5em;
				color: #4e4b66;
				word-break: break-word;
				overflow: hidden;

				&.view-less {
					max-height: 14.99em;
					display: block;
					display: -webkit-box;
					-webkit-box-orient: vertical;
					-webkit-line-clamp: 10;
				}

				a {
					width: fit-content;
					overflow: hidden;
					color: $blue;
					&:hover {
						text-decoration: underline;
						color: $blue;
					}
				}
			}

			.read-more-post {
				cursor: pointer;
				margin-top: 8px;
				font-weight: 600;
				font-size: 15px;
				width: fit-content;
				&:hover {
					text-decoration: underline;
				}
			}
		}

		.box_post__user-status {
			display: flex;
			justify-content: space-between;
			.setting {
				position: relative;
				margin-left: auto;
				.setting-mini-post-btn {
					width: 32px;
					height: 32px;
					border-radius: 50%;
					margin-left: auto;
					transition: 0.25s;
				}
				.setting-mini-post-btn:hover {
					background-color: #f1f1f1;
				}
				.setting-list {
					max-width: 230px;
					width: 230px;
					position: absolute;
					top: 70%;
					right: 0;
					border-radius: 10px;
					background-color: #fcfcfc;
					box-shadow: 0 0 20px 2px rgb(232, 232, 237);
					overflow: hidden;
					z-index: 100;
					padding: 8px;
					.setting-item {
						display: block;
						display: flex;
						align-items: center;
						gap: 1rem;
						font-size: 18px;
						font-weight: 600;
						color: #4e4b66;
						padding: 13px;
						cursor: pointer;
						transition: 0.25s;
						svg {
							width: 16px;
							height: 16px;
						}
						.setting-item__content {
							font-size: 16px;
						}
					}
					.setting-item:hover {
						background-color: #f1f1f1;
						border-radius: 5px;
					}
				}
			}
		}
	}

	&__user-status {
		display: flex;
		align-items: center;
		gap: 16px;
		margin-bottom: 12px;

		&__avatar {
			width: 6.05%;
			border-radius: 50%;
			overflow: hidden;
			img {
				width: 100%;
				object-fit: cover;
			}
		}

		&__name {
			font-weight: 600;
			font-size: 18px;
			line-height: 1.5rem;
			letter-spacing: 0.1px;
			& > a:hover {
				text-decoration: underline;
			}
		}

		&__post-time-status {
			display: flex;
			flex-wrap: wrap;
			align-items: center;
			font-size: 13px;
			line-height: 1.4rem;
			color: #a0a3bd;
			column-gap: 6px;
			font-weight: 500;

			&__online-dot {
				width: 2.67px;
				height: 2.67px;
				border-radius: 50%;
				background-color: $primary;
				margin-right: 4px;
			}
			img {
				width: 16px;
				height: 16px;
			}

			.show-time + svg {
				stroke-opacity: 0.5;
				margin-right: 5px;
			}
		}

		&__subtitle {
			display: flex;
			flex-wrap: wrap;
			align-items: center;
			color: $ash-placeholder;
			font-weight: 500;

			.react-rating-container {
				margin-left: 1rem;
			}

			&__svg {
				svg {
					stroke-opacity: 0.5;
				}
			}
		}
	}

	&__options {
		display: flex;
		align-items: center;
		gap: 2rem;
		padding: 20px 16px;
		border-top: #f1f1f5 1px solid;

		&__item {
			display: flex;
			align-items: center;
			gap: 12px;
			font-size: 0.81rem;
			color: #6e7191;
			font-weight: 600;
			cursor: pointer;
			user-select: none;
			svg {
				width: 24px;
				height: 24px;
			}
		}
	}

	&__image {
		overflow: hidden;
		img {
			width: 100px;
			height: auto;
		}
	}

	&__video-youtube {
		width: 100%;
		aspect-ratio: 1/0.6;
		margin: 24px 0;
	}

	&__user__container {
		display: inline-block;
		align-items: center;
		gap: 5px;
		.post__name__group {
			font-weight: 600;
			font-size: 18px;
		}
		span {
			font-weight: lighter;
			a {
				font-weight: 600;
			}
		}

		&__mention-users-plus {
			position: relative;
			> span {
				font-weight: 600 !important;
				position: relative;
				cursor: pointer;
				position: relative;
			}

			::before {
				content: '';
				position: absolute;
				top: -7px;
				left: 0;
				width: 100%;
				height: 20px;
				background-color: transparent;
			}
			& > span:hover {
				+ .post__user__container__list-mention-users {
					opacity: 0.7;
					transition: opacity 0.2s ease;
					left: 50%;
				}
			}
		}

		&__list-mention-users {
			font-weight: 500;
			font-size: 12px;
			position: absolute;
			opacity: 0;
			background-color: black;
			border-radius: 5px;
			padding: 0 5px;
			color: white;
			left: 100vw;
			transform: translateX(-50%);
			width: fit-content;
			max-width: 150px;
			z-index: 3;
			transition: opacity 0.2s ease;
			&__name {
				white-space: nowrap;
				text-overflow: ellipsis;
				overflow: hidden;
			}
		}
	}
}

@media only screen and (width: 1024px) {
	.post {
		&__user-status__post-time-status {
			font-size: 10px;
		}
	}
}

// responsive điện thoại
@media only screen and (max-width: 500px) {
	.post {
		&__container {
			padding: 20px 8px;

			.share-target__progress__percent-number {
				font-size: small;
			}

			.tagged {
				.badge {
					padding: 6px 10px;
				}
			}
		}

		&__user-status {
			gap: 8px;

			&__name {
				font-size: 14px;
			}

			&__subtitle {
				.react-rating-container {
					margin-left: 0.5rem;
				}
			}
		}
	}
}

// màn siêu bé
@media only screen and (max-width: 331px) {
	.post {
		&__container {
			.post__content-wrapper {
				margin-bottom: 10px;

				.post__content,
				.read-more-post {
					font-size: 13px;
				}
			}
		}

		&__user-status {
			&__name {
				font-size: 13px;
			}

			&__post-time-status {
				font-size: 8px;
				.show-time {
					& + svg {
						width: 10px;
						height: 10px;
					}
				}
			}
		}

		&__video-youtube {
			margin: 10px 0;
		}
	}
}
