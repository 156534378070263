@import 'src/scss/abstracts/_variables.scss';

.quote-action-bar {
	display: flex;
	align-items: center;
	gap: 32px;
}

.quote-action__item {
	display: flex;
	align-items: center;
	font-size: 13px;
	color: #6e7191;
	font-weight: 600;
	cursor: pointer;
	-webkit-user-select: none;
	user-select: none;

	& > div,
	> a {
		display: flex;
		align-items: center;
		gap: 10px;
	}

	span {
		line-height: 24px;
		vertical-align: middle;
		white-space: nowrap;
	}

	svg {
		width: 24px;
		height: 24px;
	}

	.quote-icon {
		&.active {
			path {
				stroke: none;
				fill: $primary;
			}
		}
	}
}

.quote-action__name {
	display: inline-block;
}

@media only screen and (max-width: 1366px) {
	.quote-action-bar {
		gap: 28px;
	}
}

@media only screen and (max-width: 1024px) {
	.quote-action {
		&__item {
			gap: 5px;
		}
	}
}

// responsive điện thoại
@media only screen and (max-width: 500px) {
	.quote-action-bar {
		gap: 8px;
	}

	.quote-action {
		&__item {
			& > div,
			> a {
				gap: 5px;
			}
			font-size: smaller;
			svg {
				width: 15px;
				height: 15px;
			}
		}
	}
}
