@import 'src/scss/abstracts/_global.scss';

.layout {
	max-width: 1440px;
	margin: 0 auto;
	min-height: 100vh;
	background-color: $secondary;
	position: relative;
	margin-top: 96px;
	.header__dard {
		position: absolute;
		background: rgba(78, 75, 102, 0.2);
		width: 100%;
		height: 100%;
		z-index: 900;
	}
}

//responsive điện thoại
@media only screen and (max-width: 500px) {
	.layout {
		margin-top: 60px;
	}
}
