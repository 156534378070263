@import 'src/scss/abstracts/_variables.scss';

.read-book-tab {
	&__header {
		display: flex;
		align-items: center;
	}

	&__year {
		font-size: 15px;
		color: #000000;
	}

	&__quantity {
		color: #000000;
		font-size: 1.125rem;
		font-weight: 700;
		line-height: 1.5;
		margin-bottom: 0;
		margin-left: auto;
	}

	.accordion-button:not(.collapsed) {
		background-color: white;
		box-shadow: unset;
		&::after {
			background-position: center;
			background-repeat: no-repeat;
			background-image: url('../../../../assets/images/caret.png');
			background-size: 10.44px 5.22px;
		}
	}

	.accordion-button {
		&::after {
			background-image: url('../../../../assets/images/caret.png');
			margin-right: 90px;
			background-position: center;
			background-repeat: no-repeat;
			background-size: 10.44px 5.22px;
		}

		&:focus {
			box-shadow: unset;
			border-color: unset;
		}
	}

	.accordion-item {
		border: unset;
	}

	.read-book {
		margin-bottom: 1.5rem;
	}
}
