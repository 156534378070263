.topbooks {
	&__container {
		&__header {
			display: flex;
			flex-wrap: wrap;
			gap: 10px;
			justify-content: space-between;
			.select-box {
				&__value {
					font-weight: 600;
					letter-spacing: 0.75px;
					flex: 1;
					text-align: center;
					font-size: 18px;
				}
				&__btn {
					width: 248px;
					justify-content: space-around;
					background: #f5e7d8;
				}
				&__list {
					max-width: 248px;
				}
			}
		}

		&__main {
			.author-book {
				.book-thumbnail {
					max-width: 136px;
					max-height: 204px;
				}
			}
			.author-book__share {
				width: 42px;
				height: 42px;
				border-radius: 50%;
				background: #eff0f7;
				display: flex;
				align-items: center;
				justify-content: center;
				cursor: pointer;
				margin-bottom: 8px;

				&.hide {
					display: none;
				}
			}
		}

		.top__user__ranks {
			display: flex;
			margin: 80px auto 40px auto;
			width: 70%;
			justify-content: space-around;
			align-items: flex-start;
			gap: 0px;
			&__two {
				width: 28%;
				margin-top: 20px;
				.number__ranks {
					font-size: 15px;
				}
			}
			&__one {
				width: 30%;
				.number__ranks {
					font-size: 15px;
				}

				&.hide {
					display: none;
				}
			}
		}
		.top__user {
			margin-left: 0;
			gap: 20px;
		}

		.top-users-slt-box {
			.select-box__list {
				max-height: fit-content;
				overflow-y: auto;
			}
		}
	}
}

.topbooks__container__sort__left.disabled-btn-select {
	opacity: 0.7;
	.select-box__btn {
		cursor: not-allowed;
		background-color: #f7f7fc;
	}
}

// responsive điện thoại
@media only screen and (max-width: 500px) {
	.topbooks {
		&__container {
			&__header {
				.select-box {
					&__btn {
						width: 200px;
					}

					&__value {
						font-size: inherit;
					}
				}
			}

			&__main {
				.author-book {
					&__share {
						&.hide {
							display: inherit;
						}
						&.show {
							display: none;
						}
					}
				}

				&.top__user {
					gap: 10px;
				}
			}

			.top__user__ranks {
				flex-direction: column;
				margin: 50px 0 20px 0;
				width: 100%;

				&__one {
					flex-direction: row;
					width: 100%;
					justify-content: flex-start;
					gap: 10px;
					&.hide {
						display: flex;
					}
					&.show {
						display: none;
					}
					.Crown {
						svg {
							width: 60px;
							height: 60px;
						}
					}
					&__avatar {
						margin: inherit;
						.user-avatar {
							width: 82px;
						}
					}
					&__title {
						text-align: inherit;
					}
				}

				&__two {
					flex-direction: row;
					width: 100%;
					justify-content: flex-start;
					gap: 10px;
					margin-top: 10px;
					&__avatar {
						margin: inherit;
					}
					&__title {
						text-align: inherit;
					}
				}
			}
		}
	}
}
