@import 'src/scss/abstracts/_variables.scss';

.group__background {
	position: relative;
	& > img {
		border-radius: 12px 12px 0px 0px;
		height: 266px;
		width: 100%;
		object-fit: cover;
	}
	.upload-image {
		position: absolute;
		bottom: 20px;
		right: 18px;
		display: flex;
		gap: 10px;
		flex-direction: row;
		padding: 12px;
		border-radius: 12px;

		img {
			width: 20px;
			height: 20px;
		}
	}
}

.group-main-component__container {
	width: 100%;
	.newfeed__create-post__options {
		padding: 0 28px;
	}
}

.group__title-name {
	background-color: #e0af7e;
	height: 39px;
	width: 100%;
	display: flex;
	align-items: center;
	padding-left: 17px;
	span {
		font-family: 'Montserrat';
		font-style: normal;
		font-weight: 500;
		font-size: 13px;
		line-height: 22px;
		letter-spacing: 0.25px;
		color: #fcfcfc;
	}
}
.group-name {
	margin-top: 25px;
	display: flex;
	justify-content: space-between;
	gap: 12px;

	&__content {
		width: 45%;
		margin-top: 8px;
		h2 {
			width: 100%;
			font-family: 'Montserrat';
			font-style: normal;
			font-weight: 700;
			font-size: 24px;
			line-height: 32px;
			letter-spacing: 1px;
			color: #2d2c42;
			word-break: break-word;
			display: -webkit-box;
			-webkit-box-orient: vertical;
			-webkit-line-clamp: 2;
			overflow: hidden;
		}
	}

	&__member {
		display: flex;
		span {
			font-family: 'Montserrat';
			font-style: normal;
			font-weight: 500;
			font-size: 15px;
			line-height: 24px;
			display: flex;
			align-items: center;
			letter-spacing: 0.75px;
			color: #6e7191;
		}
		.groupPublic {
			span {
				font-size: 13px;
			}
			.imgPani {
				display: flex;
				align-items: center;
				margin: 0px 4px 0px 6px;
			}
		}
	}

	&__btn {
		.search-field__input {
			font-family: 'Montserrat';
			font-style: normal;
			font-weight: 500;
			font-size: 15px;
			line-height: 22px;
			display: flex;
			align-items: center;
			letter-spacing: -0.25px;
			color: #a0a3bd;
		}
	}

	&__join-group {
		width: 206px;
		height: 57px;
		background: #e0af7e;
		border-radius: 12px;
		display: flex;
		align-items: center;
		justify-content: center;
		button {
			font-family: 'Montserrat';
			font-style: normal;
			font-weight: 600;
			font-size: 13px;
			line-height: 22px;
			display: flex;
			align-items: center;
			text-align: center;
			letter-spacing: 0.25px;
			color: #fcfcfc;
		}
		svg {
			width: 24px;
			height: 24px;
			margin-right: 12px;
		}
		&:hover {
			cursor: pointer;
		}
	}

	&__joined-group {
		padding-left: 24px;
		width: 100%;
		max-width: 206px;
		height: 57px;
		background: #eff0f6;
		border-radius: 12px;
		display: flex;
		align-items: center;
		position: relative;

		&.opening {
			border-bottom-left-radius: 0;
			border-bottom-right-radius: 0;
		}

		button {
			font-weight: 600;
			font-size: 13px;
			line-height: 22px;
			display: flex;
			align-items: center;
			letter-spacing: 0.25px;
			color: #2d2c42;
			span {
				margin-right: 22px;
			}
		}
		svg {
			width: 24px;
			height: 24px;
			margin-right: 12px;
		}
		.list__dropdown {
			border-radius: 0px 0px 12px 12px;
			position: absolute;
			width: 100%;
			z-index: 999;
			background-color: #eff0f6;
			top: 100%;
			left: 0;
			padding-left: inherit;
			li {
				display: flex;
				align-items: center;
				height: 47px;
				font-weight: 600;
				font-size: 13px;
				line-height: 22px;
				cursor: pointer;
			}
		}
	}

	&__invite-group {
		display: flex;
		justify-content: center;
		align-items: center;
		padding: 16px 20px;
		width: 103px;
		height: 57px;
		background: #fd6150;
		border-radius: 12px;
		margin-left: 15px;
		button {
			font-family: 'Montserrat';
			font-style: normal;
			font-weight: 600;
			font-size: 13px;
			line-height: 22px;
			display: flex;
			align-items: center;
			text-align: center;
			letter-spacing: 0.25px;
			color: #fcfcfc;
			svg {
				margin-right: 15px;
				width: 18px;
				height: 18px;
			}
		}
		&:hover {
			cursor: pointer;
		}
	}
}

.main__title {
	font-family: 'Montserrat';
	font-style: normal;
	font-weight: 600;
	font-size: 18px;
	line-height: 29px;
	display: flex;
	align-items: center;
	color: #2d2c42;
	margin-bottom: 8px;
}

.btn-top {
	display: flex;
}

.group__search {
	margin-top: 14px;
	.search-field {
		background-color: #eff0f7;
		&::placeholder {
			color: $ash-placeholder;
		}
	}
}

.group-tabs {
	margin-top: 32px;
	.nav-item {
		height: 91px;
		width: 161px;
	}
	.nav-link {
		width: 100%;
		height: 100%;
		font-family: 'Montserrat';
		font-style: normal;
		font-weight: 600;
		font-size: 20px;
		line-height: 32px;
		justify-content: center;
		display: flex;
		align-items: center;
		letter-spacing: 0.75px;
		color: #a0a3bd;
	}
}

.mb-3 {
	margin-bottom: 0px !important;
	padding-left: 5px;
	padding-top: 5px;
}

.groupPublic {
	display: flex;
	& svg {
		stroke-opacity: 0.5;
		margin-top: 3px;
	}
	& span {
		margin-left: 5px;
		margin-top: 0;
		align-items: flex-start;
	}
}

@media only screen and (max-width: 1024px) {
	.group-main-component__container {
		.main__title {
			font-size: 15px;
		}

		.search-field {
			height: 47px;
			border-radius: 10px;
			&__icon {
				width: 18px;
				height: 18px;
				margin-right: 20px;
			}

			&__input {
				font-size: 12px;
			}
		}
	}

	.group-name {
		&__content {
			h2 {
				font-size: 20px;
				margin-bottom: 5px;
			}
		}

		&__member {
			span {
				font-size: 11px;
			}
		}

		&__join-group {
			width: 180px;
			height: 47px;
			button {
				font-weight: 600;
				font-size: 12px;
			}
			svg {
				width: 18px;
				height: 18px;
				margin-right: 10px;
			}
		}

		&__joined-group {
			height: 47px;
		}

		&__invite-group {
			padding: 16px 20px;
			width: 90px;
			height: 47px;
			border-radius: 12px;
			margin-left: 10px;
			button {
				font-size: 12px;
				svg {
					margin-right: 10px;
					width: 18px;
					height: 18px;
				}
			}
		}
	}
}

@media only screen and (max-width: 500px) {
	.group__background {
		.upload-image {
			bottom: 8px;
			right: 8px;

			span {
				display: none;
			}
		}
	}

	.group-name {
		flex-direction: column;
		margin-top: 10px;

		&__content {
			width: inherit;

			h2 {
				font-size: medium;
				line-height: 1.5;
			}
		}

		.btn-top {
			justify-content: center;
		}

		&__joined-group {
			height: 40px;

			svg {
				width: 20px;
				height: 20px;
			}
		}

		&__invite-group {
			height: 40px;
		}
	}

	.group-main-component__container {
		.search-field {
			height: 40px;
		}
	}
}

// responsive điện thoại
@media only screen and (max-width: 500px) {
	.group-tabs {
		ul {
			justify-content: space-evenly;
		}

		.nav-item {
			height: 47px;
			width: 125px;
		}

		.nav-link {
			font-size: medium;
		}
	}

	.group-main-component__container {
		.newfeed__create-post__options {
			padding: unset;
		}
	}
}
