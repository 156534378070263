@import 'src/scss/abstracts/_variables.scss';

.sidebar-quote {
	display: flex;
	flex-direction: column;
	gap: 3.75rem;
	padding-top: 12px;
	&__reading__status {
		h4 {
			margin-bottom: 1.5rem;
		}
	}

	.topic-column__container {
		grid-template-columns: 1fr;
	}

	.topic-column__item {
		font-weight: 700;
		width: fit-content;
	}

	.sidebar-quote__category-list {
		p {
			margin-top: 24px;
			text-align: center;
		}

		.dualColumn {
			margin-top: 24px;
			.dualColumn-item {
				.dualColumn-item__title {
					cursor: pointer;
				}

				.dualColumn-item__number {
					font-size: 15px;
					color: $ash;
					font-weight: 600;
				}
			}
		}

		.sidebar-quote__category-list__search {
			border: 1px solid $ash-line;
			::placeholder {
				font-weight: 500;
				letter-spacing: 0.75px;
				color: #a0a3bd;
			}
		}
	}

	.sidebar-quote__author-books {
		margin-top: 3.75rem;
		.book-slider__title {
			line-height: 1.5;
		}
	}
}
