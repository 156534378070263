@import 'src/scss/abstracts/_variables.scss';

.dualColumn-list {
	width: 100%;
	border-radius: 16px;
	overflow: hidden;
	font-size: 13px;
	span {
		display: flex;
		align-items: center;
	}
}

.dualColumn-item {
	padding: 1rem 0 1rem;
	display: flex;
	color: $ash;
	line-height: 1.5;
	justify-content: space-between;
	gap: 1rem;
	&.has-background {
		padding: 1rem 1rem 0;
		&:last-of-type {
			padding-bottom: 1rem;
		}
	}

	&__title {
		display: inline-block;
		font-weight: 600;
	}

	&__number {
		display: flex;
		justify-content: flex-end;
		height: fit-content;
		font-weight: 500;
		color: $ash-placeholder;
		min-width: 30%;
		&.no-page-text {
			color: $ash;
			font-size: 18px;
			font-weight: 600;
		}
	}

	.link:hover {
		cursor: pointer;
		text-decoration: underline;
	}
}

.dualColumn-btn {
	display: flex;
	align-items: center;
	color: $ash;
	margin-top: 1.25rem;
	span {
		font-size: 13px;
	}
	.view-caret {
		margin-right: 15px;
		transition: all 0.2s linear;
		&.view-more {
			transform: rotate(180deg);
		}
	}
}
