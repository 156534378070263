.top__quotes__container {
	padding: 28px 20px 24px 20px;
	display: flex;
	flex-direction: column;
	gap: 24px;
	.top__quotes__description {
		text-align: center;
		font-weight: 500;
		font-size: 15px;
		line-height: 24px;
		color: #4e4b66;
		flex: 1;
		word-wrap: break-word;
	}
	.top__quotes__author {
		text-align: center;
		font-weight: 400;
		font-size: 13px;
		line-height: 24px;
		color: #4e4b66;
		text-decoration-line: underline;
	}
	.top__quotes__footer {
		display: flex;
		justify-content: space-between;
	}
	.top__quotes__info {
		display: flex;
		gap: 15px;
	}
	.quote-action__name:nth-last-child(2) {
		color: #0576f0;
	}
}

// responsive điện thoại
@media only screen and (max-width: 500px) {
	.top__quotes__container {
		gap: 10px;
		.top__quotes__footer {
			flex-direction: column;
			align-items: center;
		}
	}
}
