.input-form-group {
	margin-top: 32px;
	input {
		font-size: 14px;
		line-height: inherit;
		background-color: transparent;
		width: 100%;
		&:focus {
			outline: none;
		}
	}
	.input-keyword {
		padding: 0 0 0 17px;
		min-height: 43px;
	}
	.input-hashtag-input-element {
		height: 43px;
	}

	&__label {
		font-size: 14px;
		font-weight: 600;
		line-height: 22px;
		margin-bottom: 24px;
	}
	&__list {
		display: flex;
		align-items: center;
		background-color: #f7f7fc;
		border-radius: 12px;
		flex-wrap: wrap;
		min-height: 57px;
		padding: 7px 24px;
		cursor: text;
		&__cards {
			flex-wrap: wrap;
			display: flex;
			cursor: pointer;
			align-items: center;
			gap: 8px;
			max-width: 100%;
			> span {
				color: #b38c64;
				background-color: #f5e7d8;
				padding: 0 18px;
				height: 43px;
				border-radius: 10px;
				overflow: hidden;
				display: flex;
				align-items: center;
				span {
					font-size: 13px;
					font-weight: 600;
					overflow: hidden;
					text-overflow: ellipsis;
				}
				button {
					z-index: 2;
				}
			}
		}
	}

	.input-hashtag-warning {
		color: #e61b00;
		margin-top: 24px;
	}
}

// responsive điện thoại
@media only screen and (max-width: 500px) {
	.input-form-group {
		margin-top: 20px;

		&__label {
			margin-bottom: 8px;
		}

		&__list {
			min-height: 40px;
			padding: 0 10px;

			&__cards {
				> span {
					height: 34px;
					padding: 0 8px;
					span {
						font-size: smaller;
					}
				}
			}
		}

		.input-keyword {
			min-height: 40px;
		}
	}
}
