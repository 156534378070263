@import 'src/scss/abstracts/_variables.scss';

.book-thumbnail {
	border-radius: 8px;
	overflow: hidden;
	cursor: pointer;
	aspect-ratio: 2/3;
	img {
		width: 100%;
		height: 100%;
		object-fit: cover;
	}

	&.book-thumbnail {
		&-sm {
			width: $width-book-sm;
		}
		&-md {
			width: calc(#{$width-book-md} - 16px);
		}
		&-lg {
			width: $width-book-lg;
		}
	}
}
