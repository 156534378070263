@import 'src/scss/abstracts/_variables.scss';

.sort-review-modal {
	&__item {
		padding: auto 0;
		height: 56px;
		display: flex;
		align-items: center;

		&__close-button {
			display: none;
		}

		& .form-check-wrapper {
			width: 100%;
		}

		& span {
			margin-bottom: 0px;
		}

		& button {
			width: 100%;
			font-size: 18px;
		}
	}
	.modal-dialog {
		width: 376px;

		.modal-content {
			border-radius: 8px;
			gap: 12px;
			display: flex;
			flex-direction: column;
			align-items: flex-start;
			padding: 32px;

			.modal-body {
				padding: 0px;
				margin: 0 auto;
				width: 312px;

				& .form-check-input[type='checkbox'] {
					background-color: white;
					border: 2px solid #a0a3bd;
				}
			}
		}
	}
	.form-check-input.is-valid ~ .form-check-label {
		font-size: 18px;
	}
}

.search-review {
	margin: 0 40px;
	height: 56px;
	padding: 0 1.5em;
	border-radius: 16px;
	overflow: hidden;
	display: flex;
	align-items: center;
	background-color: $ash-background;
	cursor: pointer;

	&__icon {
		width: 20px;
		height: 20px;
		margin-right: 20px;
	}

	&__input {
		background-color: inherit;
		font-size: 16px;
		color: $ash-placeholder;
	}
}

.review-tab {
	padding-top: 30px;

	&__no-data {
		text-align: center;
		margin: 0 auto;
		padding: 40px 0;
	}

	&__search {
		padding: 2rem 2.5rem 0;
	}

	.post__container {
		margin-bottom: 0;
		padding: 32px 40px 22px;
	}

	.search-field__input::placeholder {
		color: $ash-placeholder;
	}
}

@media only screen and (max-width: 1280px) {
	.sort-review-modal__item {
		button {
			margin-top: 30px;
		}
	}
}

// responsive điện thoại
@media only screen and (max-width: 500px) {
	.sort-review-modal {
		.modal-dialog {
			width: auto;
			.modal-content {
				padding: 20px 10px;

				.modal-body {
					width: inherit;
				}
			}
		}

		&__item {
			height: 40px;
			justify-content: space-between;

			&__close-button {
				display: block;
			}

			button {
				margin-top: 0;
				font-size: small;
			}
		}

		.form-check-input.is-valid ~ .form-check-label {
			font-size: small;
		}
	}

	.review-tab {
		padding-top: 20px;

		&__filter {
			&__options {
				gap: 0;
			}
		}

		&__search {
			padding: 1rem 0;

			.search-field {
				margin: 0 10px;
			}
		}

		.post__container {
			padding: 20px 8px;
		}

		.post-action-bar {
			padding: 10px 20px;
			justify-content: space-between;

			&__item {
				svg {
					width: 20px;
					height: 20px;
				}
			}

			&__title {
				display: none;
			}
		}
	}

	.search-review {
		margin: 0 10px;
		height: 40px;

		&__icon {
			display: none;
		}

		&__input {
			font-size: small;
		}
	}
}
