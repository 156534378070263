.grid-image {
	position: relative;
	border-radius: 8px;
	overflow: hidden;
	width: 100%;

	&.one-image {
		display: block;
		max-height: 700px;
	}

	&.more-one-image {
		display: block;
		height: 500px;
	}

	&.add-margin {
		margin: 24px 0;
	}

	.grid-image__image-wrapper {
		position: absolute;
		overflow: hidden;
		padding: 1px;
		img {
			display: block;
			width: 100%;
			height: 100%;
			object-fit: cover;
			margin: auto;
			cursor: pointer;
		}

		&__image-over {
			position: absolute;
			inset: 1px;
			background-color: rgba(0, 0, 0, 0.4);
			display: flex;
			justify-content: center;
			align-items: center;
			color: white;
			font-size: 36px;
			font-weight: 700;
			letter-spacing: 5px;
			user-select: none;
			cursor: pointer;
		}
	}

	.grid-image__container {
		position: relative;
		border-radius: 8px;
		overflow: hidden;
		width: 100%;
		height: 100%;
	}

	.grid-image__item-over {
		position: absolute;
		inset: 0 0 1px 0;
		background-color: rgba(0, 0, 0, 0.4);
		display: flex;
		justify-content: center;
		align-items: center;
		color: white;
		font-size: 36px;
		font-weight: 700;
		letter-spacing: 5px;
	}

	&.one-image {
		.grid-image__image-wrapper {
			padding: 0;
		}
	}
}

.slick-prev {
	margin-left: -15px !important;
}

.slick-next:before {
	margin-left: 15px;
}
.ril-inner {
	background-color: black !important;
}
