.authors-search-container {
	padding: 32px 28px;

	&__item {
		display: flex;
		flex-direction: column;
		gap: 30px;
	}
}

@media only screen and (max-width: 820px) {
	.authors-search-container {
		padding: 28px 24px;
	}
}

@media only screen and (max-width: 768px) {
	.authors-search-container {
		padding: 24px 20px;
	}
}
