@import 'src/scss/abstracts/_variables.scss';

.comment {
	display: flex;
	margin-bottom: 1.25rem;

	&__editing {
		width: 100%;
		word-break: break-word;
		&__cancel {
			width: fit-content;
			font-size: 12px;
			margin-top: 0.3rem;
			span {
				color: $blue;
				cursor: pointer;
				&:hover {
					text-decoration: underline;
				}
			}
		}
	}

	&__wrapper {
		width: 100%;
		&__info {
			display: flex;
			align-items: center;
			&:hover {
				.comment__wrapper__info__options {
					opacity: 1;
				}
			}
			&__options {
				margin-left: 10px;
				position: relative;
				opacity: 0;
				&.isShowing {
					opacity: 1;
				}
				&:hover {
					.comment__wrapper__info__options__elipsis {
						background-color: #f7f7fc;
					}
				}
				&__elipsis {
					cursor: pointer;
					width: 25px;
					height: 25px;
					text-align: center;
					border-radius: 50%;
					user-select: none;
				}
				&__list {
					position: absolute;
					pointer-events: none;
					opacity: 0;
					font-size: 13px;
					font-weight: 600;
					width: max-content;
					transform: translate(-50%, 0);
					left: 50%;
					margin-top: 8px;
					padding: 8px;
					background-color: $white;
					box-shadow: 0 0 20px 2px rgba($ash-placeholder, 0.4);
					border-radius: 8px;
					z-index: 2;

					&.show {
						pointer-events: inherit;
						opacity: 1;
					}

					&.move-element {
						left: -100%;
					}

					p {
						cursor: pointer;
						padding: 8px;
						border-radius: 5px;
						&:hover {
							background-color: $grey-border;
						}
					}
				}
			}
		}
	}

	&__container {
		position: relative;
		margin-bottom: 0.3em;
		padding: 1em;
		border-radius: 16px;
		background-color: $ash-background;
		font-size: 13px;
		width: fit-content;
	}

	&--secondary {
		padding-left: 1.25rem;
	}

	&__header {
		display: flex;
		align-items: center;
	}
	&__avatar {
		flex-shrink: 0;
		margin-right: 8px;
	}

	&__author {
		font-weight: 600;
		line-height: 1.5;
		color: $ash;
		&:hover {
			text-decoration: underline;
		}
	}

	&__badge {
		margin-left: 0.7em;
		padding: 0.4em 0.6em;
		font-size: 0.75rem;
		font-weight: 500;
	}

	&__content {
		font-weight: 500;
		line-height: 1.5;
		color: $ash-body;
		word-break: break-word;
		overflow: hidden;
		display: block;
		display: -webkit-box;
		-webkit-box-orient: vertical;

		&.view-less {
			-webkit-line-clamp: 2;
			max-height: 2.99em;
		}
	}

	.box-comment__content {
		font-weight: 500;
		line-height: 1.5;
		color: $ash-body;
		word-break: break-word;

		.comment__content__readMore {
			cursor: pointer;
			color: #000000 !important;
			font-weight: 600;
		}

		.comment__content__readMore:hover {
			text-decoration: underline;
		}
	}

	&__action {
		display: flex;
		gap: 1em;
		font-size: 11px;
		font-weight: 600;
		line-height: 1.5;
		color: $ash-label;
	}

	&__item {
		cursor: pointer;
		user-select: none;
		&--timeline {
			font-weight: 500;
			color: $ash-placeholder;
		}
		&.liked {
			color: $primary;
		}
		&:hover {
			text-decoration: underline;
		}
	}

	.mention-class {
		font-family: inherit;
		font-size: 14px;
		&:hover {
			text-decoration: underline;
		}
	}

	.url-class {
		width: fit-content;
		overflow: hidden;
		color: $blue;
		&:hover {
			text-decoration: underline;
			color: $blue;
		}
	}

	.cmt-like-number {
		position: absolute;
		height: 20px;
		right: -10px;
		bottom: -5px;
		display: flex;
		justify-content: center;
		align-items: center;
		font-size: 14px;
		line-height: 22px;
		font-weight: 600;
		gap: 4px;
		background-color: white;
		border-radius: 45px;
		box-shadow: 0px 2px 7px rgba(0, 0, 0, 0.1);
		padding: 0px 6px;
	}
}
