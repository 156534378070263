@import 'src/scss/abstracts/_global.scss';

.subContainer {
	display: flex;
	justify-content: space-between;
	padding: 0 22px 0 40px;
	.subContainer__sidebar-wrapper {
		flex-shrink: 0;
		&.left {
			max-width: 301px;
			width: 100%;
		}
		&.right {
			max-width: 319px;
			width: 100%;
		}
		.subContainer__left {
			max-width: 301px;
		}
		.subContainer__right {
			max-width: 319px;
		}
		.subContainer__left,
		.subContainer__right {
			width: 100%;
			position: fixed;
			height: calc(100vh - 96px);
			overflow-x: hidden;
			overflow-y: scroll;
			overscroll-behavior: contain;
			padding: 40px 14px 40px 0;

			&::-webkit-scrollbar {
				width: 4px;
			}
			/* track */
			&::-webkit-scrollbar-track {
				background: transparent;
				border-radius: 8px;
			}
			/* thumb */
			&::-webkit-scrollbar-thumb {
				background: transparent;
				border-radius: 8px;
			}

			&.show-scrollbar {
				&::-webkit-scrollbar-thumb {
					background: rgba(#606060, 0.2);
				}
			}
		}

		.subContainer__left {
			z-index: 200;
		}

		.subContainer__right {
			padding: 40px 14px 40px 25px;
		}
	}

	.subContainer__main {
		max-width: 698px;
		flex-grow: 1;
		padding: 40px 0;
	}
}

@media only screen and (max-width: 1366px) {
	.subContainer {
		.subContainer__sidebar-wrapper {
			&.left {
				max-width: 271px;
			}
			.subContainer__left {
				max-width: 271px;
			}

			&.right {
				max-width: 289px;
			}
			.subContainer__right {
				max-width: 289px;
			}
			.subContainer__left {
				padding: 28px 14px 40px 0;
			}
			.subContainer__right {
				padding: 28px 14px 40px 25px;
			}
		}
		.subContainer__main {
			max-width: 660px;
			padding: 28px 0 40px;
		}
	}
}

@media only screen and (max-width: 1096px) {
	.subContainer {
		padding: 0 2px 0 16px;
		.subContainer__sidebar-wrapper {
			&.left,
			&.right {
				max-width: 217px;
			}
			.subContainer__left,
			.subContainer__right {
				max-width: 217px;
			}

			.subContainer__left {
				padding: 28px 10px 0 0;
			}
			.subContainer__right {
				padding: 28px 10px 40px 14px;
			}
		}
	}
}

@media only screen and (max-width: 894px) {
	.subContainer {
		.subContainer__sidebar-wrapper.left {
			display: none;
		}
	}
}

@media only screen and (max-width: 820px) {
	.subContainer {
		padding: 0;
		.subContainer__sidebar-wrapper {
			display: none;
		}

		.subContainer__main {
			max-width: 700px;
			margin: auto;
			padding: 0 28px 28px 28px;
		}
	}
}

// responsive điện thoại
@media only screen and (max-width: 500px) {
	.subContainer {
		.subContainer__main {
			padding: 15px 10px;
		}
	}
}
