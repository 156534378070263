@import 'src/scss/abstracts/_variables.scss';

.user-avatar {
	display: inline-block;
	border-radius: 50%;
	overflow: hidden;
	aspect-ratio: 1/1;
	box-shadow: 0px 0px 2px 0px $ash-placeholder;
	&__img {
		width: 100%;
		height: 100%;
		object-fit: cover;
		background-color: white;
	}

	&.user-avatar {
		cursor: pointer;
		&-sm {
			width: $width-avatar-sm;
		}
		&-mm {
			width: 56px;
		}
		&-md {
			width: $width-avatar-md;
		}
		&-lg {
			width: $width-avatar-lg;
		}
		&-xl {
			width: $width-avatar-xl;
		}
	}
}

// responsive điện thoại
@media only screen and (max-width: 500px) {
	.user-avatar.user-avatar-md {
		width: 40px;
	}
}
