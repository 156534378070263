.result {
	&__notfound {
		&__main {
			text-align: center;

			img {
				max-width: 262px;
				width: 80%;
			}
		}

		&__title {
			font-weight: 700;
			font-size: 20px;
			line-height: 32px;
			color: #2d2c42;
			margin-top: 15px;
			margin-bottom: 4px;
		}

		&__description {
			font-weight: 600;
			font-size: 13px;
			line-height: 22px;
			color: #a0a3bd;
			margin-bottom: 2.75rem;
		}
	}
}

// responsive điện thoại
@media only screen and (max-width: 500px) {
	.result {
		&__notfound {
			&__title {
				font-size: medium;
			}

			&__description {
				margin-bottom: 1.5rem;
			}
		}
	}
}
