@import 'src/scss/abstracts/_variables.scss';
.bookcase {
	&__item {
		margin-bottom: 60px;

		&-name {
			font-size: 20px;
			line-height: 32px;
			font-weight: 600;
			color: $ash;
		}

		&__book {
			margin-top: 32px;
			display: flex;
			justify-content: space-between;

			&-info {
				display: flex;
				flex-direction: column;
				justify-content: space-between;
				width: 70%;
			}

			&-name {
				font-size: 24px;
				line-height: 32px;
				font-weight: 700;
				color: $ash;
				text-align: justify;
			}

			&-progress {
				margin-top: 32px;

				&-bar {
					height: 10px !important;
					border-radius: 5px !important;
					.progress-bar {
						border-radius: 5px !important;
					}
				}
			}

			&-percent {
				width: 100%;
				display: flex;
				justify-content: flex-end;
				margin-top: 4px;
				font-weight: 600;
				font-size: 15px;
				line-height: 21px;
				color: $ash-label;
			}
		}

		&__author-name {
			line-height: 24px;
			color: $ash-label;
			margin-top: 20px;
			font-size: 15px;
		}

		&__button {
			width: 100%;
			display: flex;
			justify-content: flex-end;
			button {
				border-radius: 12px;
				padding: 16px 50px;
				color: #fcfcfc;
				font-weight: 600;
				background-color: $primary;
				letter-spacing: 0.75px;
				line-height: 24px;
				font-size: 18px;
			}
		}

		&__reviews {
			margin-top: 40px;
			border-radius: 16px;
			padding: 36px 24px 36px 40px;
			background-color: $secondary;

			&-name {
				font-weight: 600;
				color: $ash;
				font-size: 20px;
				line-height: 32px;
				padding-bottom: 16px;
			}

			&-list {
				margin: 0 0 4px 40px;
			}
		}

		&__review {
			&-item {
				display: flex;
				align-items: center;
				gap: 24px;
				margin-top: 16px;
				.bookcase__review-item__svg {
					position: relative;
					.bookcase__review-item__vertical-stick {
						position: absolute;
						bottom: 100%;
						left: 0;
						width: 100%;
						display: flex;
						justify-content: center;
						.bookcase__vertical-stick {
							width: 2px;
							height: 17px;
							background-color: $primary;
						}
					}
				}
				&__text {
					font-weight: 600;
					color: $ash;
					font-size: 13px;
				}
			}
		}
	}

	&__review {
		&-item {
			display: flex;
			align-items: center;
			gap: 24px;
			margin-top: 16px;
			.bookcase__review-item__svg {
				position: relative;
				.bookcase__review-item__vertical-stick {
					position: absolute;
					bottom: 100%;
					left: 0;
					width: 100%;
					display: flex;
					justify-content: center;
					.bookcase__vertical-stick {
						width: 2px;
						height: 17px;
						background-color: $primary;
					}
				}
			}
			&__text {
				font-weight: 600;
				color: $ash;
				font-size: 13px;
			}
		}

		&-all {
			margin-top: 8px;
			display: flex;
			justify-content: flex-end;
			button {
				font-size: 13px;
				color: $blue;
				span {
					margin-right: 12px;
					font-weight: 600;
				}
			}
		}
	}
}

@media only screen and (max-width: 1024px) {
	.bookcase {
		&__item {
			&__book {
				&-info {
					margin-left: 20px;
				}
			}

			&-name {
				font-size: 18px;
			}
		}
	}
}

// responsive điện thoại
@media only screen and (max-width: 500px) {
	.bookcase {
		&__item {
			&__book {
				&-info {
					margin-left: 10px;
				}

				&-name {
					font-size: 14px;
					line-height: 20px;
				}

				&-progress {
					margin-top: 20px;
				}

				.book-thumbnail {
					width: 150px;
				}
			}

			&__author {
				&-name {
					line-height: 18px;
					margin-top: 15px;
				}
			}

			&__button {
				button {
					font-size: small;
					padding: 8px 13px;
				}
			}

			&__reviews {
				margin-top: 25px;
				padding: 20px;

				&-name {
					line-height: 1.5;
					font-size: medium;
					padding-bottom: 0;
				}

				&-list {
					margin: 0;
				}
			}
		}
	}
}
