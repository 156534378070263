@import 'src/scss/abstracts/_global.scss';

.main-confirm-my-book {
	&__back-btn {
		display: flex;
		align-items: center;
		gap: 36px;
		.back-btn {
			padding: 8px;
		}
		span {
			font-weight: 600;
			font-size: 20px;
			letter-spacing: 0.75px;
		}
	}

	&__book-info {
		margin-top: 44px;
		display: flex;
		gap: 36px;
	}

	&__hash-tags {
		display: flex;
		align-items: center;
		gap: 12px;
	}

	&__hash-tag-item {
		padding: 8px;
		display: flex;
		justify-content: center;
		align-items: center;
		border-radius: 8px;
		background-color: $primary-light;
		color: $primary-dark;
		font-size: 13px;
	}

	&__book-name {
		margin-top: 16px;
		font-size: 24px;
		line-height: 32px;
		font-weight: 700;
		color: $ash;
		display: -webkit-box;
		-webkit-box-orient: vertical;
		-webkit-line-clamp: 2;
		overflow: hidden;
	}

	&__author-name {
		margin-top: 12px;
		display: flex;
		align-items: center;
		gap: 16px;
		color: $ash;
	}

	&__description {
		margin-top: 16px;
	}

	&__check {
		margin-top: 24px;
		border-radius: 12px;
		border: $success-dark 2px solid;
		width: 200px;
		padding: 16px;
		display: flex;
		align-items: center;
		justify-content: center;
		gap: 16px;
		line-height: 24px;
		color: $success-dark;
		font-weight: 600;
		svg {
			path {
				stroke: $success-dark;
			}
		}
	}

	&__terms {
		margin-top: 40px;
		border-radius: 16px;
		background-color: white;
		padding: 40px;
		color: $ash;

		&__header {
			font-size: 20px;
			line-height: 32px;
			font-weight: 600;
		}

		&__body {
			margin-top: 20px;
			text-align: justify;
			line-height: 24px;
			color: $ash-body;
		}

		&__footer {
			margin-top: 52px;
			display: flex;
			align-items: center;
			justify-content: flex-end;
			gap: 16px;
			svg {
				cursor: pointer;
			}
			.checkbox-circle {
				width: 24px;
				height: 24px;
				border: 2.5px solid;
				border-radius: 50%;
				cursor: pointer;
			}
		}
	}

	&__confirm {
		margin-top: 56px;
		padding: 0 40px;
		display: flex;
		justify-content: space-between;

		&__text-1 {
			font-weight: 600;
			font-size: 20px;
			line-height: 32px;
			color: $ash;
		}

		&__text-2 {
			line-height: 24px;
			color: $ash-body;
			margin: 16px 0 24px;
		}

		&__upload-images {
			width: fit-content;
			border-radius: 12px;
			border: $ash-line 2px solid;
			display: flex;
			align-items: center;
			justify-content: center;
			gap: 12px;
			padding: 16px 24px;
			color: $ash;
			font-weight: 600;
			height: fit-content;
			svg {
				path {
					stroke: $ash;
				}
			}
			&.disable {
				cursor: no-drop;
				span,
				svg {
					opacity: 0.5;
				}
			}
		}

		&__images-uploaded {
			display: flex;
			gap: 20px;
			margin-top: 24px;
		}

		&__image-item {
			width: 120px;
			height: fit-content;
			max-height: 120px;
			overflow: hidden;
			border: $ash-line 1px solid;
			position: relative;
			img {
				width: 100%;
			}
		}

		&__image-over {
			position: absolute;
			inset: 0;
			background-color: rgba(0, 0, 0, 0.4);
			justify-content: center;
			align-items: center;
			color: white;
			font-size: 36px;
			font-weight: 700;
			letter-spacing: 5px;
			display: none;
			user-select: none;
			&.show {
				display: flex;
			}
		}

		&__submit {
			width: fit-content;
			border-radius: 12px;
			display: flex;
			align-items: center;
			justify-content: center;
			gap: 12px;
			padding: 16px 32px;
			color: white;
			font-weight: 600;
			background-color: $success-dark;
			margin-top: 40px;
			height: fit-content;
		}
	}
}

@media only screen and (max-width: 1024px) {
	.main-confirm-my-book__confirm {
		&__book-info {
			gap: 24px;
		}

		&__block-right {
			& img {
				width: 300px;
				height: 300px;
			}
		}

		&__confirm {
			padding: 0 15px;
		}
	}
}

@media only screen and (max-width: 820px) {
	.main-confirm-my-book__book-info {
		margin-top: 10px;
	}
}

@media only screen and (max-width: 768px) {
	.main-confirm-my-book {
		&__check {
			margin-top: 10px;
			padding: 8px 12px;
			width: fit-content;
			gap: 8px;
			line-height: normal;
			font-size: small;

			svg {
				width: 16px;
				height: 16px;
			}
		}
	}
}

// responsive điện thoại
@media only screen and (max-width: 500px) {
	.main-confirm-my-book {
		&__book-info {
			gap: 16px;
		}

		&__book-name {
			font-size: medium;
			margin-top: inherit;
			line-height: 1.5;
		}

		&__author-name {
			margin-top: inherit;
			gap: 8px;

			svg {
				width: 16px;
				height: 16px;
			}
		}

		&__terms {
			padding: 20px;

			&__header {
				font-size: medium;
			}

			&__body {
				margin-top: 10px;
			}

			&__footer {
				margin-top: 20px;
			}
		}

		&__confirm {
			margin-top: 35px;
			flex-direction: column;
			padding: 0;
			align-items: center;

			&__block-left {
				display: flex;
				flex-wrap: wrap;
				gap: 10px;
				justify-content: center;
			}

			&__text-2 {
				width: 100%;
				margin: 0;
				text-align: center;
			}

			&__upload-images {
				padding: 8px 12px;
			}

			&__submit {
				margin-top: inherit;
				padding: 8px 12px;
			}

			&__images-uploaded {
				margin-top: inherit;
			}
		}
	}
}
