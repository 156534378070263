.main-content__container {
	background-color: #fbf7f2;
	border-radius: 16px;

	.post-data__blank {
		padding: 2.5rem 0;
		background-color: #fff;
		text-align: center;
	}
}
