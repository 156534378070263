.group-sibar-left {
	&__container {
		width: 100%;
	}

	&__title {
		font-family: 'Montserrat';
		font-style: normal;
		font-weight: 600;
		font-size: 20px;
		line-height: 32px;
		display: flex;
		align-items: center;
		letter-spacing: 0.75px;
		color: #2d2c42;
	}

	&__description {
		background: #ffffff;
		border-radius: 16px;
		padding: 20px 18px;
		display: flex;
		flex-direction: column;
		gap: 20px;
		margin-top: 32px;

		span {
			img {
				width: 24px;
				height: 24px;
				margin-right: 22px;
			}
		}
	}

	&__text1 {
		strong {
			color: #2d2c42;
			font-weight: 600;
		}
		span {
			font-family: 'Montserrat';
			font-style: normal;
			font-weight: 500;
			font-size: 15px;
			line-height: 24px;
			color: #6e7191;
		}
		word-break: break-word;
	}

	&__btn {
		margin-top: 20px;
		display: flex;
		justify-content: flex-end;
		align-items: center;
		margin-bottom: 20px;

		button {
			color: #0576f0;
			font-size: 13px;
		}
	}

	&__people {
		background: #ffffff;
		border-radius: 16px;
		margin-top: 32px;
		padding: 20px 6px 24px 16px;
		img {
			width: 54px;
			height: 54px;
			object-fit: cover;
			border-radius: 50%;
		}
		&__people-admin {
			b {
				font-size: 15px;
			}
		}
	}

	&__people-friends {
		margin-top: 15px;
		b {
			font-size: 15px;
		}
	}

	&__people-folowers {
		margin-top: 21px;
	}
}

.under-title {
	margin-top: 60px;
}

.people-item {
	height: 54px;
	display: flex;
	align-items: center;
	margin-top: 15px;
	.people-item__text {
		display: flex;

		justify-content: center;
		flex-direction: column;
		margin-left: 14.5px;
		span {
			font-family: 'Montserrat';
			font-style: normal;
			font-weight: 600;
			font-size: 18px;
			line-height: 24px;
			display: -webkit-box;
			-webkit-line-clamp: 1;
			-webkit-box-orient: vertical;
			text-overflow: ellipsis;
			overflow: hidden;
			align-items: center;
			letter-spacing: 0.75px;
			color: #2d2c42;
			max-height: 24px;
		}
		div {
			margin-top: 5px;
			font-family: 'Montserrat';
			font-style: normal;
			font-weight: 500;
			font-size: 13px;
			line-height: 22px;
			display: flex;
			align-items: center;
			letter-spacing: 0.25px;
			color: #6e7191;
		}
	}
}

.manage-item {
	cursor: pointer;
	font-family: 'Montserrat';
	font-style: normal;
	font-weight: 500;
	font-size: 14px;
	line-height: 24px;
	align-items: center;
	letter-spacing: 0.75px;
	color: #6e7191;
	display: flex;
	margin-top: 22px;
	svg {
		margin-right: 8px;
		width: 28px;
		height: 28px;
	}
	p {
		margin-right: 10px;
		background-color: #e0af7e;
		border-radius: 50%;
		width: 30px;
		height: 28px;
		padding: 3px;
		font-family: 'Montserrat';
		font-style: normal;
		font-weight: 500;
		font-size: 13px;
		line-height: 22px;
		display: flex;
		align-items: center;
		letter-spacing: 0.25px;
		color: #fcfcfc;
	}
}
.manage-btn {
	cursor: pointer;
	display: flex;
	align-items: center;
	svg {
		margin-right: 10px;
	}
}
.group__manager {
	margin-bottom: 60px;
}

@media only screen and (max-width: 1024px) {
	.group-sibar-left {
		&__container {
			.people-item {
				img {
					width: 34px;
					height: 34px;
				}
				.people-item__text {
					span {
						font-size: 13px;
					}
				}
			}
		}

		&__text1 {
			span {
				font-weight: 500;
				font-size: 13px;
				line-height: 24px;
				color: #6e7191;
			}
			word-break: break-word;
		}

		&__description {
			gap: 12px;
			padding: 18px 14px;

			span {
				img {
					width: 24px;
					height: 24px;
					margin-right: 22px;
				}
			}
		}
	}
}
