.hashtag-quotes {
	max-width: 900px;
	margin: auto;
	padding: 40px;

	.quotes-blank {
		display: flex;
		align-items: center;
		justify-content: center;
	}
}
