@import 'src/scss/abstracts/_variables.scss';

.react-rating-container {
	white-space: nowrap;

	.star-icon {
		width: 20px;
		path {
			fill: $ash-line;
		}

		&.fill {
			path {
				fill: $warning;
			}
		}
	}

	&.small {
		.star-icon {
			width: 12px;
		}

		svg {
			width: 12px;
			height: 12px;
		}
	}
}

// responsive điện thoại
@media only screen and (max-width: 500px) {
	.react-rating-container {
		.star-icon {
			width: 16px;
		}

		svg {
			width: 16px;
			height: 16px;
		}
	}
}
