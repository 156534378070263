@import 'src/scss/abstracts/_variables.scss';

.read-more {
	font-size: 15px;
	text-align: justify;

	.post__content {
		font-size: 15px;
		line-height: 1.5em;
		color: #4e4b66;
		word-break: break-word;
		overflow: hidden;
		display: block;

		display: -webkit-box;
		-webkit-box-orient: vertical;
		text-overflow: ellipsis;
	}

	&-post {
		cursor: pointer;
		margin-top: 3px;
		font-weight: 600;
		font-size: 15px;
		line-height: 1.5em;
		width: fit-content;
		&:hover {
			text-decoration: underline;
		}
	}
}

// responsive điện thoại
@media only screen and (max-width: 500px) {
	.read-more {
		.post__content {
			font-size: small;
		}

		&-post {
			font-size: small;
		}
	}
}
