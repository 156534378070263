@import 'src/scss/abstracts/_variables.scss';

.post-book {
	margin-bottom: 24px;
	display: flex;
	flex-direction: row;
	overflow: auto;

	> a {
		display: contents;
	}

	.book-thumbnail {
		flex-shrink: 0;
		margin-right: 1.25rem;
	}

	&__informations {
		flex-grow: 1;
		display: flex;
		flex-direction: column;
		justify-content: space-around;
		gap: 1.5rem;
	}
	&__name {
		font-weight: 600;
		font-size: 1.25rem;
		line-height: 2rem;
		color: #2d2c42;
		display: -webkit-box;
		-webkit-box-orient: vertical;
		-webkit-line-clamp: 2;
		text-overflow: ellipsis;
		overflow: hidden;
		cursor: pointer;
	}

	&__author {
		font-size: 0.81rem;
		line-height: 1.375rem;
		color: #6e7191;
	}

	&__button-and-rating {
		display: flex;
		gap: 0.8rem;

		& > button {
			width: fit-content;
		}
	}

	&__rating__group {
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
	}

	&__rating__number {
		font-weight: 600;
		font-size: 11px;
		color: #a0a3bd;
		margin-top: 0.5rem;
	}

	&__edit {
		margin-top: 1.5rem;

		.linear-progress {
			height: 12px !important;
			width: 90%;
			max-width: 256px;

			.linear-progress-bar {
				border-radius: 0.5rem;
			}
		}
	}

	&__editor {
		display: flex;
		align-items: center;
		margin-top: 0.5rem;
	}

	&__ratio {
		font-size: 18px;
		font-weight: 600;
		& + * {
			font-size: 13px;
			font-weight: 600;
			color: $ash-label;
			margin-left: 0.75rem;
		}
	}

	&__message {
		flex-grow: 1;
		margin-left: auto;
		text-align: right;
		font-size: 0.875rem;
		font-weight: 600;
		color: $red;
	}
}

.create-post-modal-content__container {
	.post-book__button-and-rating {
		justify-content: space-between;
		gap: 0;
	}
}

@media only screen and (max-width: 1024px) {
	.post-book {
		&__name {
			font-size: 18px;
		}
		&__ratio {
			font-size: 16px;
			& + * {
				font-size: 13px;
			}
		}
		.btn-status {
			height: 46px;
			&.btn {
				span {
					font-size: 15px;
				}
			}
		}
	}
}

@media only screen and (max-width: 820px) {
	.create-post-modal-content__container {
		.post-book {
			.post-book__button-and-rating {
				gap: 0rem;
			}
		}
	}
}

// responsive điện thoại
@media only screen and (max-width: 500px) {
	.post-book {
		flex-direction: column;
		gap: 10px;
		align-items: center;
		overflow: inherit;
		&__edit {
			margin-top: 0.8rem;

			.linear-progress {
				margin: 0 auto;
			}
		}

		&__editor {
			justify-content: center;
		}

		&__informations {
			gap: 0.8rem;
			text-align: center;
		}

		&__name {
			font-size: 14px;
			line-height: 1.5rem;
		}

		&__button-and-rating {
			justify-content: center;

			.btn-status.btn {
				height: 36px;

				span {
					font-size: small;
				}
			}
		}

		.book-thumbnail {
			width: $width-book-sm;
		}
	}
}

// màn siêu bé
@media only screen and (max-width: 331px) {
	.post-book {
		.book-thumbnail {
			margin-right: 0.5rem;
		}

		&__editor {
			justify-content: center;
		}

		&__button-and-rating {
			justify-content: center;

			.btn-status {
				.text-status {
					display: inherit;
				}
			}
		}
	}
}
