.member {
	&-group {
		&__container {
			padding-bottom: 20px;
			border-bottom-left-radius: 16px;
			border-bottom-right-radius: 16px;
		}

		&__admin {
			padding: 52px 48px 10px 44px;
			h2 {
				font-family: 'Montserrat';
				font-style: normal;
				font-weight: 600;
				font-size: 18px;
				line-height: 32px;
				display: flex;
				align-items: center;
				letter-spacing: 0.75px;
				color: #2d2c42;
				margin-bottom: 40px;
			}
		}

		&__folower,
		&__all-member,
		&__friends {
			padding: 0px 48px 10px 44px;
			h2 {
				font-weight: 600;
				font-size: 18px;
				line-height: 32px;
				display: flex;
				align-items: center;
				letter-spacing: 0.75px;
				color: #2d2c42;
				margin-bottom: 20px;
			}
		}

		&__all-member {
			margin-top: 53px;
		}
	}

	&-item {
		min-height: 54px;
		margin-bottom: 20px;
		display: flex;
		flex-direction: row;
		align-items: center;
		justify-content: space-between;

		img {
			width: 54px;
			height: 54px;
			border-radius: 50%;
			margin-right: 14px;
			object-fit: cover;
		}

		&__info {
			display: flex;
			align-items: center;
		}

		&__text {
			span {
				font-weight: 600;
				line-height: 22px;
				letter-spacing: 0.25px;
				color: #2d2c42;
				display: -webkit-box;
				-webkit-line-clamp: 1;
				-webkit-box-orient: vertical;
				text-overflow: ellipsis;
				overflow: hidden;
			}
			p {
				margin-top: 5px;
				font-family: 'Montserrat';
				font-style: normal;
				font-weight: 600;
				font-size: 13px;
				line-height: 22px;
				display: flex;
				align-items: center;
				letter-spacing: 0.25px;
				color: #6e7191;
			}
		}
	}

	&-item__btn {
		width: 121px;
		height: 34px;
	}
}

.controlled-tab-tab-member {
	border-radius: 16px;
}

.bnt-add-friend {
	border: 1.22px solid #e0af7e;
	border-radius: 8px;
	img {
		width: 24px;
		height: 24px;
	}
	font-family: 'Montserrat';
	font-style: normal;
	font-weight: 600;
	font-size: 11px;
	line-height: 15px;
	letter-spacing: 0.457476px;
	color: #b38c64;
	margin-right: 12px;

	&.disabled {
		opacity: 0.7;
		cursor: wait;
	}
}

.btn-folow {
	padding: 9.75949px 14.6392px;
	position: static;
	width: 121px;
	height: 34.52px;
	background-color: #e0af7e;
	font-weight: 600;
	font-size: 11px;
	line-height: 15px;
	border-radius: 7.31962px;
	letter-spacing: 0.457476px;
	color: #fcfcfc;
	margin-right: 12px;

	&.disabled {
		opacity: 0.7;
		cursor: wait;
	}
}

.more-icon-btn-group {
	background: #f5e7d8;
	opacity: 0.4;
	border-radius: 50%;
	width: 38px;
	height: 38px;
}
.view-member-all {
	margin-top: 32px;
	background-color: #e0af7e;
	height: 56px;
	border-radius: 16px;
	margin-bottom: 20px;
	color: #fcfcfc;
	font-family: 'Montserrat';
	font-weight: 600;
	font-size: 15px;
	line-height: 24px;
	letter-spacing: 0.75px;
	width: 100%;
}

// responsive điện thoại
@media only screen and (max-width: 500px) {
	.member {
		&-group {
			&__admin {
				padding: 20px;

				h2 {
					margin-bottom: 15px;
					line-height: 1.5;
					font-size: medium;
				}
			}

			&__friends,
			&__folower,
			&__all-member {
				padding: 0 20px;

				h2 {
					line-height: 1.5;
					font-size: medium;
				}
			}

			&__all-member {
				margin-top: 0;
			}
		}
		&-item {
			flex-direction: column;
			align-items: flex-start;
			gap: 10px;

			&__btn {
				width: 100px;
			}
		}
	}

	.btn-folow {
		font-size: smaller;
	}

	.bnt-add-friend {
		font-size: smaller;
	}
}
