.modal-search-categories {
	&__close-button {
		display: none;
	}

	.modal-content {
		border-radius: 10px;
		max-width: 479px;
		width: 100%;
		margin: auto;
		max-height: 95vh;
		overflow-y: auto;

		.modal-body {
			padding: 20px 20px 36px 20px;

			h4 {
				margin-bottom: 8px;
				line-height: 32px;
				letter-spacing: 0.75px;
			}
			.search-field {
				border: none;
				height: 49px;
				&__icon {
					width: 24px;
					height: 24px;
					margin-right: 8px;
				}
				&__input::placeholder {
					color: #a0a3bd;
				}
			}
		}
	}

	.result-categories-list {
		padding: 20px 16px;

		.result-categories-item {
			padding: 16px 40px;
			font-weight: 600;
			border-radius: 10px;
			font-size: 18px;
			color: #4e4b66;
			height: 56px;
			line-height: 24px;
		}

		.result-categories-item:hover {
			background-color: antiquewhite;
			cursor: pointer;
		}
	}
}

// responsive điện thoại
@media only screen and (max-width: 500px) {
	.modal-search-categories {
		&__close-button {
			display: block;
			margin-bottom: 8px;
		}

		.modal-content {
			.modal-body {
				padding: 20px 10px;

				h4 {
					font-size: large;
				}
			}
		}

		.result-categories-list {
			padding: 10px 0;

			.result-categories-item {
				padding: 8px 10px;
				font-size: medium;
				height: 50px;
			}
		}
	}
}
