.search__container {
	display: flex;
	padding: 22px;
	background: #ffffff;
	border-radius: 12px;
	gap: 16px;
	.connect-button {
		background: #f5e7d8;
		border-radius: 12px;
		max-width: 127px;
		border: none;
		span {
			font-weight: 600;
			font-size: 16px;
			line-height: 24px;
			color: #b38c64;
		}
	}
}

// responsive điện thoại
@media only screen and (max-width: 500px) {
	.search__container {
		flex-direction: column;
		gap: 8px;

		.connect-button {
			padding: 0;

			span {
				font-size: small;
				line-height: 1;
			}
		}
	}
}
