@import 'src/scss/abstracts/_global.scss';

.newfeed {
	&__header {
		display: flex;
		justify-content: space-between;
		align-items: flex-end;
		margin-bottom: 24px;
		p {
			font-size: 2.25rem;
			line-height: 3rem;
			font-weight: 700;
			color: #2d2c42;
		}
		svg {
			cursor: pointer;
		}
	}

	&__create-post {
		padding: 24px;
		background-color: white;
		border-radius: 12px;
		margin-bottom: 24px;

		&__avatar-and-input {
			display: flex;
			justify-content: space-between;
			align-items: center;
			gap: 16px;
			margin-bottom: 20px;
			input {
				cursor: pointer;
			}
		}

		&__input {
			flex-grow: 1;
			height: 100%;
			border: none;
			outline: none;
			font-size: 0.94rem;
			line-height: 1.5rem;

			&::placeholder {
				color: #a0a3bd;
			}
		}

		&__options {
			display: flex;
			justify-content: space-between;
			align-items: center;

			&__item {
				display: flex;
				align-items: center;
				gap: 16px;
				cursor: pointer;
				span {
					font-weight: 600;
					color: #6e7191;
					font-size: 18px;
				}

				&__logo {
					svg {
						width: 24px;
						height: 24px;
					}
					&--book {
						path {
							fill: $primary;
						}
					}
					&--category {
						path {
							stroke: $primary;
						}
					}

					&--friend {
						path {
							stroke: $primary;
						}
					}
				}
			}
		}
	}

	.post-book__button-and-rating {
		.post-book__rating__group {
			min-width: 135px;
			.react-rating-container {
				span {
					margin: 0px 0.8px;
				}
			}
		}
	}

	.quote-card {
		margin: 0;
	}
}

@media only screen and (max-width: 1366px) {
	.newfeed {
		.create-post-modal-content {
			&__main {
				&__body {
					padding: 13px 10px 13px 24px;

					&__text-field-edit-wrapper {
						.rich-text-editor {
							margin-bottom: 10px;
						}
					}
				}

				&__header {
					padding: 12px 24px;
				}

				&__options-and-submit {
					padding: 17px;
				}
			}
		}
	}
}

@media only screen and (max-width: 1280px) {
	.newfeed {
		.create-post-modal-content__main {
			&__body {
				padding: 24px 10px 24px 24px;
			}

			&__header {
				padding: 16px 24px;
			}

			&__options-and-submit {
				padding: 24px;
			}
		}
	}
}

@media only screen and (max-width: 1024px) {
	.newfeed {
		.btn-status.btn {
			padding: 8px;
		}
		&__create-post {
			&__options__item {
				span {
					font-size: 16px;
				}
			}
		}
	}
}

@media only screen and (max-width: 913px) {
	.newfeed {
		.post-book__button-and-rating {
			.post-book__rating__group {
				min-width: 100px;
			}
		}
	}
}

@media only screen and (width: 820px) {
	.newfeed {
		.post-book__button-and-rating {
			gap: 4rem;
		}
		.post__container {
			.post-book__edit .linear-progress {
				max-width: 70%;
			}
			.btn-status.btn {
				padding: 8px;
			}
		}
	}
}

@media only screen and (max-width: 768px) {
	.newfeed {
		.btn-status.btn {
			padding: 8px;
		}
	}
}

// responsive điện thoại
@media only screen and (max-width: 500px) {
	.newfeed {
		&__header {
			padding-left: 10px;
		}

		&__create-post {
			&__options {
				&__item {
					gap: 8px;
					&__logo {
						svg {
							width: 16px;
							height: 16px;
						}
					}
					span {
						font-size: 13px;
					}
				}
			}
		}
	}
}

// màn siêu bé
@media only screen and (max-width: 331px) {
	.newfeed {
		&__create-post {
			&__options {
				&__item {
					gap: 5px;
					&__logo {
						svg {
							width: 14px;
							height: 14px;
						}
					}
					span {
						font-size: 12px;
					}
				}
			}
		}
	}
}
