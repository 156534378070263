@import 'src/scss/abstracts/_variables.scss';

.review {
	max-width: 58rem;
	margin: 0 auto;
	padding: 2.5rem 0;
	&__header {
		display: flex;
		align-items: center;
		gap: 2.25rem;
		h4 {
			margin: 0;
		}
	}

	&__items {
		border-radius: 16px;
		padding: 40px 40px 8px 40px;
		background-color: white;

		hr {
			border: #cecdcd 1px solid;
		}
	}

	.review__no-data {
		width: fit-content;
		margin: auto;
		padding: 40px 0;
	}
}

@media only screen and (max-width: 1025px) {
	.review {
		padding: 2.5rem;
	}
}

@media only screen and (max-width: 768px) {
	.review {
		.review__header {
			h4 {
				display: -webkit-box;
				-webkit-box-orient: vertical;
				-webkit-line-clamp: 3;
				text-overflow: ellipsis;
				overflow: hidden;
			}
		}
		.review-book-info {
			.review-book-info__name {
				font-size: 1.3rem;
			}
			.read-more {
				font-size: 0.9rem;
			}
		}
	}
}

// responsive điện thoại
@media only screen and (max-width: 500px) {
	.review {
		padding: 40px 10px;

		&__header {
			margin-left: 15px;
		}

		&__items {
			padding: 0;

			h4 {
				padding: 25px 0 0 20px;
			}
		}
	}
}
