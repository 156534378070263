.ranks__container {
	width: 55%;
	margin: auto;
	padding: 47px 0;
	font-family: 'Montserrat';
	font-style: normal;

	.tab-content {
		padding-bottom: 0;
		border-bottom-left-radius: 16px;
		border-bottom-right-radius: 16px;
	}

	&__header {
		display: flex;
		align-items: center;
		gap: 12px;
		margin-bottom: 50px;
	}

	&__main {
		.nav-tabs {
			padding: 0 40px;
			justify-content: space-around;
		}
		.nav-item {
			.nav-link {
				font-weight: 600;
				font-size: 20px;
				line-height: 32px;
				letter-spacing: 0.75px;
				// width: 100%;
			}
			.active {
				border-top-left-radius: 16px;
				border-top-right-radius: 16px;
			}
		}

		&__back {
			background-color: #eff0f7;
			padding: 8px;
			border-radius: 50%;
		}

		&__title {
			color: #2d2c42;
			font-weight: 700;
			font-size: 24px;
			line-height: 32px;
			letter-spacing: 1px;
		}
	}
}

@media only screen and (max-width: 1280px) {
	.ranks__container {
		width: 65%;
	}
}

@media only screen and (max-width: 1024px) {
	.ranks__container {
		width: 75%;
		.topbooks__container {
			&__main {
				margin-left: 0;
			}
		}
	}
}

@media only screen and (max-width: 820px) {
	.ranks__container {
		width: 90%;

		.topbooks__container__main {
			margin-left: 0;
			.author-book {
				margin-bottom: 1.2rem;
				.book-thumbnail {
					img {
						width: 100%;
						height: 100%;
					}
				}
				.author-book__info__book-name {
					.author-book__share {
						width: 35px;
						height: 35px;
					}
					.author-book__title {
						font-size: 1.2rem;
					}
				}
			}
		}
	}
}

@media only screen and (max-width: 768px) {
	.ranks__container {
		.topbooks__container {
			.topbooks__container__main {
				margin-left: 0;
			}
		}
	}
}

// responsive điện thoại
@media only screen and (max-width: 500px) {
	.ranks__container {
		width: 100%;
		padding: 40px 10px 20px 10px;

		&__header {
			margin-bottom: 20px;
			gap: 20px;
		}

		&__main {
			&__title {
				font-size: 1.25rem;
			}
			.nav-tabs {
				padding: 0;
			}
			.nav-item {
				.nav-link {
					font-size: medium;
					line-height: normal;
				}
			}
		}
	}
}
