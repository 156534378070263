.rich-text-editor {
	width: 100%;
	.m1mfvffo {
		vertical-align: baseline;
	}
}

.public-DraftEditorPlaceholder-root {
	height: 100%;
	.public-DraftEditorPlaceholder-inner {
		height: 100%;
		display: flex;
		align-items: center;
	}
}

.mnw6qvm {
	border-radius: 4px;
	.myz2dw1,
	.mtiwdxc {
		padding: 8px 12px;
		display: flex;
		align-items: center;
		img {
			width: 32px;
			height: 32px;
			object-fit: cover;
			border-radius: 50%;
		}
		span {
			margin-bottom: 0px;
			font-family: inherit;
			font-size: 14px;
			font-weight: 600;
		}
	}
}
