@import 'src/scss/abstracts/_variables.scss';

.form-check-custom {
	gap: 1.125em;
	border-radius: 12px;
	padding-left: 12px !important;
	cursor: pointer;
	&:hover {
		background-color: $secondary;
		.form-check-label--custom {
			color: $primary-dark;
		}
	}

	&--radio {
		cursor: pointer;
	}
}

.form-check .form-check-input {
	margin-left: 0 !important;
	flex-shrink: 0;
}

.form-check-label--custom {
	padding: 1em 0;
	cursor: pointer;
}
