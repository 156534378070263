@import 'src/scss/abstracts/_variables.scss';

.loading-indicator {
	width: 44px;
	height: 44px;
	display: flex;
	justify-content: center;
	align-items: center;
	margin: auto;
}

.loading-indicator:after {
	content: ' ';
	display: block;
	width: 40px;
	height: 40px;
	border-radius: 50%;
	border: 4px solid $primary;
	border-color: $primary transparent $primary transparent;
	animation: loading-indicator 1.2s linear infinite;
}

@keyframes loading-indicator {
	0% {
		transform: rotate(0deg);
	}
	100% {
		transform: rotate(360deg);
	}
}
