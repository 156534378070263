.modal-portal {
	position: fixed;
	top: 0;
	left: 0;
	z-index: 1050;
	width: 100vw;
	height: 100vh;
	background-color: rgba(0, 0, 0, 0.2);
	user-select: none;
}
