.myfriends__layout {
	max-width: 200px;
	width: 100%;
	background: #fbf7f2;
	border-radius: 16px;
	align-items: stretch;
	flex-direction: column;
	display: flex;
	overflow-x: hidden;
	.myfriends__star {
		display: flex;
		align-items: center;
		justify-content: center;
		gap: 7px;
		padding: 15px 11px 5px;
		.myfriends__star__name {
			font-weight: 600;
			font-size: 15px;
			line-height: 24px;
			letter-spacing: 0.75px;
			color: #2d2c42;
			text-align: center;
			cursor: pointer;
			display: -webkit-box;
			-webkit-box-orient: vertical;
			-webkit-line-clamp: 1;
			overflow: hidden;
			word-break: break-word;
		}
	}
	.myfriends__layout__img {
		border-top-left-radius: 16px;
		border-top-right-radius: 16px;
		width: 100%;
		height: 200px;
		object-fit: cover;
		cursor: pointer;
	}
	.myfriends__name {
		font-weight: 600;
		font-size: 15px;
		line-height: 24px;
		letter-spacing: 0.75px;
		color: #2d2c42;
		text-align: center;
		margin: 15px 0 7px 0;
	}
	.myfriends__button__container {
		padding: 0 11px 28px 11px;

		margin-top: auto;

		.myfriends__button {
			border-radius: 12px;
			padding: 9px 0;
			display: flex;
			align-items: center;
			justify-content: center;
			margin-top: 8px;
			.myfriends__button__content {
				font-weight: 600;
				font-size: 13px;
				line-height: 22px;
				letter-spacing: 0.25px;
				display: inline-block;
				overflow: hidden;
				white-space: nowrap;
				text-overflow: ellipsis;
			}
		}
	}

	.myFriend__mutualFriend {
		display: flex;
		align-items: center;
		justify-content: center;
		font-weight: 600;
		font-size: 11px;
		letter-spacing: 0.75px;
		color: #6e7191;
	}
}

// responsive điện thoại
@media only screen and (max-width: 500px) {
	.myfriends__layout {
		.myfriends__star {
			.myfriends__star__name {
				-webkit-line-clamp: 2;
			}
		}

		.myfriends__layout__img {
			height: 150px;
		}
	}
}
