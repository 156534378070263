@import 'src/scss/abstracts/_variables.scss';

.back-btn {
	cursor: pointer;
	width: 48px;
	aspect-ratio: 1/1;
	border-radius: 50%;
	background: #eff0f6;
	display: flex;
	justify-content: center;
	align-items: center;
	flex-shrink: 0;
}

// responsive điện thoại
@media only screen and (max-width: 500px) {
	.back-btn {
		width: 35px;
		svg {
			width: 24px;
			height: 24px;
		}
	}
}
