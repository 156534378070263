@import 'src/scss/abstracts/_variables.scss';

.filter-pane {
	background: $white;
	&__heading {
		display: flex;
		justify-content: space-between;
		align-items: center;
		padding: 40px 40px 20px;
	}

	hr {
		margin: 0;
		background: #d1d1d1;
	}

	&__title {
		font-size: 1.25rem;
		font-weight: 600;
		line-height: 1.5;
		text-transform: capitalize;
		margin: 0;
	}

	&__subtitle {
		font-size: 0.825rem;
		font-weight: 600;
		color: $ash-label;
		display: inline-block;
		margin-left: 0.75rem;
	}

	&__btn {
		width: 38px;
		aspect-ratio: 1;
		border-radius: 50%;
		background: #f5e7d8;
		&.dropdown-toggle {
			&::after {
				display: none;
			}
		}
	}

	&__content {
		.filter-options {
			padding: 0 2.5rem;
		}
	}
}

@media only screen and (max-width: 1024px) {
	.filter-pane__content {
		.filter-options {
			gap: 20px;
		}
	}
}

@media only screen and (max-width: 768px) {
	.filter-pane__content {
		.filter-options {
			padding: 0 85px;
		}
	}
}

@media only screen and (max-width: 820px) {
	.filter-pane__content {
		.filter-options {
			padding: 0;
			justify-content: center;
		}
	}
}

// responsive điện thoại
@media only screen and (max-width: 500px) {
	.filter-pane {
		&__heading {
			padding: 15px !important;
			justify-content: center;
			gap: 20px;
		}

		&__subtitle {
			margin-left: 0.2rem;
		}

		&__btn {
			width: 30px;
			svg {
				width: 15px;
				height: 15px;
			}
		}

		&__content {
			.filter-options {
				gap: 10px;
			}

			.form-check {
				padding-left: 0;
				gap: 0.3em;
			}
		}
	}
}
