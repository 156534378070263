@import 'src/scss/abstracts/_variables.scss';

.post-tab {
	min-height: 350px;
	margin-top: 0.5px;
	position: relative;
	background-color: $secondary;
	&.loading {
		background-color: $white;
	}

	&::before {
		content: '';
		display: block;
		width: 100%;
		height: 20px;
		background-color: white;
		position: absolute;
	}
}

@media only screen and (min-width: 500px) {
	.post-tab {
		.post__container {
			padding: 32px 40px;
			.share-target {
				padding: 28px 44px;
			}
		}
	}
}

// responsive điện thoại
// @media only screen and (max-width: 500px) {
// 	.post-tab {
// 		.post__container {
// 			padding: 10px 8px;
// 			.share-target {
// 				padding: inherit;
// 			}
// 		}
// 	}
// }
