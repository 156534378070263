.progress__circle__container {
	background: #ffffff;
	padding: 32px 18px;
	border-radius: 16px;
	font-family: 'Montserrat';
	font-style: normal;
	margin-bottom: 30px;

	.CircularProgressbar {
		display: block;
		max-width: 214px;
		margin: auto;
	}

	.CircularProgressbar-trail {
		stroke: #e3feff;
	}

	.CircularProgressbar-text {
		fill: #2d2c42;
		font-weight: 700;
		line-height: 48px;
	}
	&__title {
		text-align: center;
		font-weight: 600;
		font-size: 15px;
		line-height: 24px;
		text-align: center;
		letter-spacing: 0.75px;
		color: #4e4b66;
		margin-top: 30px;
	}
}

.progress__circle__title {
	font-weight: 600;
	font-size: 20px;
	line-height: 32px;
	display: flex;
	align-items: center;
	letter-spacing: 0.75px;
	color: #2d2c42;
	margin-bottom: 24px;
}

@media only screen and (max-width: 1024px) {
	.progress__circle__title {
		font-size: 18px;
	}
}
