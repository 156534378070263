.advertise {
	background-color: #febd59;
	position: fixed;
	left: 50%;
	bottom: 0;
	z-index: 1000;
	transform: translateX(-50%);
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding: 1rem;
	border-radius: 4px;
	color: #fff;
	gap: 4rem;
	transition: all 0.3s linear;
	.advertise-close {
		position: absolute;
		top: -0.75rem;
		right: -0.75rem;
		width: 1.5rem;
		height: 1.5rem;
		border-radius: 50%;
		background-color: #fff;
		display: flex;
		justify-content: center;
		align-items: center;
		transition: all 0.3s linear;
		box-shadow: 0 0 12px rgba(0 0 0/10%);
		> svg {
			width: 1rem;
			stroke: #000;
		}
		&:hover {
			background-color: red;
			> svg {
				stroke: #fff !important;
			}
		}
	}
	h1 {
		margin: 0;
		font-size: 1.1rem;
		font-weight: 800;
		white-space: nowrap;
		line-height: 0;
		> span {
			font-weight: 500;
		}
	}
	.btn-goto {
		white-space: nowrap;
		padding: 0.875rem 1.25rem;
		border-radius: 12px;
		border-width: 2px;
		background-color: #fff;
		font-weight: 700;
		transition: all 0.3s linear;
		color: #313131;
		font-size: 0.875rem;
		&:hover {
			background-color: #ff9900;
			color: #fff;
		}
	}
}

@media only screen and (max-width: 768px) {
	.advertise {
		gap: 1rem;
	}
}

// responsive điện thoại
@media only screen and (max-width: 500px) {
	.advertise {
		left: 1.5rem !important;
		right: 1.55rem !important;
		transform: unset !important;
		h1 {
			font-size: 1.5rem;
			> span {
				display: none;
			}
			> span:nth-child(3) {
				font-size: 0.75rem;
				display: block;
				margin-top: 1.5rem;
				text-transform: capitalize;
			}
		}
	}
}
