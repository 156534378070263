@import 'src/scss/abstracts/_global.scss';

.event-and-rank-bar {
	display: flex;
	flex-direction: column;
	gap: 60px;
	padding-top: 24px;

	&__block {
		display: block;

		&__title {
			font-size: 1.25rem;
			font-weight: 600;
			margin-bottom: 24px;
			color: #2d2c42;
		}

		.event-and-rank-bar__block.top__user__ranks {
			.top__user__ranks__one__title,
			.top__user__ranks__two__title {
				width: 92%;
				margin: auto;
				padding: 14px 0;
				p {
					font-size: 20px;
					line-height: 1.25;
					display: -webkit-box;
					-webkit-box-orient: vertical;
					-webkit-line-clamp: 2;
					overflow: hidden;
					text-overflow: ellipsis;
					word-break: break-word;
				}
			}
		}
		.top__user__ranks__one__title {
			.number__books {
				font-size: 25px !important;
			}
		}
		.top__user__ranks__two__title {
			.number__books {
				font-size: 20px !important;
			}
		}
	}

	&__content {
		display: flex;
		padding: 20px 16px;
		background-color: white;
		border-radius: 16px;
		position: relative;
		justify-content: center;
		cursor: pointer;
		img {
			width: 100%;
		}

		.top__user__ranks {
			transform: scale(0.5);
			position: absolute;
			left: 24px;
			top: 10px;
			&__one {
				position: relative;
				bottom: 40px;
			}
			&__two {
				position: relative;
				top: 50px;
			}
		}
	}

	&__welcome {
		border-radius: 16px;
		overflow: hidden;
		cursor: pointer;

		&__image {
			width: 100%;
			object-fit: cover;
		}
	}
}

.event-bar__name {
	font-size: 0.94rem;
	line-height: 1.5rem;
	margin-bottom: 20px;
	color: #2d2c42;
	letter-spacing: 0.75px;
	font-weight: 700;
}

.event-bar__join-event {
	width: 100%;
	margin-top: 20px;
	padding: 9px;
	border: #e0af7e 2px solid;
	border-radius: 8px;
	width: 100%;
	color: #b38c64;
	font-size: 0.81rem;
	line-height: 1.37rem;
	font-weight: 500;
}

@media only screen and (max-width: 1366px) {
	.event-and-rank-bar__content {
		.top__user__ranks {
			transform: scale(0.45);
			top: 0;
		}
	}
}

@media only screen and (max-width: 1096px) {
	.event-and-rank-bar__block__title {
		font-size: 18px;
	}
	.event-and-rank-bar__content {
		.top__user__ranks {
			transform: scale(0.25);
			left: 16px;
			top: -38px;
			&__two {
				top: 100px;
			}
		}
	}
}
