@import 'src/scss/abstracts/_variables.scss';

.review-book-info {
	display: flex;
	line-height: 1.5;
	align-items: flex-start;
	gap: 2.25rem;
	margin: 3rem 0 3.75rem;

	&__image {
		flex-shrink: 0;
	}

	&__btn {
		&.btn {
			width: 200px;
			margin-top: 1.5em;
		}
	}

	&__content {
		display: flex;
		flex-direction: column;
	}

	&__name-author {
		margin-bottom: 8px;
	}

	&__name {
		font-size: 1.5rem;
		font-weight: 700;
		color: $ash;
		display: -webkit-box;
		-webkit-box-orient: vertical;
		-webkit-line-clamp: 2;
		text-overflow: ellipsis;
		overflow: hidden;
		line-height: 2rem;
	}

	&__author {
		font-size: 1rem;
		font-weight: 400;
	}

	&__check {
		width: 20px;
		height: 20px;
		margin-left: 9px;
		fill: $success;
		path {
			color: $white;
		}
	}

	&__stars {
		display: flex;
		align-items: center;
		gap: 0.56rem;
		margin-bottom: 18px;
		span {
			font-size: 0.825rem;
			font-weight: 600;
			color: $ash-placeholder;
		}
	}

	&__action {
		display: flex;
		gap: 1.875rem;
		margin-top: auto;
		background: $white;
		padding: 0.875rem 1.25rem;
		max-width: 200px;
		border-radius: 12px;
	}

	&__share {
		&__text {
			margin-left: 1rem;
			display: inline-block;
			font-size: 0.825rem;
			font-weight: 600;
			color: $ash-label;
		}
	}
}

@media only screen and (max-width: 768px) {
	.review-book-info {
		margin: 1.5rem 0 0.75rem 0;
		gap: 1rem;
	}

	.review {
		&__items {
			margin-top: 1.5rem;
		}
	}
}

// responsive điện thoại
@media only screen and (max-width: 500px) {
	.review-book-info {
		&__stars {
			flex-direction: column;
			align-items: inherit;
		}
	}
}
