.comment-editor {
	display: flex;
	gap: 8px;
	.rich-text-editor {
		padding: 11px 16px;
		background: #fefdfa;
		border: 1px solid #d9dbe9;
		border-radius: 15px;
		font-size: 13px;
		overflow: hidden;
		color: #2d2c42;
		line-height: 22px;
		.public-DraftEditorPlaceholder-inner {
			color: #a0a3bd;
			font-size: 13px;
			line-height: 22px;
		}
	}
}

.comment-editor__avatar {
	flex-shrink: 0;
	padding-top: 5px;
	img {
		width: 100%;
	}
}
