@import 'src/scss/abstracts/_variables.scss';

.setting-more {
	position: relative;
	&__btn {
		width: 2.4rem;
		height: 2.4rem;
		padding: 0.5em;
		margin-bottom: 1em;
		border-radius: 50%;
		background-color: rgba($white-off, 1);
		border: 2px solid $ash-line;
		display: flex;
		align-items: center;
		justify-content: space-around;
	}

	&__dot {
		width: 5px;
		height: 5px;
		border-radius: 50%;
		display: inline-block;
		background-color: $ash-placeholder;
	}

	&__list {
		width: 320px;
		max-width: 320px;
		position: absolute;
		top: 2.8rem;
		left: 0;
		transform: translateX(-40%);
		border-radius: 16px;
		background-color: $white-off;
		box-shadow: 0px 10px 15px -3px rgba(0, 0, 0, 0.1);
		transition: all 0.3s ease-in;
		z-index: 600;
	}
	&__item {
		padding: 1.25em;
		font-size: 1rem;
		font-weight: 600;
		line-height: 1.5;
		color: $ash-body;
	}

	&__icon {
		width: 22px;
		height: 22px;
		margin-right: 1.25em;
	}
}

// responsive điện thoại
@media only screen and (max-width: 500px) {
	.setting-more {
		&__list {
			width: 250px;
		}
	}
}
