@import 'src/scss/abstracts/_variables.scss';

.quote-card {
	padding: 40px 40px 32px;
	background-color: $secondary;
	font-size: 1rem;
	border-radius: 1rem;
	margin-bottom: 1.25em;

	&__quote-content {
		width: 100%;
		display: flex;
		flex-direction: column;
		align-items: center;
		gap: 16px;
		p {
			color: $ash-body;
			line-height: 1.5rem;
			text-align: center;
		}
		&__quote {
			max-width: 75%;
			word-break: break-word;
			font-size: 15px;
			font-weight: 600;
		}
		&__author-and-book {
			font-size: 13px;
			font-weight: 400;
		}
	}

	&__author {
		display: flex;
		justify-content: center;
		gap: 1rem;
		margin: 24px 0 16px;

		&__avatar {
			flex-shrink: 0;
			display: flex;
			justify-content: center;
			align-items: center;
		}

		&__detail {
			display: flex;
			flex-direction: column;
			justify-content: space-between;
			&__text {
				font-size: 13px;
				line-height: 22px;
				color: $ash-label;
			}
			&__name {
				font-size: 16px;
				font-weight: 600;
				line-height: 24px;
				color: $ash;
				letter-spacing: 0.75px;
			}
		}
	}

	&__categories-in-detail {
		margin: 32px 0 16px;
		.quote-card__categories-badge {
			&.badge {
				font-size: 13px;
				padding: 12px 16px;
				border-radius: 12px;
				line-height: 19px;
				margin: 0 12px 12px 0;
			}
		}
	}

	&__hashtag {
		color: $ash-label;
		letter-spacing: 0.75px;
		font-weight: 600;
		text-overflow: ellipsis;
		overflow: hidden;
	}

	&__author {
		&__avatar {
			&:hover {
				cursor: pointer;
			}
		}
	}

	&__detail {
		&__name {
			&:hover {
				cursor: pointer;
			}
		}
	}
}

.quote-footer {
	display: flex;
	align-items: center;
	justify-content: space-between;
	&__left {
		width: 50%;
		display: flex;
		flex-wrap: wrap;
		gap: 0.75em;
	}

	&__badge {
		font-size: 13px;
		padding: 12px 16px;
		border-radius: 12px;
		line-height: 19px;
		max-width: 160px;
		overflow: hidden;
		text-overflow: ellipsis;
	}

	&__right {
		display: flex;
		justify-content: flex-end;
	}
}

@media only screen and (max-width: 768px) {
	.quote-card {
		padding: 40px 28px 32px;
	}
}

// responsive điện thoại
@media only screen and (max-width: 500px) {
	.quote-card {
		padding: 15px 10px;

		&__quote-content {
			&__quote {
				max-width: inherit;
				font-size: small;
			}

			&__author-and-book {
				font-size: smaller;
			}
		}

		&__author {
			margin: 15px 0;
			&__detail {
				&__text {
					font-size: smaller;
				}
				&__name {
					font-size: small;
				}
			}
		}

		&__categories-in-detail {
			margin: 16px 0 8px;
			.quote-card__categories-badge {
				&.badge {
					font-size: smaller;
					padding: 6px 9px;
					border-radius: 12px;
					line-height: 19px;
					margin: 0 10px 10px 0;
				}
			}
		}
	}

	.quote-footer {
		flex-direction: column;

		&__left {
			width: inherit;
			gap: 0.3em;
		}

		&__badge {
			font-size: smaller;
			padding: 8px 10px;
			border-radius: 8px;
			line-height: normal;
		}
	}
}
