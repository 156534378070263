@import 'src/scss/abstracts/_variables.scss';

.book-review {
	border-radius: 16px;
	overflow: hidden;

	&__tabs {
		justify-content: center;
		align-items: center;
		gap: 13.5rem;
		padding-top: 0.25rem;

		&.nav-tabs {
			.nav-link {
				font-size: 1.5rem;
				font-weight: 700;
				&.active {
					font-size: 2.25rem;
					line-height: 1.3;
				}
			}
		}
	}

	.blank-content {
		padding-left: 2.5rem;
	}

	.quote-card {
		margin: 2.5rem;
	}

	hr {
		background: #f1f1f5;
		height: 2px !important;
		opacity: 1 !important;
	}
}

.quotes-tab {
	h5 {
		text-align: center;
		margin: 0 auto;
		padding: 40px 0;
	}
}

@media only screen and (max-width: 1024px) {
	.book-review {
		&__tabs {
			&.nav-tabs {
				.nav-link {
					font-size: 1.25rem;
					&.active {
						font-size: 1.75rem;
					}
				}
			}
		}
	}
}

@media only screen and (max-width: 820px) {
	.book-review {
		& h5 {
			padding-bottom: 20px;
		}
	}
}

// responsive điện thoại
@media only screen and (max-width: 500px) {
	.book-review {
		&__tabs {
			gap: inherit;
			justify-content: space-evenly;

			&.nav-tabs {
				.nav-link {
					font-size: 1rem;
					&.active {
						font-size: 1.25rem;
					}
				}
			}
		}

		.quote-card {
			margin: 15px;
		}
	}

	.quotes-tab {
		h5 {
			padding: 30px 0;
		}
	}
}
