@import 'src/scss/abstracts/_variables.scss';

.register {
	&__container-modal {
		background-color: rgba(97, 97, 97, 0.5);
		position: fixed;
		width: 100%;
		height: 100vh;
		z-index: 1;
		top: 0;
		left: 0;
		display: flex;
		align-items: center;
		justify-content: center;
	}

	&__container {
		background-color: white;
		box-shadow: 0px 0px 32px rgba(0, 0, 0, 0.04);
		max-width: 1440px !important;
		margin: auto;
		height: 100vh;
		overflow: auto;
	}

	&__body {
		display: flex;
		justify-content: center;
		padding-top: 9vh;

		&-img {
			padding-left: 30px;
			img {
				width: 100%;
				max-width: 603px;
				aspect-ratio: 67/66;
			}
		}
	}

	&__name {
		display: grid;
		grid-template-columns: 1fr 1fr;
		column-gap: 8px;
	}

	&__box {
		padding: 38px 16px 38px 16px;
		width: 400px;
		max-width: 400px;
		background: #fcfcfc;
		box-shadow: 0px 0px 32px rgba(0, 0, 0, 0.12);
		border-radius: 12px;
	}

	&__form {
		display: flex;
		justify-content: center;
		padding: 0 30px;

		&__wrapper {
			display: flex;
			flex-direction: column;
			align-items: center;
			width: 480px;
			max-width: 480px;
			margin-left: auto;
			padding: 80px;
			border-top-right-radius: 24px;
			border-top-left-radius: 24px;
			background-color: #fff;
			text-align: right;
			height: 100vh;
		}

		&-title {
			font-family: 'Montserrat';
			font-style: normal;
			font-weight: 600;
			font-size: 20px;
			line-height: 32px;
			display: flex;
			align-items: center;
			text-align: center;
			letter-spacing: 0.75px;
			color: #2d2c42;
			span {
				width: 100%;
			}
		}

		&__logo {
			width: 200px;
			height: auto;
			margin-bottom: 80px;
		}

		&__field {
			margin-top: 19px;
			border-radius: 12px;
			position: relative;
			background-color: #eff0f6;
			display: flex;
			justify-content: space-between;
			align-items: center;
			height: 56px;
			padding: 0 12px;
			&.error {
				border: $red 1px solid;
			}
			.error--text {
				display: none;
				&.show {
					display: block;
					margin-left: 8px;
				}
			}
		}

		&__link {
			display: flex;
			justify-content: center;
			align-items: center;
			height: 45px;
			margin-top: 12px;
			color: #0576f0;
		}

		&__input {
			background-color: #eff0f6;
			border: none;
			line-height: 22px;
			border: none;
			outline: none;
			padding-left: 8px;
			&::placeholder {
				color: #a0a3bd;
				font-family: 'Montserrat';
				font-style: normal;
				font-weight: 500;
				font-size: 13px;
				line-height: 22px;
				letter-spacing: 0.25;
			}

			&-name {
				background-color: #eff0f6;
				border: none;
				line-height: 22px;
				border: none;
				outline: none;
				padding-left: 8px;
				&::placeholder {
					color: #a0a3bd;
					font-family: 'Montserrat';
					font-style: normal;
					font-weight: 500;
					font-size: 13px;
					line-height: 22px;
					letter-spacing: 0.25;
				}
			}
		}

		&__btn {
			display: block;
			width: 100%;
			height: 54px;
			margin-top: 28px;
			background-color: #18bb0c;
			border: none;
			border-radius: 12px;
			line-height: 1.5;
			color: #fcfcfc;
			font-size: 18px;
		}
	}

	&__register-box__title {
		font-family: 'Montserrat';
		font-style: normal;
		font-weight: 600;
		font-size: 20px;
		line-height: 32px;
		display: flex;
		align-items: center;
		text-align: center;
		letter-spacing: 0.75px;
		color: #2d2c42;
	}
}

.error-message-name {
	width: 160px !important;
	position: absolute;
	top: 3px;
	left: 0px;
}

#tooltip {
	background-color: #18bb0c;
}

.error-message {
	margin-left: -50px;
}

#registerTip {
	border-radius: 12px;

	color: #fcfcfc;
	font-family: 500;
	letter-spacing: 0.25px;
	align-items: center;
}

@media screen and (max-width: 1024px) {
	.register__container {
		.register__header {
			img {
				margin-left: 200px;
			}
		}
		.register__body {
			align-items: center;
		}
	}
}

@media screen and (max-width: 820px) {
	.register {
		&__body {
			padding-top: 20px;

			&-img {
				display: none;
			}
		}

		&__form {
			display: flex;
			align-items: center;
			justify-content: center;
		}

		&__box {
			width: 85%;
		}
	}
}

@media screen and (max-width: 500px) {
	.register {
		&__box {
			width: 300px;
		}

		&__form {
			&-title {
				font-size: medium;
				line-height: 24px;
			}
		}

		&__name {
			grid-template-columns: 1fr;
		}
	}
}

@media screen and (max-width: 366px) {
	.register {
		&__form {
			&__field {
				height: 40px;
			}

			&__btn {
				height: 40px;
				font-size: small;
			}
		}
	}
}
