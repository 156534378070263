.show-time {
	position: relative;
	.show-time-modal {
		opacity: 0;
		position: absolute;
		top: 25px;
		right: 100vw;
		width: max-content;
		background-color: black;
		border-radius: 5px;
		color: white;
		padding: 3px 7px;
		font-size: 12px;
		transition: opacity 0.2s ease;
	}
	&:not(.not-event) > span {
		&:hover {
			cursor: pointer;
			text-decoration: underline;
		}
	}
}

.show-time > span:hover {
	+ .show-time-modal {
		transition: opacity 0.2s ease;
		transition-delay: 0.2s;
		opacity: 0.7;
		right: inherit;
		z-index: 2;
		transform: translate(-50%, 0);
		left: 50%;
	}
}
