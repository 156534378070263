@import 'src/scss/abstracts/_variables.scss';

.main-category-detail {
	display: flex;
	flex-direction: column;
	gap: 40px;
	&__header {
		display: flex;
		align-items: center;
		&__name {
			margin-bottom: 0;
			margin-left: 20px;
			font-weight: 600;
			font-size: 1.25em;
			line-height: 1.5;
		}

		.btn-like {
			margin-left: auto;
			&:hover {
				.heart-icon {
					color: inherit;
				}
			}

			&.active {
				.heart-icon {
					svg {
						fill: $primary;
						path {
							stroke: none;
						}
					}
				}
			}

			&.btn-outline-primary {
				&.active {
					background-color: unset;
					color: $primary-dark;
				}
			}
		}

		.heart-icon {
			display: inline-block;
			margin-right: 0.825rem;
			color: $primary;
		}
	}

	&__intro {
		padding: 32px 40px 28px 40px;
		border-radius: 16px;
		background: $white;
		font-size: 15px;
		font-weight: 600;
		line-height: 24px;
		color: $ash-body;
		text-align: justify;
		letter-spacing: 0.75px;
	}

	&__container {
		padding: 32px 40px 60px;
		background: $white;
		border-radius: 16px;
		.get-more-books-btn {
			margin: 24px 0 0 auto;
			display: flex;
			gap: 16px;
			align-items: center;
		}
		.loading-indicator {
			margin-top: 20px;
		}
	}

	&__allbook {
		margin-top: 2.5rem;

		h3 {
			text-align: left;
			font-weight: 600;
			font-size: 1.25em;
			line-height: 1.5;
			margin-bottom: 1.5rem;
		}

		h4 {
			text-align: center;
			font-weight: 500;
			font-size: 18px;
			line-height: 1.5;
		}
	}

	.filter-pane {
		border-radius: 16px;
		background: none;
		&__heading {
			background: white;
			border-top-left-radius: 16px;
			border-top-right-radius: 16px;
			padding: 32px 40px 24px;
			h4 {
				margin-bottom: 0;
			}
		}
	}

	.book-thumbnail {
		border: 1px solid $ash-line;
		img {
			transition: transform 0.2s ease-out;
			&:hover {
				transform: scale(1.1);
				transition: all 0.2s ease-out;
			}
		}
	}

	&__modal {
		.modal-content {
			max-width: 352px;
			margin: auto;
			border-radius: 8px;
		}

		&__content {
			padding: 48px;
		}

		&__title {
			margin-bottom: 8px;
			font-size: 18px;
			font-weight: 600;
			line-height: 24px;
			padding-left: 12px;
		}

		&__btn {
			width: 100%;
			margin-top: 1.5rem;
		}

		&__option__group {
			margin-bottom: 1.75rem;
			h4 {
				font-size: 18px;
			}
		}

		.form-check-label {
			font-size: 16px !important;
		}

		.btn-confirm {
			width: 100%;
		}
	}

	&__posts {
		position: relative;
		&::before {
			content: '';
			display: block;
			width: 100%;
			height: 20px;
			background-color: white;
			position: absolute;
		}
	}
}

// responsive điện thoại
@media only screen and (max-width: 500px) {
	.main-category-detail {
		&__modal {
			&__content {
				padding: 30px;
			}
		}

		&__container {
			padding: 25px 20px 28px;
		}

		&__header {
			.back-btn {
				margin-left: 15px;
			}

			&__name {
				margin-left: 10px;
			}

			.heart-icon {
				margin-right: 0.5rem;
				svg {
					width: 20px;
					height: 20px;
				}
			}
		}
	}
}
