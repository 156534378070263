@import 'src/scss/abstracts/_variables.scss';

.statistic {
	max-width: 280px;
}

.statistic-title {
	margin-bottom: 0.4em;
	font-size: 1.25rem;
	font-weight: 600;
	line-height: 1.5;

	&.custom {
		padding: 0em;
	}
}

@media only screen and (max-width: 820px) {
	.statistic-title {
		display: none;
	}
}

@media only screen and (max-width: 1024px) {
	.statistic-title.custom {
		font-size: 17px;
		margin-bottom: 10px;
	}
	.sidebar-shelves {
		.statistic {
			margin-top: 30px;
		}
	}
}
