@import 'src/scss/abstracts/_variables.scss';

.post-edit-book {
	display: flex;

	.book-thumbnail {
		flex-shrink: 0;
		margin-right: 1.25rem;
	}

	&__informations {
		display: flex;
		flex-direction: column;
		justify-content: space-between;
		padding: 0.875rem 0;
		flex-grow: 1;
	}

	.linear-progress {
		max-width: 255px;
	}

	&__name {
		font-weight: 600;
		font-size: 1.25rem;
		line-height: 2rem;
		color: #2d2c42;
		display: -webkit-box;
		-webkit-box-orient: vertical;
		-webkit-line-clamp: 2;
		text-overflow: ellipsis;
		overflow: hidden;
	}

	&__author {
		font-size: 0.81rem;
		line-height: 1.375rem;
		color: #6e7191;
	}

	&__action {
		display: flex;
		gap: 1.25rem;
	}

	&__rating {
		display: flex;
		flex-direction: column;
		align-items: flex-start;
	}

	&__rating__number {
		font-weight: 600;
		font-size: 11px;
		color: #a0a3bd;
		margin-top: 0.5rem;
	}

	&__edit {
		margin-top: 1.5rem;
	}

	&__editor {
		display: flex;
		flex-wrap: wrap;
		gap: 1rem;
		align-items: center;
		margin-top: 1.5rem;

		input + span {
			font-size: 18px;
		}
	}

	&__input {
		outline: none;
		min-width: 20px;
		max-width: 50px;
		border-bottom: 1px solid $ash-line;
		font-size: 18px;
	}

	&__message {
		flex-grow: 1;
		font-size: 0.875rem;
		font-weight: 600;
		color: $red;
	}

	small {
		margin: 10px 0;
		display: inline-block;
	}
}

// responsive điện thoại
@media only screen and (max-width: 500px) {
	.post-edit-book {
		&__edit {
			margin-top: 1rem;
		}

		&__message {
			font-size: 14px;
			text-align: inherit;
		}
	}
}
