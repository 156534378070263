.group-settings__container {
	background-color: #fcfcfc;
	border-radius: 24px;
	margin-top: 32px;
	.form-field-group {
		margin-bottom: 39px;
		label {
			margin-bottom: 23px;
			margin-top: 0px;
			font-weight: 600;
			font-size: 16px;
		}
	}
	.form-field-wrapper {
		margin-top: 23px;
	}
	.select-box {
		width: 100%;
		.select-box__value {
			width: 100%;
		}
	}
	.form-button {
		width: 100%;
		background-color: #e0af7e;
		height: 56px;
		display: flex;
		justify-content: center;
		align-items: center;
		border-radius: 12px;
		&.disabled-btn {
			background-color: #d9dbe9;
			button {
				color: #a0a3bd;
				font-weight: 600;
			}
		}
	}

	.input-hashtag {
		&__label {
			margin-bottom: 23px;
			font-size: 16px;
		}
	}
}
.group-settings__form {
	padding: 0 24px 32px 24px;
	.input-form-group__label {
		font-size: 16px;
		margin-bottom: 23px;
	}
}
.group-settings__title {
	padding: 24px 24px 0 24px;
	display: flex;
	align-items: center;

	h2 {
		flex: 1;
		text-align: center;
		margin-right: 50px;
		font-family: 'Montserrat';
		font-style: normal;
		font-weight: 600;
		font-size: 20px;
		line-height: 32px;
		letter-spacing: 0.75px;
		color: #2d2c42;
	}
	button {
		width: 48px;
		height: 48px;
		background-color: #eff0f7;
		border-radius: 50%;
		svg {
			width: 24px;
			height: 24px;
		}
	}
}
.group-settings__title-content {
	margin-top: 48px;
	h3 {
		font-family: 'Montserrat';
		font-style: normal;
		font-weight: 600;
		font-size: 20px;
		line-height: 32px;
		display: flex;
		align-items: center;
		letter-spacing: 0.75px;

		color: #2d2c42;
	}
}
.group-manage__title__content {
	margin-bottom: 23px;
	h3 {
		font-family: 'Montserrat';
		font-style: normal;
		font-weight: 600;
		font-size: 20px;
		line-height: 32px;
		display: flex;
		align-items: center;
		letter-spacing: 0.75px;
		color: #2d2c42;
	}
}
