@import 'src/scss/abstracts/_global.scss';

.header {
	display: flex;
	justify-content: space-between;
	align-items: center;
	background-color: $white;
	padding: 16px 40px;
	position: fixed;
	top: 0;
	width: 100vw;
	z-index: 1000;
	max-width: 1440px;
	box-shadow: 0px 4px 28px rgba(0, 0, 0, 0.1);
	&.low-zIndex {
		z-index: 200;
	}

	&__left {
		display: flex;
		align-items: flex-end;
		gap: 6px;
		margin-right: 4px;

		.header-logo-small {
			display: none;
		}

		.header-badge {
			border: #0576f0 1px solid;
			padding: 8px;
			border-radius: 6px 8px;
			font-size: 11px;
			color: #0576f0;
			font-weight: 500;
			white-space: nowrap;
		}
	}

	&__logo {
		height: 42px;
	}

	&__center {
		display: flex;
		justify-content: space-between;
		flex-grow: 1;
		gap: 10px;
	}

	&__search {
		::placeholder {
			font-weight: 500;
			color: #a0a3bd;
			letter-spacing: 0.75px;
			font-size: 15px;
		}
		height: 64px;
		width: 380px;
		padding: 0 1.5rem;
		border-radius: 16px;
		display: flex;
		align-items: center;
		background-color: #eff0f7;
		position: relative;
		cursor: text;
		margin-left: 7%;

		&__icon {
			width: 20px;
			height: 20px;
			margin-right: 20px;
		}

		&__input {
			flex-grow: 1;
			background-color: inherit;
			border: none;
			outline: none;
			font-size: 15px;
			&:focus {
				border: none;
			}
		}
	}

	&__nav {
		display: flex;
		justify-content: space-between;
		background-color: #eff0f7;
		height: 64px;
		border-radius: 12px;
		padding: 0px 20px;
		max-width: 489px;
		margin: 0 auto;
		width: 80%;
		transition: all 0.4s ease;
		overflow: hidden;
		&.hidden {
			max-width: 0px;
			width: 0%;
			padding: 0;
			margin-left: 0;
		}

		&.show {
			overflow: unset;
		}

		.header__nav__item {
			position: relative;
			display: flex;
			justify-content: center;
			align-items: center;

			&.active {
				.header__nav__icon {
					fill: $primary;
					path {
						stroke: $primary;
						fill: $primary;
					}
				}
			}

			&--hover {
				position: absolute;
				top: 100%;
				padding: 10px 7px;
				background-color: rgba(0, 0, 0, 0.7);
				border-radius: 4px;
				color: #fcfcfc;
				z-index: 2000;
				width: fit-content;
				white-space: nowrap;
				font-size: 12px;
				letter-spacing: 0.2px;
				cursor: default;
				user-select: none;
				opacity: 0;
				transition: all 0.2s ease;
			}

			&:hover .header__nav__item--hover {
				opacity: 1;
				transition: all 0.2s ease;
				&:hover {
					opacity: 0;
				}
				&.hide {
					opacity: 0;
				}
			}
		}

		.header__nav__link {
			cursor: pointer;
			svg {
				height: 36px;
			}

			&.header__notify__icon--realtime__active {
				position: relative;
				&::after {
					position: absolute;
					top: 0px;
					right: -1px;
					content: '';
					display: inline-block;
					width: 14px;
					aspect-ratio: 1/1;
					border-radius: 50%;
					background-color: $primary;
				}
			}
		}
	}

	&__userInfo {
		display: flex;
		align-items: center;
		cursor: pointer;
		margin-left: 10px;
		& svg {
			width: 21px;
			height: 21px;
		}
		#arrow-down-icon {
			position: relative;
			top: 8px;
			left: 5px;
			& svg {
				transform: scale(0.8);
			}
		}
	}

	&__avatar {
		border-radius: 50%;
		width: 60px;
		height: 60px;
		overflow: hidden;
		img {
			width: 100%;
			height: 100%;
			object-fit: cover;
		}
	}

	&__option-info {
		visibility: hidden;
		position: absolute;
		background-color: #fff;
		box-shadow: 0px 0px 32px rgba(0, 0, 0, 0.12);
		padding: 13px 10px;
		border-radius: 12px;
		width: fit-content;
		right: 8px;
		top: 90%;
		display: flex;
		flex-direction: column;
		z-index: 2001;
		li {
			font-size: 15px;
			padding: 6px;
			border-radius: 8px;
			&:hover {
				background-color: $grey-border;
				cursor: pointer;
			}
		}
		&.show {
			visibility: visible;
		}

		&.avatar-edit {
			right: unset;
			bottom: unset;
			margin-top: 5px;
			left: 50%;
			transform: translate(-50%, 0);
			white-space: nowrap;
			z-index: inherit;

			svg {
				width: 20px;
				height: 20px;
				margin-right: 5px;
			}
		}

		&.cover-edit {
			right: 0;
			bottom: unset;
			margin-top: 5px;
			white-space: nowrap;
			z-index: inherit;

			svg {
				width: 20px;
				height: 20px;
				margin-right: 5px;
			}
		}
	}
}

@media only screen and (max-width: 1024px) {
	.header {
		padding: 16px;

		&__logo {
			height: 36px;
		}

		&__search {
			&__input {
				font-size: 14px;
			}

			&__icon {
				width: 16px;
				height: 16px;
			}
		}
	}
}

@media only screen and (max-width: 820px) {
	.header {
		padding: 16px 28px;
		&__left {
			.header-logo-small {
				display: block;
			}

			.header-logo-big {
				display: none;
			}
		}

		&__search {
			display: none;
		}
	}
}

@media only screen and (max-width: 1115px) {
	.header__search {
		&__icon {
			margin-right: 10px;
		}
	}
}

@media only screen and (max-width: 930px) {
	.header__search {
		padding-left: 1rem;
		margin-left: 0%;
		width: 300px;
	}

	.header__left {
		gap: 0;
	}
}

@media only screen and (max-width: 768px) {
	.header__left {
		gap: 4px;
	}
}

// responsive điện thoại
@media only screen and (max-width: 500px) {
	.header {
		padding: 8px 10px;
		&__left {
			.header-logo-big,
			.header-logo-small {
				display: none;
			}

			.header-badge {
				font-size: 9px;
				padding: 4px 6px;
				border-radius: 4px;
			}
		}

		&__center {
			gap: 5px;
			justify-content: flex-start;
			.header-search-small {
				margin-left: 0px;
			}

			.header__nav {
				padding: 0px;
				height: 50px;
				justify-content: space-evenly;
				&__item {
					min-width: none;
				}
				&__link {
					svg {
						width: 20px;
						height: 20px;
					}
				}

				.header__nav__link.header__notify__icon--realtime__active {
					&::after {
						width: 10px;
					}
				}
			}
		}

		&__avatar {
			width: 40px;
			height: 40px;
		}

		&__option-info {
			padding: 8px;

			svg {
				width: 15px;
				height: 15px;
			}

			li {
				font-size: smaller;
			}

			&.avatar-edit {
				svg {
					width: 16px;
					height: 16px;
					margin-right: 5px;
				}
			}

			&.cover-edit {
				li {
					font-size: 14px;
				}
			}
		}
	}
}
