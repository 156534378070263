.book__author__charts {
	padding: 64px 50px 40px;

	.book__author__charts__header {
		display: flex;
		align-items: center;
		justify-content: center;
		gap: 22px;
	}

	.notification__main {
		&__title {
			font-weight: 700;
			font-size: 23px;
			line-height: 33px;
			text-align: center;
			color: #000000;
			letter-spacing: 0.729px;
		}
	}

	&__main {
		display: flex;
		margin-top: 20px;
		gap: 15px;
	}

	&__search__main {
		max-width: 345px;

		&__container {
			background: #ffffff;
			padding: 20px 13px;
			border-radius: 12px;
			.book__author__charts__search {
				cursor: pointer;
				.chart__history__title {
					color: #000000;
					font-weight: 600;
					font-size: 16px;
					line-height: 33px;
					margin-top: 16px;
				}
				.result__search__main__left {
					display: flex;
					align-items: center;
					gap: 12px;
					margin-top: 10px;
					.result__search__name {
						line-height: 24px;
						overflow: hidden;
						text-overflow: ellipsis;
						display: -webkit-box;
						-webkit-line-clamp: 3;
						-webkit-box-orient: vertical;
					}
					.result__search__book-cover {
						width: 30px;
						aspect-ratio: 2/3;
						border-radius: 4px;
					}
				}
			}
		}
	}
}

.book__author__recharts {
	background: #ffffff;
	border-radius: 10.8122px;
	padding: 15px 20px 30px 20px;
	flex: 1;
	width: 100%;
	position: relative;
	min-width: 1px;
	.filter-quote-pane__config {
		max-width: 90px;
		margin-left: auto;
		.charts__setting {
			display: flex;
			align-items: center;
			gap: 4px;
			position: relative;
			cursor: pointer;
			.charts__setting__title {
				font-weight: 400;
				font-size: 12px;
				line-height: 33px;
				color: #a0a3bd;
			}
		}
	}

	.recharts-label {
		color: #000000;
		font-weight: 600;
		font-size: 14px;
		line-height: 18px;
	}

	&__small-search-button {
		position: absolute;
		z-index: 1;
		display: none;
		cursor: pointer;
		&__icon {
			position: absolute;
			background-color: #f7f7fc;
			height: 40px;
			width: 40px;
			border-radius: 50%;
			justify-content: center;
			align-items: center;
			display: flex;
			opacity: 0;
			transition: all 0.4s ease;
			&.show {
				opacity: 1;
				transition: all 0.4s ease;
			}
			img {
				margin-right: inherit;
			}
		}
		&__modal {
			opacity: 0;
			right: 100vh;
			position: absolute;
			transition: all 0.4s ease;
			border-radius: 10px;
			box-shadow: 3px 3px 8px 0px gray;
			&.show {
				opacity: 1;
				right: inherit;
				transition: opacity 0.4s ease;
			}
		}
	}
}

.chart__history__titles {
	margin-top: 20px;
	text-align: center;
}

.filter-chart-dropdown {
	.modal-content {
		border: none;
		align-items: center;
		justify-content: center;
		.dropdown-menu {
			margin: auto;
			transform: none !important;
		}
	}
}

@media only screen and (max-width: 1280px) {
	.book__author__charts__search__main {
		max-width: 300px;
	}
}

@media only screen and (max-width: 1024px) {
	.book__author__charts__main {
		&__small-screen-hide {
			display: none;
		}
	}
	.book__author__recharts {
		&__small-search-button {
			display: block;
		}
	}
}

// responsive điện thoại
@media only screen and (max-width: 500px) {
	.book__author__charts {
		padding: 15px 10px;

		.notification__main {
			&__container {
				gap: 10px;
			}

			&__title {
				font-size: medium;
				line-height: 1.5;
			}
		}
	}

	.book__author__recharts {
		padding: 15px 10px;
	}
}
