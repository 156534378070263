.notification-modal {
	position: absolute;
	width: 400px;
	top: 84%;
	right: 36px;
	background: #fcfcfc;
	border-radius: 10px;
	overflow: hidden;
	box-shadow: 0px 0px 32px rgba(0, 0, 0, 0.04);
	cursor: default;
	z-index: 1000;

	&__header {
		font-size: 20px;
		line-height: 32px;
		font-weight: 600;
		margin-top: 20px;
		margin-left: 20px;
	}

	.nav-tabs {
		background-color: transparent;
		.nav-link {
			margin-left: 20px;
			padding: 12px 0;
			font-size: 18px;
			line-height: 1.5;
			font-weight: 600;
			&.active {
				background-color: transparent;
			}
		}
	}

	.tab-content {
		padding: 12px 6px 20px 12px;
		max-height: 69vh;
		margin-right: 2px;
		overflow: auto;
		background-color: transparent;
		&::-webkit-scrollbar {
			width: 4px;
		}

		&::-webkit-scrollbar-track {
			background: white;
			border-radius: 8px;
		}

		&::-webkit-scrollbar-thumb {
			background: rgba(#606060, 0.3);
			border-radius: 8px;
			&:hover {
				background: rgba(#606060, 0.2);
			}
		}
	}

	&__content__title {
		font-style: normal;
		font-weight: 600;
		font-size: 18px;
		line-height: 32px;
		letter-spacing: 0.75px;
		padding: 8px 8px 12px;
	}

	.notification-status {
		margin-bottom: 4px;
		&__main-content-wrapper {
			&__message {
				font-size: 13px;
			}
		}
		&__reply-buttons {
			margin-top: 12px;
			margin-left: 0;
			justify-content: space-between;
			.notification-status__reply-button {
				width: 100%;
				height: 44px;
			}
		}
	}

	.notification__tab__button {
		text-align: center;
		padding: 12px 20px 0;
		color: #0576f0;
		font-weight: 600;
		font-size: 18px;
		line-height: 24px;
		letter-spacing: 0.75px;
		cursor: pointer;
		display: flex;
		align-items: center;
		justify-content: center;
	}

	.no__notificaion {
		display: block;
		font-size: 1rem;
		text-align: center;
		margin: 100px 0;
	}

	.notification-modal__loading-container {
		padding-top: 20px;
		height: calc(69vh + 54px);
	}

	.timeline-wrapper {
		background-color: transparent;
		.timeline-item {
			background-color: transparent;
			padding: 0 20px 20px;
		}
	}
	.overflow-unset {
		+ div {
			overflow: unset;
		}
	}
}

// responsive điện thoại
@media only screen and (max-width: 500px) {
	.notification-modal {
		right: 0px;
		top: 92%;
		width: 100vw;
		&__header {
			font-size: 16px;
		}
		.nav-tabs {
			.nav-link {
				font-size: 14px;
			}
		}

		.notification-status {
			&__reply-buttons {
				.notification-status__reply-button {
					height: 36px;
					font-size: 13px;
				}
			}
		}

		&__content__title {
			font-size: 16px;
			line-height: 22px;
		}
	}
}

@media only screen and (max-width: 360px) {
	.notification-modal {
		&__header {
			margin-top: 10px;
		}
	}
}
