.bookSearch {
	&__container {
		padding: 32px 28px;
	}

	&__title {
		font-weight: 500;
		font-size: 15px;
		line-height: 24px;
		color: #a0a3bd;
		padding-left: 4px;
	}

	&__main {
		margin-top: 36px;
		.author-book {
			&__info {
				justify-content: flex-end;
			}

			&__title {
				margin-bottom: 12px;
				-webkit-line-clamp: 2;
			}

			&__authors {
				margin-bottom: 20px;
			}
		}
	}

	.react-rating-container {
		> span {
			margin-right: 12px;
			.star-icon {
				width: 28px;
			}
		}
	}
}

.btn-goTo-upload-book {
	display: flex;
	flex-direction: column;
	align-items: center;
	&.has-background {
		margin-top: 35px;
		background-color: #fbf7f2;
		margin-bottom: 1.75rem;
		padding: 1.125rem 1.75rem;
		border-radius: 16px;
	}
}

@media only screen and (max-width: 820px) {
	.bookSearch__container {
		padding: 28px 24px;
	}
}

@media only screen and (max-width: 768px) {
	.bookSearch__container {
		padding: 24px 20px;
	}
}

// responsive điện thoại
@media only screen and (max-width: 500px) {
	.bookSearch {
		&__main {
			.author-book {
				&__info {
					.react-rating-container {
						> span {
							margin-right: inherit;
						}
					}
				}

				&__authors {
					margin-bottom: 4px;
				}
			}

			.text-status {
				display: none;
			}

			.react-rating-container {
				.star-icon {
					width: 20px;
				}
			}
		}
	}
}
