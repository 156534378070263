.favorite-author-tab {
	&__list {
		display: flex;
		flex-direction: column;
		gap: 1.5rem;
	}
}
@media only screen and (max-width: 1024px) {
	.favorite-author-tab {
		& h4 {
			font-size: 18px;
		}
	}
}
