@import 'src/scss/abstracts/_variables.scss';

.main-reading-summary {
	&__nav {
		background-color: white;
		margin-bottom: 2rem !important;
		padding: 12px 0px !important;
		border-radius: 16px;

		display: flex;
		flex-wrap: wrap;
		justify-content: space-around;
		align-items: center;

		&__title.disabled {
			a {
				color: gray !important;
			}
		}
	}

	.main-reading-summary__btn-back {
		display: flex;
		align-items: center;
		gap: 12px;
		margin-bottom: 32px;
		span {
			font-size: 1.25rem;
			font-weight: 600;
		}
	}

	.nav-item {
		font-size: 20px;
		font-weight: 400;
		color: $ash;
	}

	.nav-link {
		color: $ash;
		&.active {
			font-weight: 600 !important;
		}
	}

	.tab-content {
		padding: 1.5rem;
		border-radius: 16px;
		min-height: 800px;
	}
	.accordion-body {
		padding: 0px;
	}

	.reading-summary-select-box {
		.select-box {
			&__btn {
				background-color: $primary-light;
				justify-content: space-between;
				font-weight: 600;
				padding: 16px 40px;
				width: 220px;
			}

			&__list {
				width: 220px;
				height: auto;
				border-radius: 12px;
				max-height: unset;
				overflow-y: unset;
				background-color: $primary-light;
				margin-top: 1rem;
				padding: 4px 40px;
			}

			&__item {
				background-color: $primary-light;
				line-height: 32px;
				margin: 0;
				display: flex;
				align-items: center;
				justify-content: space-between;
				font-size: 14.38px;
				&.active {
					border: unset;
					font-weight: 400;
					svg {
						display: block;
						margin: 0;
						width: 14px;
						path {
							stroke: #222;
						}
					}
				}
			}
		}
	}
}

.reading-summary-share-btn {
	background-color: $primary-light;
	color: $ash;
	width: 220px;
	display: block;
	margin: 38px 0 80px auto;
	font-size: 14.38px;
}

@media only screen and (max-width: 1280px) {
	.main-reading-summary {
		.main-reading-summary__nav {
			padding: 12px 10px !important;
		}
		.tab-content {
			margin-right: 0;
		}
	}
}

@media only screen and (max-width: 1024px) {
	.mainContainer {
		.mainContainer__right {
			display: none;
		}
	}
}

@media only screen and (max-width: 768px) {
	.main-reading-summary {
		.main-reading-summary__nav {
			padding: 12px 0px !important;

			.nav-link {
				font-size: 1rem;
			}
		}
		.read-book-tab__quantity {
			font-size: 1rem;
		}
		.tab-content {
			width: auto;
		}
	}
}

// responsive điện thoại
@media only screen and (max-width: 500px) {
	.main-reading-summary {
		.tab-content {
			padding: 20px 10px;
			min-height: inherit;
		}

		.reading-summary-select-box {
			.select-box {
				&__btn {
					padding: 15px;
					width: 150px;
				}

				&__list {
					padding: 0 15px;
					width: 150px;
					margin-top: 0;
				}

				&__item {
					font-size: small;
				}
			}
		}
	}

	.reading-summary-share-btn {
		color: $ash;
		width: 150px;
		margin: 38px auto;
		font-size: small;
	}
}
