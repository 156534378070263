@import 'src/scss/abstracts/_variables.scss';

.book-info {
	&__rating {
		margin-top: 3.5em;
	}

	&__general-information {
		background-color: white;
		border-radius: 1em;
		margin: 2.4em 0 2.375em;
		padding: 1em 2.5em;
		h2 {
			font-size: 1.25em;
			font-weight: 700;
		}
		&__content {
			margin-top: 1em;
			display: flex;
			gap: 3.3em;
		}

		&__item {
			font-size: 0.94em;
			font-weight: 600;
			line-height: 1.5;
		}

		&__block {
			font-weight: 600;
			font-size: 0.94em;

			&:first-of-type {
				margin-top: 1em;
			}

			&__label {
				display: inline-block;
				width: 31.5%;
				background-color: $ash-background;
				padding: 1.2em;
			}

			&__info {
				display: inline-block;
				padding: 1.2em;
			}
		}
	}
}

@media only screen and (max-width: 820px) {
	.book-info {
		&__general-information {
			font-size: 15px;
			&__block {
				gap: 1.5em;
			}
		}
	}
}

// responsive điện thoại
@media only screen and (max-width: 500px) {
	.book-info {
		&__rating {
			margin-top: 2em;
		}

		&__general-information {
			padding: 1em;

			h2 {
				font-size: medium;
			}

			&__block {
				font-size: small;

				&__label {
					width: 100%;
					padding: 0.8em;
				}
			}
		}
	}
}
