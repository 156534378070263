@import 'src/scss/abstracts/_variables.scss';

.infor-tab {
	&__heading {
		margin-bottom: 2.5rem;
		font-size: 1.25rem;
		line-height: 1.5;
	}

	.form-field {
		input {
			height: 100%;
		}

		&.form-field-flex {
			display: flex;
			flex-wrap: wrap;
			align-items: center;
			gap: 8px;
			padding: 7px 24px;
			background-color: $ash-background;
			height: fit-content;
		}

		&-socials-link {
			margin-bottom: 16px;
		}

		&-badge {
			&.badge {
				padding: 0 18px;
				font-size: 13px;
				line-height: 15px;
				border-radius: 10px;
				height: 43px;
				display: flex;
				align-items: center;
			}
		}
	}

	.form-field-label {
		margin-bottom: 20px;
	}

	.form-field-group {
		margin-bottom: 2.5rem;
	}
	.gender {
		.form-field {
			width: fit-content;
			min-width: 120px;
			&-filled {
				width: 100%;
				height: 57px;
				padding: 0 1em;
				font-size: 0.94rem;
				line-height: 1.5;
				color: $ash;
				border-radius: 15px;
				background-color: #f7f7fc;
				display: flex;
				align-items: center;
			}
		}
	}
}

@media only screen and (max-width: 1024px) {
	.infor-tab__heading {
		font-size: 18px;
	}
}

// responsive điện thoại
@media only screen and (max-width: 500px) {
	.infor-tab {
		.form-field-group {
			margin-bottom: 2rem;

			.form-field-label {
				margin-bottom: 10px;
			}

			.form-field {
				.form-field-filled {
					padding: 0 12px;
					height: 40px;
					border-radius: 8px;
				}

				input {
					padding: 0 12px;
					min-height: 40px;
					border-radius: 8px;
				}

				&__no-data {
					height: 40px;
					font-size: small;
				}

				&-badge {
					&.badge {
						padding: 0 18px;
						font-size: 10px;
						border-radius: 8px;
						height: 30px;
					}
				}

				&.form-field-flex {
					gap: 6px;
					padding: 7px 16px;
				}
			}
		}
	}
}
