@import 'src/scss/abstracts/_variables.scss';

.shelf {
	display: grid;
	grid-template-columns: repeat(4, 1fr);
	grid-gap: 1.25rem;
	.book-item {
		margin-bottom: 2rem;
		.book-item__container {
			& > .book-thumbnail {
				border: 1px solid $ash-line;
				width: 100%;
				img {
					transition: transform 0.2s ease-out;
					&:hover {
						transform: scale(1.1);
					}
				}
			}
		}
	}
}

@media only screen and (max-width: 768px) {
	.shelf {
		grid-template-columns: repeat(3, 1fr);
		grid-gap: 1rem;
	}
}

// responsive điện thoại
@media only screen and (max-width: 500px) {
	.shelf {
		.book-item {
			margin-bottom: inherit;
		}
	}
}

@media only screen and (max-width: 366px) {
	.shelf {
		grid-template-columns: repeat(2, 1fr);
	}
}
