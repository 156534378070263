.notification {
	&__main {
		width: 100%;
		max-width: 936px;
		margin: auto;
		padding-bottom: 60px;
		font-family: 'Montserrat';
		font-style: normal;
		min-height: 100vh;

		.tab-content {
			padding: 20px 32px 32px;
		}

		.timeline-wrapper {
			padding: 8px;
			.timeline-item {
				padding-bottom: 20px;
			}
		}

		&__container {
			display: flex;
			padding-top: 40px;
			gap: 36px;
			align-items: center;
		}

		&__title {
			font-weight: 600;
			font-size: 20px;
			line-height: 32px;
			letter-spacing: 0.75px;
		}
	}

	&__tabs__main {
		margin-top: 40px;
		border-radius: 16px;
		overflow: hidden;

		ul {
			display: flex;
			flex-wrap: wrap;
			li {
				button {
					margin: 30px 30px 0 40px;
					font-weight: 700;
					font-size: 24px;
					line-height: 32px;
					font-family: 'Montserrat';
					font-style: normal;
					padding: 10px !important;
				}
			}
		}
	}

	&__all__main__title {
		font-weight: 600;
		font-size: 20px;
		line-height: 32px;
		letter-spacing: 0.75px;
		padding: 12px 8px 32px;
	}
}

@media only screen and (max-width: 820px) {
	.notification__main {
		max-width: 772px;
	}
}

@media only screen and (max-width: 768px) {
	.notification__main {
		max-width: 736px;
	}
}

// responsive điện thoại
@media only screen and (max-width: 500px) {
	.notification {
		&__main {
			padding: 0 10px;

			.tab-content {
				padding: 20px 0;
			}
		}

		&__tabs__main {
			margin-top: 25px;
			ul {
				li {
					flex-grow: 1;
					button {
						width: 100%;
						font-size: medium;
						margin: 5px 5px 0 5px;
					}
				}
			}
		}

		&__all__main__title {
			padding: 0 16px;
			font-size: medium;
			line-height: 1.2;
		}
	}
}
