.detail-feed-container {
	max-width: 700px;
	margin: auto;
	padding: 40px 10px;
	.detail-feed__loading {
		min-height: 50vh;
		background-color: white;
		border-radius: 16px;
		overflow: hidden;
		padding: 8px 0;
	}
}
