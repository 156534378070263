@import 'src/scss/abstracts/_global.scss';

.create-quotes-modal {
	&-container {
		width: 100%;
		height: 100%;
		position: fixed;
		top: 0;
		left: 0;
		display: flex;
		justify-content: center;
		z-index: 1000;
		background-color: rgba(217, 219, 233, 0.5);
		overflow-y: auto;
		padding: 80px 10px;
		&::-webkit-scrollbar {
			width: 8px;
		}

		/* Track */
		&::-webkit-scrollbar-track {
			background: #f1f1f1;
			border-radius: 8px;
		}

		/* Handle */
		&::-webkit-scrollbar-thumb {
			background: #606060;
			border-radius: 8px;
			&:hover {
				background: rgba(#606060, 0.85);
			}
		}
	}

	&-content {
		width: 100%;
		max-width: 636px;
		height: fit-content;
		background-color: white;
		border-radius: 16px;
		overflow: hidden;
	}

	&__header {
		padding: 16px 24px;
		display: flex;
		align-items: center;
		justify-content: space-between;
		h5 {
			margin: 0;
			font-weight: 600;
			color: $ash;
			line-height: 2rem;
			font-size: 1.25rem;
		}

		&__close {
			padding: 8px;
			background-color: #eff0f6;
			border-radius: 50%;
			svg {
				width: 20px;
				height: 20px;
			}
		}
	}

	&__body {
		border-top: #f1f1f5 1px solid;
		border-bottom: #f1f1f5 1px solid;

		&__text-field-edit-wrapper {
			width: 100%;
			position: relative;
			&.default {
				height: 150px;
				margin-top: 20px;
				padding: 0 8px 0 24px;
			}
			&.has-background {
				display: flex;
				justify-content: center;
				align-items: center;
				height: 300px;
				padding: 24px 8px 24px 24px;
			}
		}

		&__text-field-edit {
			width: 100%;
			outline: none;
			z-index: 1000;
			position: relative;
			overflow-x: hidden;
			overflow-y: scroll;
			color: $ash-body;
			max-height: 100%;

			&.default {
				line-height: 1.5rem;
			}

			&.has-background {
				font-size: 20px;
				font-weight: 600;
				line-height: 32px;
				text-align: center;
				padding-right: 8px;
			}

			&::-webkit-scrollbar {
				width: 8px;
			}

			&::-webkit-scrollbar-thumb {
				border-radius: 10px;
				background-color: #cfcfcf;
			}

			&::-webkit-scrollbar-track {
				background-color: transparent;
			}
		}

		&__text-field-placeholder {
			position: absolute;
			z-index: 1;
			&.default {
				top: 0;
				left: 24px;
				line-height: 1.5rem;
				color: #a0a3bd;
			}
			&.has-background {
				width: 100%;
				height: 100%;
				top: 0;
				left: 0;
				display: flex;
				justify-content: center;
				align-items: center;
				font-size: 20px;
				font-weight: 600;
				line-height: 32px;
				color: $ash-body;
				opacity: 0.7;
			}
			&.hide {
				display: none;
			}
		}

		&__select-background {
			border-radius: 50%;
			padding: 12px;

			svg {
				width: 20px;
				height: 20px;
			}
			&.open-button {
				background-image: linear-gradient(to right, #fec790, #f991f7);
			}
			&.close-button {
				background-color: #eff0f6;
			}

			&-container {
				position: relative;
				margin: 16px 24px 0 24px;
				display: flex;
				flex-direction: row;
				gap: 24px;
			}

			&-button-box {
				background-color: white;
				padding: 4px 0;
			}

			&-box {
				display: flex;
				align-items: center;
				gap: 24px;
				transition: all 0.4s ease-in;
				transform: translateX(-100%);
				visibility: hidden;
				padding: 4px 0;

				&::-webkit-scrollbar {
					height: 4px;
				}

				/* Track */
				&::-webkit-scrollbar-track {
					background: #f1f1f1;
					border-radius: 8px;
				}

				/* Handle */
				&::-webkit-scrollbar-thumb {
					background: #606060;
					border-radius: 8px;
					&:hover {
						background: rgba(#606060, 0.85);
					}
				}

				&.show {
					overflow-x: auto;
					transform: translateX(0%);
					visibility: visible;
				}

				&-item {
					min-width: 44px;
					aspect-ratio: 1;
					outline: transparent 4px solid !important;
					border-radius: 8px;
					&.active {
						outline: #e0af7e 4px solid !important;
					}
				}
			}
		}

		&__add-options {
			padding: 0 24px 24px;
		}

		&__option-item {
			margin-top: 32px;

			&__title {
				font-size: 14px;
				font-weight: 600;
				line-height: 1.25rem;
				margin-bottom: 24px;
			}

			&__search-container {
				height: 57px;
				margin-bottom: 24px;
				padding: 0px 24px;
				border-radius: 12px;
				background-color: $ash-background;
				display: flex;
				align-items: center;
				svg {
					width: 18px;
					height: 18px;
				}
				> Input {
					width: 100%;
					background-color: unset;
					margin-left: 16px;
					outline: none;
					font-size: 14px;
					&::placeholder {
						color: $ash-placeholder;
					}
				}

				&.list__tags {
					padding: 8px 24px;
					border-radius: 12px;
					background-color: $ash-background;
					display: flex;
					align-items: center;
					flex-wrap: wrap;
					height: fit-content;
				}
			}

			&-added {
				width: 100%;
				display: flex;
				justify-content: space-between;
				color: $ash-body;
				gap: 24px;
				span {
					line-height: 24px;
					color: $ash-body;
				}
				svg {
					path {
						stroke: $ash-body;
					}
				}
			}

			&__search-result {
				display: flex;
				margin-top: 24px;
				&.author {
					gap: 23px;
				}
				&.book {
					justify-content: space-between;
					overflow-x: auto;
					gap: 10px;
					&::-webkit-scrollbar {
						height: 5px;
					}

					/* Track */
					&::-webkit-scrollbar-track {
						background: #f1f1f1;
						border-radius: 8px;
					}

					/* Handle */
					&::-webkit-scrollbar-thumb {
						background: #606060;
						border-radius: 8px;
						&:hover {
							background: rgba(#606060, 0.85);
						}
					}
				}
			}
		}
	}

	&__searched-item {
		display: flex;
		flex-direction: column;
		align-items: center;
		cursor: pointer;
		// overflow: hidden;
		&.author {
			width: 100px;
		}
		&.book {
			width: 160px;
			min-width: 160px;
		}
	}

	&__author {
		&__avatar {
			border-radius: 50%;
			width: 80px;
			height: 80px;
			object-fit: cover;
		}

		&__name {
			font-size: 14px;
			line-height: 20px;
			text-align: center;
			color: $ash-label;
			font-weight: 600;
			margin-top: 8px;
		}
	}

	&__book {
		&__image {
			width: 100%;
			aspect-ratio: 2/3;
			border-radius: 8px;
			object-fit: cover;
		}

		&__name {
			font-weight: 600;
			line-height: 23px;
			text-align: center;
			display: -webkit-box;
			-webkit-box-orient: vertical;
			-webkit-line-clamp: 2;
			overflow: hidden;
			margin: 12px 0 4px;
			font-size: 15px;
			color: $ash;
		}

		&__author {
			font-weight: 500;
			line-height: 19px;
			text-align: center;
			font-size: 13px;
			color: $ash-label;
		}
	}

	&__no-search-result {
		margin-top: 24px;
		color: $ash;
		font-size: 15px;
	}

	&__footer {
		padding: 24px;
		button {
			width: 100%;
			padding: 16px;
			height: 56px;
			border-radius: 12px;
			background-color: $ash-line;
			color: $ash-placeholder;
			font-weight: 600;
			cursor: not-allowed !important;
			&.active {
				background-color: $primary;
				color: white;
				cursor: pointer !important;
			}
		}
	}
}

.input-keyword {
	margin-left: -14px !important;
}

.input__tag {
	flex-wrap: wrap;
	display: flex;
	cursor: pointer;
	align-items: center;
	margin-bottom: 10px;
	margin-top: 10px;
	gap: 10px;
	max-width: 100%;
	> span {
		width: max-content;
		color: #b38c64;
		background-color: #f5e7d8;
		padding: 0.75rem 1.25rem;
		border-radius: 10px;
		font-size: 0.75rem;
		overflow: hidden;
		display: flex;
		align-items: center;
		span {
			overflow: hidden;
			text-overflow: ellipsis;
			max-width: 95%;
		}
		button {
			z-index: 2;
		}
	}
}

// responsive điện thoại
@media only screen and (max-width: 500px) {
	.create-quotes-modal {
		&__body {
			&__text-field-edit-wrapper {
				&.has-background {
					height: 200px;
					padding: 12px 0 12px 8px;
				}
			}

			&__text-field-edit {
				&.has-background {
					font-size: small;
					line-height: 1.5;
				}
			}

			&__select-background {
				padding: 8px;

				&-container {
					gap: 12px;
					margin: 16px 10px 0;
				}

				&-button-box {
					padding: 0;
				}
			}

			&__select-background-box {
				gap: 10px;
				&-item {
					min-width: 30px;
				}
			}

			&__option-item {
				margin-top: 20px;

				&__title {
					margin-bottom: 8px;
				}

				&__search-container {
					height: 40px;
					padding: 0 10px;
				}

				&-added {
					span {
						line-height: inherit;
					}
				}
			}

			&__add-options {
				padding: 0 10px 18px;
			}
		}

		&__searched-item.book {
			min-width: 108px;
		}

		&__book {
			&__name,
			&__author {
				font-size: inherit;
				line-height: 1.5;
			}
		}

		&__footer {
			button {
				height: 40px;
				padding: 0;
			}
		}
	}
}
