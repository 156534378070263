.sidebar-upload {
	margin-top: 4.75rem;

	.sibar-pop-authors {
		margin-top: 80px;
	}

	.slick-arrow.slick-next {
		z-index: 0;
	}
}
