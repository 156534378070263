@import 'src/scss/abstracts/_global.scss';
.status-book-wrapper {
	&:nth-of-type(2) {
		margin-top: 1rem;
	}
	font-weight: 600;
}

.status-book__title {
	font-size: 1rem;
	font-weight: inherit;
	line-height: 1.5;
	text-align: left;
	color: $ash;
}

.status-book__list {
	margin: 1rem 0 1rem;
	min-height: fit-content;
	&--shelves {
		max-height: 180px;
		overflow-x: hidden;
		overflow-y: auto;
		&::-webkit-scrollbar {
			width: 8px;
		}

		/* Track */
		&::-webkit-scrollbar-track {
			background: #f1f1f1;
			border-radius: 8px;
		}

		/* Handle */
		&::-webkit-scrollbar-thumb {
			background: rgba(#606060, 0.3);
			border-radius: 8px;
			&:hover {
				background: rgba(#606060, 0.2);
			}
		}
		p {
			font-size: 15px;
			font-weight: 400;
			text-align: center;
		}
	}
}

.status-item {
	padding: 1.25rem 1.25rem;
	display: flex;
	align-items: center;
	color: $ash-body;
	&.status-item--icon {
		&:hover {
			.status-item__title {
				color: $primary;
			}
			background-color: $secondary;
		}
	}

	.custom-checkbox {
		margin-left: auto;
	}
	.custom-checkbox__container {
		display: inline-block;
		width: fit-content;
		position: relative;
		margin-right: 2rem;
		margin-bottom: 15px;
		cursor: pointer;
		font-size: 22px;
		-webkit-user-select: none;
		-moz-user-select: none;
		-ms-user-select: none;
		user-select: none;
	}

	.status-item__input {
		position: absolute;
		opacity: 1;
		cursor: pointer;
		height: 0;
		width: 0;
	}

	/* Create a custom checkbox */
	.status-item__checkmark {
		position: absolute;
		top: 0;
		left: 100%;
		height: 20px;
		width: 20px;
		background-color: $white;
		border: 2px solid $ash-placeholder;
		&::after {
			content: '';
			position: absolute;
			display: none;
		}
	}

	.custom-checkbox__container .status-item__input:checked ~ .status-item__checkmark:after {
		display: block;
	}

	.custom-checkbox__container .status-item__checkmark:after {
		left: 5px;
		top: 2px;
		width: 5px;
		height: 10px;
		border: solid $primary;
		border-width: 0 3px 3px 0;
		-webkit-transform: rotate(45deg);
		-ms-transform: rotate(45deg);
		transform: rotate(45deg);
	}

	&__title {
		margin-left: 1em;
	}

	.custom-radio {
		margin-left: auto;
	}

	.custom-radio__container {
		display: inline-block;
		width: fit-content;
		position: relative;
		margin-right: 2rem;
		margin-bottom: 16px;
		cursor: pointer;
		font-size: 22px;
		-webkit-user-select: none;
		-moz-user-select: none;
		-ms-user-select: none;
		user-select: none;
	}

	/* Hide the browser's default radio button */
	.custom-radio__container input {
		position: absolute;
		opacity: 0;
		cursor: pointer;
	}

	/* Create a custom radio button */
	.checkmark {
		position: absolute;
		top: 0;
		left: 0;
		height: 20px;
		width: 20px;
		background-color: $white;
		border-radius: 50%;
		border: 2px solid $ash-placeholder;
	}

	/* When the radio button is checked, add a blue background */
	.custom-radio__container input:checked ~ .checkmark {
		background-color: $primary;
		border: none;
	}
}
