@import 'src/scss/abstracts/_variables.scss';

.personal-info {
	margin-bottom: 3.25rem;
	&__wallpaper {
		width: 100%;
		aspect-ratio: 92/35;
		display: flex;
		justify-content: flex-end;
		align-items: flex-end;
		border-top-left-radius: 12px;
		border-top-right-radius: 12px;
		position: relative;
		> img {
			width: 100%;
			height: 100%;
			object-fit: cover;
			border-radius: 12px 12px 0px 0px;
		}
	}

	.edit-wallpaper {
		width: fit-content;
		right: 2.5rem;
		bottom: 1.5rem;
		position: absolute;
		&__btn {
			padding: 0.825rem 1.25rem;
			background-color: $white;
			border-radius: 12px;
			font-size: 18px;
			font-weight: 600;
			color: $ash;
			display: flex;
			align-items: center;

			span {
				margin-left: 0.875rem;
				margin-top: 2px;
			}
		}
	}

	&__detail {
		&__avatar-and-name {
			display: flex;
			width: 100%;
			gap: 36px;
		}

		&__avatar {
			width: fit-content;
			margin: -72px 10px 0 10px;
			position: relative;
		}

		&__name {
			width: 100%;
		}

		&__connect-buttons-and-introduction {
			display: flex;
			gap: 36px;
			margin-top: 16px;
			.personal-info-none {
				visibility: hidden;
				button {
					padding: 0;
				}
			}
		}

		&__introduction {
			flex-grow: 1;
			.read-more {
				margin-top: 1.2rem;
			}
		}

		.edit-avatar {
			position: absolute;
			bottom: 1rem;
			right: 0;
			&__btn {
				width: 38px;
				height: 38px;
				border-radius: 50%;
				background-color: $secondary;
			}
		}
	}

	&__edit-image-modal {
		.modal-content {
			border-radius: 16px;
			overflow: hidden;
		}

		.modal-header {
			span {
				position: relative;
				left: 50%;
				transform: translate(-50%, 0);
			}
		}
		.modal-body {
			padding: 0;
			display: flex;
			flex-direction: column;
		}
	}

	&__username {
		display: flex;
		align-items: center;
		margin-top: 2.25rem;
		margin-bottom: 0.875rem;
		h4 {
			margin: 0;
			font-size: 1.5rem;
			font-weight: 700;
			max-width: 400px;
			text-overflow: ellipsis;
			overflow: hidden;
			white-space: nowrap;
		}

		.edit-name {
			display: flex;
			align-items: flex-end;
			font-size: 0.875rem;
			font-weight: 500;
			color: $ash-label;

			&__pencil {
				width: 20px;
				height: 20px;
				margin: 0 0.25rem 0 0.75rem;
			}

			span {
				display: block;
			}
			button {
				font-size: 13px;
			}
		}
	}

	&__email {
		color: $ash;
		font-weight: 600;
	}

	.setting {
		position: relative;
		margin-left: auto;
		&-btn {
			width: 38px;
			height: 38px;
			border-radius: 50%;
			background-color: rgba($primary-light, 0.4);
			margin-left: auto;
		}

		&-list {
			position: absolute;
			top: 130%;
			right: 0;
			border-radius: 16px;
			background-color: $white-off;
			box-shadow: 0 0 20px 2px rgba($ash-placeholder, 0.4);
			overflow: hidden;
			z-index: 10000;
		}

		&-item {
			padding: 0 2rem 1.25rem;
			display: flex;
			align-items: center;
			gap: 1.25rem;
			font-size: 18px;
			font-weight: 600;
			color: $ash-body;
			cursor: pointer;
			&:hover {
				background-color: $secondary;
				.setting-item__content {
					text-shadow: 0px 0px 0.5px black;
				}
			}
			&:first-of-type {
				padding-top: 2rem;
			}
			&:not(:first-of-type) {
				padding-top: 1.25rem;
			}

			svg {
				width: 20px;
				height: 20px;
			}

			&__content {
				white-space: nowrap;
			}
		}
	}

	&__list {
		display: flex;
		flex-wrap: wrap;
		column-gap: 2rem;
		row-gap: 0.5rem;
	}

	&__item {
		font-size: 13px;
		font-weight: 500;
		line-height: 1.5;
		color: $ash-label;
		cursor: pointer;
		.number {
			display: inline-block;
			margin-right: 0.25rem;
			font-weight: 700;
			color: $blue;
		}
	}

	&-form {
		.form-field {
			display: flex;
			gap: 24px;
			&-group {
				margin-bottom: 2.5rem;
			}

			&--custom {
				width: 87%;
			}

			&-wrapper {
				display: flex;
				align-items: center;
				justify-content: space-between;
				gap: 1.5rem;
				&.socials-link {
					margin-bottom: 1rem;
					.btn-icon {
						svg {
							path {
								fill: $red;
							}
						}
					}
				}
			}

			&-label {
				margin-bottom: 12px;
			}

			&-name {
				width: 100%;
				display: flex;
				gap: 24px;
				justify-content: space-between;
			}

			&__btn {
				background-color: #eff0f6;
				height: 34px;
				border-radius: 12px;
				display: flex;
				align-items: center;
				justify-content: center;
				font-size: 14px;
				padding: 0 12px;
				cursor: pointer;
				user-select: none;
				&.cancel {
					color: #e61b00;
				}
				&.save {
					color: $success;
					&.disabled {
						opacity: 0.4;
						cursor: no-drop;
					}
				}
			}

			&__buttons {
				display: flex;
				justify-content: flex-end;
				gap: 1rem;
				margin-top: 16px;
				&.no-data {
					justify-content: flex-start;
					margin: 0;
					gap: 1.5rem;
				}
			}

			&-filled {
				width: 100%;
				height: 57px;
				padding: 0 1em;
				font-size: 0.94rem;
				line-height: 1.5;
				color: $ash;
				border-radius: 12px;
				background-color: #f7f7fc;
				display: flex;
				align-items: center;
				user-select: none;

				&.email {
					background-color: #d9dbe9;
				}

				&.gender {
					justify-content: center;
					width: fit-content;
					min-width: 90px;
					padding: 0 1.5rem;
				}

				&.editting {
					border: 1px solid #d9dbe9;
				}
			}

			input {
				height: 57px;
				background-color: $ash-background;
			}

			.add-and-search-categories {
				.add-and-search-categories__main-content {
					input {
						height: 32px;
					}
					.add-and-search-categories__input {
						height: fit-content;
					}
				}
			}

			&.categories {
				padding: 7px 24px;
				background-color: #f7f7fc;
				align-items: center;
				flex-wrap: wrap;
				gap: 8px;
				width: 100%;
			}

			&.error {
				color: $red;
				font-size: 0.825rem;
				margin-top: 12px;
			}
			.add-and-search-categories__categories-added__item {
				font-size: 12px;
			}

			.select-box.gender {
				.select-box__list {
					width: 190px;
				}
			}
		}

		.btn-icon {
			flex-shrink: 0;
			width: 34px;
			height: 34px;
			border-radius: 50%;
			display: flex;
			align-items: center;
			justify-content: center;
			background-color: #eff0f6;
			cursor: pointer;
			svg {
				width: 14px;
				height: 14px;
			}
			&.hidden {
				visibility: hidden;
			}
		}

		&__btn__container {
			margin-top: 1.25rem;
			padding-top: 2rem;
			border-top: 1px solid #f1f1f5;
			text-align: center;
		}

		&__fixing-width {
			.editStatus {
				.add-and-search-categories__main-content {
					border: 1px solid #d9dbe9;
				}
			}
		}
	}

	&__btn__submit {
		background-color: $ash-line;
		padding: 1.25rem;
		max-width: 650px;
		font-size: 18px;
		width: 100%;
		border-radius: 12px;
		color: $ash-placeholder;
		cursor: no-drop;
		&.active {
			background-color: $primary;
			color: white;
			cursor: pointer;
		}
	}

	&__modal {
		&::-webkit-scrollbar {
			width: 8px;
		}

		&::-webkit-scrollbar-track {
			background: #f1f1f1;
			border-radius: 8px;
		}

		&::-webkit-scrollbar-thumb {
			background: #606060;
			border-radius: 8px;
			&:hover {
				background: rgba(#606060, 0.85);
			}
		}

		.modal-dialog {
			max-width: 650px;
		}
		.modal-content {
			border-radius: 16px;
			border: none;
		}

		.modal-header {
			padding: 2rem 1.25rem 1.25rem;
			border-bottom: 1px solid #f1f1f5;
		}

		.modal-title {
			text-align: center;
			flex-grow: 1;
		}
		.modal-body {
			padding: 24px;
		}

		.close-btn {
			background-color: #eff0f6;
			padding: 8px;
			border-radius: 50%;

			svg {
				width: 18px;
				height: 18px;
			}
		}
	}
	.setting-btn {
		background-color: #f1f1f1;
	}

	.read-more {
		word-break: break-word;
	}
}

.personal-info-form {
	.form-field-textarea {
		.public-DraftEditorPlaceholder-root {
			.public-DraftEditorPlaceholder-inner {
				height: auto;
			}
		}
		.public-DraftEditor-content {
			> div {
				min-height: 150px;
			}
		}
	}
}

.form-field__no-data {
	font-size: 15px;
	height: 57px;
	display: flex;
	align-items: center;
}

.modal-delete-social-container {
	background-color: rgba(22, 22, 22, 0.3);
	width: 100vw;
	height: 100vh;
	position: fixed;
	top: 0;
	left: 0;
	display: flex;
	justify-content: center;
	align-items: center;
}

.modal-delete-social {
	.modal-content {
		padding: 2rem;
		text-align: center;
		border-radius: 24px;
	}

	.btn-closeX {
		display: inline-block;
		margin-left: auto;
		width: 24px;
		height: 24px;
		cursor: pointer;
	}

	&__title {
		margin-bottom: 1.25rem;
		font-size: 2.25rem;
		font-weight: 700;
		color: #14142b;
	}
	&__subtitle {
		margin-bottom: 1.5rem;
		font-size: 1.125rem;
		color: $ash-body;
	}

	&__del-btn {
		&.btn {
			display: block;
			margin: 0 auto;
			width: 100%;
			max-width: 255px;
			margin-bottom: 1.5rem;
		}
	}
}

@media only screen and (max-width: 820px) {
	.main-profile {
		.personal-info {
			&__detail {
				&__avatar-and-name {
					gap: 12px;
				}

				&__connect-buttons-and-introduction {
					gap: 12px;
				}
			}

			&__username {
				h4 {
					max-width: 320px;
				}
			}

			&__list {
				.personal-info__item {
					font-size: small;
				}
			}
		}
		// .bookcase__item__book-name {
		// 	font-size: 16px;
		// 	display: -webkit-box;
		// 	-webkit-line-clamp: 3;
		// 	-webkit-box-orient: vertical;
		// 	overflow: hidden;
		// 	text-overflow: ellipsis;
		// }
	}
}

@media only screen and (max-width: 768px) {
	.main-profile {
		.personal-info {
			.personal-info__detail__avatar-and-name {
				.personal-info__username {
					h4 {
						font-size: 22px;
						max-width: 280px;
					}
				}
			}

			&__list {
				column-gap: 1rem;
			}
		}
	}
}

@media only screen and (max-width: 991px) {
	.personal-info__edit-image-modal {
		.modal-dialog.modal-lg {
			max-width: 800px;
			width: 90%;
			margin: auto;
		}
	}
}

// responsive điện thoại
@media only screen and (max-width: 500px) {
	.personal-info {
		&__modal {
			.modal-body {
				padding: 24px 10px;
			}
		}

		.edit-wallpaper {
			right: 0.6rem;
			bottom: 0.6rem;
			transform: scale(0.7);
			&__btn {
				span {
					display: none;
				}
			}
		}

		&__detail {
			&__avatar {
				.user-avatar {
					width: 120px !important;
				}
			}

			.edit-avatar {
				bottom: 0;
			}

			&__avatar-and-name {
				flex-direction: column;
				gap: 0;
			}

			&__connect-buttons-and-introduction {
				flex-direction: column;

				.connect-buttons.column {
					flex-direction: row;
					justify-content: center;
				}
			}

			&__introduction {
				padding-left: 2rem;
			}
		}

		&__username {
			margin-top: 0;
			justify-content: center;

			.setting {
				margin-left: 1rem;
			}

			.edit-name {
				button {
					display: none;
				}
			}
		}

		&__email {
			text-align: center;
		}

		.setting {
			&-list {
				right: -1rem;
			}

			&-item {
				font-size: small;
				padding: 0 1.25rem 1.25rem;

				&:first-of-type {
					padding-top: 1.25rem;
				}

				svg {
					width: 15px;
					height: 15px;
				}
			}
		}

		&__btn__submit {
			font-size: small;
		}
	}

	.personal-info-form {
		.form-field {
			gap: 4px;
			input {
				height: 40px;
				font-size: inherit;
			}

			&-name {
				gap: 10px;
			}

			&-filled {
				height: 40px;
			}

			.select-box.gender {
				.select-box__list {
					width: 160px;
				}
			}

			&__no-data {
				height: 40px;
				font-size: inherit;
			}

			&.categories {
				padding: 4px 10px;
			}
		}
	}
}
