.main-shelves__modal {
	&.read-target {
		.modal-content {
			padding: 1rem 0px 0px 0px;
			.read-challenge {
				margin-bottom: 0;
				&__title {
					margin-bottom: 0px;
				}
			}
		}
	}
}
