@import 'src/scss/abstracts/_variables.scss';

.read-book {
	display: flex;
	align-items: center;
	flex-wrap: wrap;
	background-color: $secondary;
	padding: 16px 12px;
	border-radius: 16px;

	&__image {
		width: 55px;
		min-width: 55px;
		height: 83px;
		margin-right: 8px;
		img {
			width: 100%;
			height: 100%;
			object-fit: cover;
			border-radius: 8px;
			cursor: pointer;
		}
	}

	&__name {
		width: 30%;
		max-height: 83px;
		overflow: hidden;
		display: -webkit-box;
		-webkit-line-clamp: 3;
		-webkit-box-orient: vertical;
		text-overflow: ellipsis;
		margin-right: 2.25rem;
		font-size: 15px;
		line-height: 24px;
		font-weight: 600;
		color: $ash-body;

		&.show {
			display: block;
		}

		&.hide {
			display: none;
		}
	}

	h5 {
		cursor: pointer;
	}

	&__title-and-stars-mobile {
		display: none;
	}
}

// responsive điện thoại
@media only screen and (max-width: 500px) {
	.read-book {
		flex-wrap: nowrap;
		&__name {
			width: auto;
			margin-right: inherit;
			font-size: small;
			line-height: 1.5;

			&.show {
				display: none;
			}
			&.hide {
				display: -webkit-box;
			}
		}
		&__stars {
			display: none;
		}

		&__title-and-stars-mobile {
			display: block;
		}
	}
}
