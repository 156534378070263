@import 'src/scss/abstracts/_variables.scss';

.group-links {
	margin-top: 5rem;
	&__card {
		border-radius: 16px;
		background-color: $white;
	}

	&__item {
		padding: 1.5rem 1rem;
		cursor: auto;

		&:not(:last-of-type) {
			border-bottom: 1px solid $grey-border;
		}

		&__name {
			font-size: 1rem;
			font-weight: 600;
			line-height: 1.5;
			cursor: pointer;
			display: -webkit-box;
			-webkit-box-orient: vertical;
			-webkit-line-clamp: 2;
			overflow: hidden;
		}

		span {
			font-size: 0.75rem;
			color: $ash-label;
		}
	}

	.active {
		display: inline-block;
		width: 8px;
		height: 8px;
		margin-right: 5px;
		border-radius: 50%;
		background: $primary;
	}
}
