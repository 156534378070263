.friends__unFriends__container {
	font-family: 'Montserrat';
	font-style: normal;
	padding: 0 !important;
	display: flex !important;
	justify-content: center;
	align-items: center;
	.modal-content {
		border: none;
		padding: 20px;
		border-radius: 10px;
		box-shadow: 0 0 3px gray;
	}
}

.friends__unFriend__close {
	text-align: right;
	cursor: pointer;
}

.friends__unFriend__content {
	font-weight: 700;
	font-size: 24px;
	line-height: 48px;
	letter-spacing: 1px;
	color: #14142b;
	margin-bottom: 12px;
	text-align: center;
}

.friends__unFriend__cancel {
	font-weight: 600;
	font-size: 15px;
	line-height: 24px;
	letter-spacing: 0.75px;
	color: #6e7191;
	cursor: pointer;
	margin-top: 24px;
}
.friends__unFriend__title {
	font-weight: 500;
	font-size: 18px;
	line-height: 32px;
	color: #4e4b66;
	letter-spacing: 0.75px;
	text-align: center;
	margin-bottom: 48px;
}
.friends__unFriend__main {
	display: flex;
	flex-direction: column;
	align-items: center;
}
.friends__unFriend__button {
	width: 330px;
}
